export const mainGreen = "#34BFA3";
export const mainGold = "#BEA971";
export const mainOrange = "#C3A248";
export const mainRed = "#F21E5A";
export const mainSilver = "#5D5D5D";
export const mainBlack = "#000000";
export const mainDisabled = "#BABABA";
export default {
  sublime: {
    color: {
      mainColor: mainGreen,
      darkGrey: mainBlack,
      forbidden: {
        level0: mainGreen,
        level1: mainGold,
        level2: mainOrange,
        level3: mainSilver
      },
      offers: {
        free: mainGreen,
        pro: mainGold,
        premium: mainSilver,
        company: mainBlack
      },
      mainGreen,
      mainGold,
      mainOrange,
      mainRed,
      mainSilver,
      mainBlack,
      mainDisabled,
      menu: {
        action: {
          add: mainGreen,
          duplicate: mainSilver,
          edit: mainOrange,
          delete: mainRed,
          disable: mainSilver
        }
      },
      mainGreenRgba(opacity = 1) {
        return `rgba(52, 191, 163, ${opacity})`;
      },
      mainGoldRgba(opacity = 1) {
        return `rgba(190, 169, 113, ${opacity})`;
      },
      mainOrangeRgba(opacity = 1) {
        return `rgba(195, 162, 72, ${opacity})`;
      },
      mainRedRgba(opacity = 1) {
        return `rgba(242, 30, 90, ${opacity})`;
      },
      mainSilverRgba(opacity = 1) {
        return `rgba(93, 93, 93, ${opacity})`;
      },
      mainBlackRgba(opacity = 1) {
        return `rgba(0, 0, 0, ${opacity})`;
      },
      disabled: mainDisabled
    }
  }
};
