import { combineReducers } from 'redux';
import authReducer from './auth';
import userReducer from './user';
import appReducer from './app';
import sublimeProjectsReducer from './sublime-projects';
import postReducer from './posts';
import statsReducer from './stats';
import offersReducer from './offers';
import projectReducer from './project';

export default combineReducers({
  auth: authReducer,
  user: userReducer,
  app: appReducer,
  sublimeProjects: sublimeProjectsReducer, // used for fetch existing projects
  projects: projectReducer, // used by lib to create or edit a current project
  posts: postReducer,
  stats: statsReducer,
  offers: offersReducer
});
