import { SIGN_IN, SIGN_OUT, SET_USER_ROLE } from '../types/auth';
const initialState = {
  client: '',
  uid: '',
  'access-token': '',
  'token-type': '',
  isConnected: false,
  isAdmin: false,
  rememberMe: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {

    case SIGN_IN:
      const { uid, client, rememberMe } = payload;
      return { ...state, uid, client, rememberMe, isConnected: true, 'access-token': payload['access-token'], 'token-type': payload['token-type'] };

    case SIGN_OUT:
      return initialState;

    case SET_USER_ROLE:
      const { roles } = payload;
      const isAdmin = roles.map(r => r.name).includes('admin');
      return { ...state, isAdmin };

    default:
      return state;
  }
};
