import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clearMessages } from '../../../store/actions/app';
import SublimeTheme from '../../../ui-kit/theme';
import './index.scss';

function formatColor(type) {
  switch (type) {
    case 'danger':
      return SublimeTheme.sublime.color.mainRed;
    case 'warning':
      return SublimeTheme.sublime.color.mainOrange;
    case 'info':
      return SublimeTheme.sublime.color.mainSilver;
    case 'success':
      return SublimeTheme.sublime.color.mainGreen;
    default:
      return SublimeTheme.sublime.color.mainGreen;
  }
}

function formatTime(type) {
  switch (type) {
    case 'danger':
      return 20000;
    case 'warning':
      return 15000;
    case 'info':
      return 10000;
    case 'success':
      return 3000;
    default:
      return SublimeTheme.sublime.color.mainGreen;
  }
}

export class AlertMessage extends Component {
  componentDidMount() {
    const { clearMessagesBox } = this.props;
    clearMessagesBox();
  }

  componentDidUpdate(prevProps, prevState) {
    const { app, clearMessagesBox } = this.props;
    const { messages } = app;

    if (messages.length >= 1) {
      clearTimeout(this.reset);
      this.reset = setTimeout(() => {
        clearMessagesBox();
      }, formatTime(messages[0].type));
    }
  }

  componentWillUnmount() {
    const { clearMessagesBox } = this.props;
    clearMessagesBox();
  }

  render() {
    const { app } = this.props;
    const { messages } = app;
    const hasMessages = messages.length >= 1;
    return (
      <div
        style={{
          display: hasMessages ? 'block' : 'none'
        }}
        className='sublime-messages-container'
      >
        <div
          dangerouslySetInnerHTML={{
            __html: '<style>.sublime-notifications{display: none;}</style>'
          }}
        />
        <ul>
          {messages.map((msg, idx) => {
            return (
              <li
                className='sublime-message-item'
                key={idx}
                style={{ color: formatColor(msg.type) }}
              >
                - {msg.message}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  app: state.app
});

const mapDispatchToProps = {
  clearMessagesBox: clearMessages
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertMessage);
