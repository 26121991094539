import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback
} from "reactstrap";
import {
  getImage,
  SublimeSwitch,
  SublimeInfo,
  Separator
} from "exalt3d-sublime-ui";
import Price from "./step1/Price";
import Domains from "./step1/Domains.tsx";

const defaultState = {
  name: "",
  player_size: "16:9",
  title: "",
  subtitle: "",
  domains: [],
  domain: { name: "", id: 0 },
  top_description: "",
  bottom_description: "",
  display_player_only: false,
  display_price: false,
  is_single_price: false,
  single_price: "",
  is_admin_price: false,
  admin_price: "",
  display_price_on_each: false,
  price_increase: false,
  currencies: [],
  menu: []
};

export default class SublimeConfStep1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...defaultState,
      errors: {}
    };
  }

  componentDidMount() {
    const { store } = this.props;
    this.loadProject();
    store.subscribe(() => {
      this.loadProject();
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { is_admin_price, is_single_price } = this.state;
    // User can select singlePrice or adminPrice
    if (prevState.is_single_price && is_admin_price) {
      // https://github.com/airbnb/javascript/issues/1875
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ is_single_price: false });
    } else if (prevState.is_admin_price && is_single_price) {
      // https://github.com/airbnb/javascript/issues/1875
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ is_admin_price: false });
    }
  }

  loadProject = () => {
    const { store } = this.props;
    if (store.getState().projects.project.id !== null) {
      this.setState({ ...store.getState().projects.project });
    } else {
      this.setState({ ...defaultState });
    }
  };

  handleChange = event => {
    const { errors } = this.state;
    const { name, value } = event.target;
    if (Object.keys(errors).includes(name)) {
      delete errors[name];
    }
    this.setState({ [name]: value });
  };

  handleDomainChange = domains => {
    this.setState({ domains: [...domains] });
  };

  handleCheckChange = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    const { submit, store } = this.props;
    const {
      name,
      domains,
      display_player_only,
      display_price,
      title,
      subtitle,
      top_description,
      bottom_description,
      errors
    } = this.state;
    return (
      <Form
        onSubmit={async e => {
          e.preventDefault();
          const result = await submit(this.state);
          if (!result.success) {
            this.setState({ errors: { ...result.response.data.error } });
          }
        }}
        className="full-width marged-top"
      >
        <Separator fix title="Project settings" isOpen>
          <Row>
            <Col xs={12} md={6}>
              <FormGroup>
                <Label for="name">
                  {'Name '}<span className="sublime-mandatory">*</span>
                </Label>
                <Input
                  invalid={Object.keys(errors).includes("name")}
                  onChange={this.handleChange}
                  value={name}
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Enter your project name"
                />
                {Object.keys(errors).includes("name") && (
                  <FormFeedback
                    dangerouslySetInnerHTML={{
                      __html: errors.name.join("<br/>")
                    }}
                  />
                )}
              </FormGroup>
            </Col>
            <Col xs={12} md={6}>
              <Domains
                onChange={this.handleDomainChange}
                store={store}
                domains={domains}
              />
            </Col>
          </Row>
        </Separator>
        <Separator fix title="Project informations" isOpen>
          <Row>
            <Col xs={12} md={6}>
              <FormGroup>
                <Label className="flex-row" for="title">
                  Configurator title
                  <SublimeInfo target="conf-title" imgSrc={getImage("title")} />
                </Label>
                <Input
                  onChange={this.handleChange}
                  value={title}
                  type="text"
                  name="title"
                  id="conf-title"
                  placeholder="Enter your title"
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={6}>
              <FormGroup>
                <Label className="flex-row" for="subtitle">
                  Subtitle
                  <SublimeInfo
                    target="subtitle"
                    imgSrc={getImage("subtitle")}
                  />
                </Label>
                <Input
                  onChange={this.handleChange}
                  value={subtitle}
                  type="text"
                  name="subtitle"
                  id="subtitle"
                  placeholder="Enter your subtitle"
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={6}>
              <FormGroup>
                <Label className="flex-row" for="top_description">
                  Top of the page description
                  <SublimeInfo
                    target="top_description"
                    imgSrc={getImage("top-of-the-page-description")}
                  />
                </Label>
                <Input
                  onChange={this.handleChange}
                  value={top_description}
                  type="textarea"
                  name="top_description"
                  id="top_description"
                  placeholder="Enter the top of the page description"
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={6}>
              <FormGroup>
                <Label className="flex-row" for="bottom_description">
                  Bottom of the page description
                  <SublimeInfo
                    target="bottom_description"
                    imgSrc={getImage("bottom-of-the-page-description")}
                  />
                </Label>
                <Input
                  onChange={this.handleChange}
                  value={bottom_description}
                  type="textarea"
                  name="bottom_description"
                  id="bottom_description"
                  placeholder="Enter the bottom of the page description"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="marged-top">
            <Col xs={12} md={12}>
              <FormGroup>
                <Label
                  className="flex-row flex-center-items"
                  check
                  for="display_player_only"
                >
                  <SublimeSwitch
                    onChange={e => {
                      this.setState({ display_price: false });
                      this.handleCheckChange(e);
                    }}
                    checked={display_player_only}
                    type="switch"
                    name="display_player_only"
                    id="display_player_only"
                  />
                  Display the player only (without menus and configurations)
                  <SublimeInfo
                    target="display_player_only"
                    imgSrc={getImage("no-menu")}
                  />
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <Row className="marged-top">
            <Col xs={12} md={12}>
              <FormGroup>
                <Label
                  check
                  className="flex-row flex-center-items"
                  for="display_price"
                >
                  <SublimeSwitch
                    onChange={e => {
                      const isChecked = e.target.checked;
                      this.setState({ is_single_price: isChecked });
                      this.handleCheckChange(e);
                    }}
                    checked={display_price}
                    name="display_price"
                    id="display_price"
                  />
                  Display the price
                  <SublimeInfo
                    target="display_price"
                    imgSrc={getImage("price-admin")}
                  />
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <Row className="marged-top">
            <Col
              xs={12}
              md={12}
              className={
                display_price
                  ? "sublime-switch-content-active"
                  : "sublime-switch-content"
              }
            >
              <Price
                {...this.state}
                handleChange={this.handleChange}
                handleCheckChange={this.handleCheckChange}
                isPlayerOnly={display_player_only}
              />
            </Col>
          </Row>
        </Separator>
        <Row>
          <Label>
            <span
              style={{ fontSize: "20px", marginRight: "3px" }}
              className="sublime-red"
            >
              "*"
            </span>
            Mandatory fields
          </Label>
        </Row>
        <Row className="marged-bottom">
          <Col className="flex-row flex-end" sm={12} md={12}>
            <Button color="primary" type="submit">
              Next step
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}
