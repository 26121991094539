// @ts-nocheck
import React from 'react';
import sublimeLogo from '../../../../assets/images/logos/logo-exalt3d-sublime.png';
import moment from 'moment';
import SublimeTheme from '../../../../ui-kit/theme';
import { withRouter } from 'react-router-dom';
import { truncate } from '../../../../utils/string.ts';
import './index.scss';
function index({ id, title, subtitle, user, image_url, created_at, history }) {
  const url = image_url && image_url.length > 1 ? image_url : sublimeLogo;
  return (
    <div
      className='sublime-post-card pointed'
      role='link'
      onClick={() => history.push(`/dashboard/posts/${id}`)}
    >
      <div
        style={{ backgroundImage: `url(${url})` }}
        className='sublime-post-card-image'
      ></div>
      <div className='container marged-top sublime-post-card-body'>
        <span style={{ color: SublimeTheme.sublime.color.mainSilverRgba(0.5) }}>
          {moment(created_at).format('MMM Do YYYY')}
        </span>
        <div className='marged-top full-height'>
          <h4 style={{ color: SublimeTheme.sublime.color.mainGold }}>
            {truncate(title, 15, true)}
          </h4>
          <p style={{ color: SublimeTheme.sublime.color.mainBlack }}>
            {truncate(subtitle, 50, true)}
          </p>
        </div>
      </div>
    </div>
  );
}

export default withRouter(index);
