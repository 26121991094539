import React, { Component } from 'react';
import { Container, Row, Col, Table } from 'reactstrap';
import { SublimeTheme, SublimeSwitch } from 'exalt3d-sublime-ui';
import ConfigUploader from '../../uploader/ConfigUploader.tsx';
import { getTitleTree } from '../../../../utils/configurator/menu';
import { updateConfigField } from '../../../../store/actions/project';

const successColor = SublimeTheme.sublime.color.mainGreen;
const warningColor = SublimeTheme.sublime.color.mainGold;

export default class SingleUpload extends Component {
  constructor(props) {
    super(props);

    const { default_configuration } = props.store.getState().projects.project;

    this.state = {
      defaultConf: default_configuration
    };
  }

  render() {
    const { defaultConf } = this.state;
    const {
      store,
      configurations,
      uploadCounterCallback,
      alreadyUploaded
    } = this.props;
    const { menu, original_size, root_url } = store.getState().projects.project;

    return (
      <Container>
        <Row>
          <Col xs={12} md={12}>
            <Table responsive>
              <thead>
                <tr>
                  <th colSpan={1}>Config title</th>
                  <th colSpan={3}>Images</th>
                  <th colSpan={1}>Default config</th>
                </tr>
              </thead>
              <tbody>
                {configurations.map((config, idx) => {
                  const joinedNomenclature = config
                    .map(i => i.nomenclature)
                    .join('&');
                  const color =
                    alreadyUploaded[joinedNomenclature] === 1
                      ? successColor
                      : warningColor;
                  return (
                    <tr key={joinedNomenclature}>
                      <td
                        align='left'
                        style={{ verticalAlign: 'middle' }}
                        colSpan={1}
                      >
                        <span
                          style={{ color }}
                          dangerouslySetInnerHTML={{
                            __html: config
                              .map(i => getTitleTree(menu, i))
                              .join('<span class="sublime-red"> & </span>')
                          }}
                        />
                      </td>
                      <td
                        align='center'
                        style={{ verticalAlign: 'middle' }}
                        colSpan={3}
                      >
                        <ConfigUploader
                          canDeleteItem={root_url ? false : true}
                          isValid={alreadyUploaded[joinedNomenclature] === 1}
                          onUpload={response => console.log(response)}
                          uploadCounterCallback={uploadCounterCallback}
                          folderName={joinedNomenclature}
                          originalSize={original_size}
                          updateOriginalSize={({ width, height }) => {
                            return store.dispatch(
                              updateConfigField(store, {
                                original_size: `${width}x${height}`
                              })
                            );
                          }}
                        />
                      </td>
                      <td
                        align='center'
                        style={{ verticalAlign: 'middle' }}
                        colSpan={1}
                      >
                        <SublimeSwitch
                          onChange={() => {
                            this.setState({ defaultConf: joinedNomenclature });
                            store.dispatch(
                              updateConfigField(store, {
                                default_configuration: joinedNomenclature
                              })
                            );
                          }}
                          checked={defaultConf === joinedNomenclature}
                          name='defaultConf'
                          id='defaultConf'
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    );
  }
}
