import React from 'react';
import {
  Container,
  Row,
  Col,
  Badge,
  Modal,
  ModalBody,
  ModalHeader,
  Button
} from 'reactstrap';
import { SublimeTheme } from 'exalt3d-sublime-ui';
import Forbbidens from './Forbbidens';
import { toggleForbiddenMenu } from '../../../../utils/configurator/restriction';
import {
  getTitleTree,
  findMenuById
} from '../../../../utils/configurator/menu';

const greenColor = SublimeTheme.sublime.color.mainGreen;
const redColor = SublimeTheme.sublime.color.mainRed;
const silverColor = SublimeTheme.sublime.color.mainSilver;
export default function ForbiddensModal({
  treeData = [],
  currentItem = {},
  isActive = false,
  toggleModal = () => {},
  availableForbidden = [],
  context = {}
}) {
  return (
    <div className='sublime-forbiddens-modal'>
      <Modal centered size='lg' isOpen={isActive} toggle={toggleModal}>
        <ModalHeader style={{ backgroundColor: silverColor, color: 'white' }}>
          {`Menu: ${
            currentItem.node && currentItem.node.id
              ? getTitleTree(
                  treeData,
                  findMenuById(treeData, currentItem.node.id)
                )
              : ''
          }`}
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col xs={6} md={6}>
                <div className='full-height'>
                  <h5
                    style={{ backgroundColor: redColor, color: 'white' }}
                    className='text-center full-width'
                  >
                    Delete forbidden options
                  </h5>
                  <div className='flex-row flex-wrap full-width full-height'>
                    {currentItem.node.data &&
                    currentItem.node.data.forbids.length >= 1 ? (
                      <Forbbidens
                        hierarchy
                        context={context}
                        item={currentItem}
                        treeData={treeData}
                      />
                    ) : (
                      <h5>No forbidden menus</h5>
                    )}
                  </div>
                </div>
              </Col>
              <Col xs={6} md={6}>
                <div>
                  <div className='flex-row flex-wrap'>
                    <h5
                      style={{ backgroundColor: greenColor, color: 'white' }}
                      className='text-center full-width'
                    >
                      Choose forbidden options
                    </h5>
                    <div className='flex-row flex-wrap full-width full-height'>
                      {availableForbidden.length >= 1 ? (
                        availableForbidden.map(forb => {
                          if (
                            currentItem.node.data.forbids.includes(forb.node.id)
                          ) {
                            return null;
                          }
                          if (forb.node.children) {
                            // eslint-disable-next-line max-len
                            if (
                              !forb.node.children
                                .map(i => i.id)
                                .every(
                                  id =>
                                    !currentItem.node.data.forbids.includes(id)
                                )
                            ) {
                              return null;
                            }
                            return (
                              <div
                                key={forb.node.id}
                                className='flex-row flex-center marged-bottom'
                                style={{ width: 'auto' }}
                              >
                                <Badge
                                  style={{
                                    maxWidth: 'auto',
                                    backgroundColor:
                                      SublimeTheme.sublime.color.forbidden[
                                        `level${forb.path.length - 1}`
                                      ]
                                  }}
                                  className='marged-right container-elipsed-text pointed'
                                  onClick={() =>
                                    toggleForbiddenMenu(
                                      currentItem,
                                      forb,
                                      context
                                    )
                                  }
                                >
                                  {getTitleTree(
                                    treeData,
                                    findMenuById(treeData, forb.node.id)
                                  )}
                                </Badge>
                              </div>
                            );
                          }
                          return (
                            <div
                              key={forb.node.id}
                              className='flex-row flex-center marged-bottom'
                              style={{ width: 'auto' }}
                            >
                              <Badge
                                style={{
                                  maxWidth: 'auto',
                                  backgroundColor:
                                    SublimeTheme.sublime.color.forbidden[
                                      `level${forb.path.length - 1}`
                                    ]
                                }}
                                className='marged-right container-elipsed-text pointed'
                                onClick={() =>
                                  toggleForbiddenMenu(
                                    currentItem,
                                    forb,
                                    context
                                  )
                                }
                              >
                                {forb.node.data.title}
                              </Badge>
                            </div>
                          );
                        })
                      ) : (
                        <h5>No available forbidden</h5>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className='flex-row flex-center marged-top'>
                <Button
                  style={{ minWidth: '100px' }}
                  onClick={toggleModal}
                  size='sm'
                  color='primary'
                >
                  OK
                </Button>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    </div>
  );
}
