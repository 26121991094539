import React, { Component } from 'react';
// @ts-ignore
import Axios from 'axios';
import { Modal, Button, ModalBody } from 'reactstrap';
import url from '../../../../utils/api';
import { getProjectId, getUploaderToken } from '../../../../utils/sessions';
import { updateConfigField } from '../../../../store/actions/project';
import './defaultImageSelector.scss';
import { getPositionFromGrid } from '../../../../utils/array';
import {
  isValidFileName,
  replaceWithValidFilename
} from '../../../../utils/uploader/defaultImageSelector';

export default class DefaultImageSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: props.isOpen || false,
      images: [],
      defaultImage: null
    };

    this.mounted = false;
  }

  componentDidMount = () => {
    this.fetchAlreadyUploaded();

    // when an uploads is complete fetch all already uploaded files for this project
    this.uploadListener = document.addEventListener(
      'newUploads',
      () => {
        clearTimeout(this.fetcherTimeout);
        this.fetcherTimeout = setTimeout(() => {
          this.fetchAlreadyUploaded();
        }, 500);
      },
      false
    );
    this.mounted = true;
  };

  componentWillUnmount = () => {
    if (this.mounted) {
      // @ts-ignore
      document.removeEventListener('newUploads', this.uploadListener);
    }
    this.mounted = false;
  };

  fetchAlreadyUploaded = () => {
    const { folderName, store } = this.props;

    if (folderName) {
      const { projectId, userId, projectDisk } = getProjectId();
      Axios.get(url.uploaderUrl.byFolderFields, {
        params: {
          sublimeUserId: userId,
          sublimeProjectId: projectId,
          sublimeDiskName: projectDisk,
          sublimeFolderName: folderName.replace('&', '-'),
          originalSize: store.getState().projects.project.original_size
        },
        headers: {
          Authorization: getUploaderToken()
        }
      })
        .then(async res => {
          if (res.data.uploads && res.data.uploads.length >= 1) {
            this.setState({
              images: [...res.data.uploads]
            });
            const { projects } = store.getState();
            const { project } = projects;
            const { named_nomenclatures } = project;
            const defaultImage =
              named_nomenclatures[folderName].defaultImageUrl;
            if (defaultImage) {
              if (isValidFileName(defaultImage)) {
                this.setState({
                  defaultImage
                });
              } else {
                const newUrl = replaceWithValidFilename(
                  named_nomenclatures[folderName]
                );
                this.setState({
                  defaultImage: newUrl
                });
              }
            }
          }
        })
        .catch(err => {
          console.error('ERROR WHILE FETCHING ALREADY UPLOADED', err);
        });
    }
  };

  buildButtonOrPreview = () => {
    const { images, defaultImage } = this.state;
    const currentSelected = images.find(i => i.url === defaultImage);
    return currentSelected ? (
      <div className='flex-column flex-center-items'>
        <img
          width='100px'
          src={currentSelected.url}
          alt={currentSelected.fileName}
        />
        <span style={{ maxWidth: '100px' }} className='container-elipsed-text'>
          {currentSelected.fileName}
        </span>
      </div>
    ) : (
      <div>
        <Button color='primary' size='sm'>
          Select
        </Button>
      </div>
    );
  };

  toggleModal = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen
    });
    if (!isOpen) {
      this.fetchAlreadyUploaded();
    }
  };

  render() {
    const { isOpen, images, defaultImage } = this.state;
    const { store, folderName } = this.props;
    const { projects } = store.getState();
    const { project } = projects;
    const {
      horizontal_axis_count,
      vertical_axis_count,
      named_nomenclatures
    } = project;

    return (
      <div>
        <div role='button' onClick={this.toggleModal}>
          {this.buildButtonOrPreview()}
        </div>
        <Modal centered toggle={this.toggleModal} isOpen={isOpen}>
          <ModalBody>
            <div className='sublime-default-image-selector-container flex-row flex-center flex-center-items flex-wrap'>
              {images.map((imageData, index) => {
                let className =
                  'sublime-image-container flex-column flex-center-items pointed';
                if (defaultImage === imageData.url) {
                  className += ' sublime-image-container-selected';
                }
                return (
                  <div
                    role='button'
                    className={className}
                    key={imageData.id}
                    onClick={() => {
                      const itemPosition = getPositionFromGrid(
                        horizontal_axis_count,
                        vertical_axis_count,
                        index,
                        imageData
                      );
                      store.dispatch(
                        updateConfigField(store, {
                          named_nomenclatures: JSON.stringify({
                            ...named_nomenclatures,
                            [folderName]: {
                              ...named_nomenclatures[folderName],
                              defaultPosition: { ...itemPosition },
                              defaultImageUrl: imageData.url
                            }
                          })
                        })
                      );
                      this.setState({ defaultImage: imageData.url });
                    }}
                  >
                    <img
                      width='100px'
                      alt={imageData.fileName}
                      src={imageData.url}
                    />
                    <span
                      style={{ maxWidth: '100px' }}
                      className='container-elipsed-text'
                    >
                      {imageData.fileName}
                    </span>
                  </div>
                );
              })}
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
