import React from 'react';
import { TwitterPicker } from 'react-color';
import AttachmentUploader from '../../uploader/Attachment';

export default function(props) {
  const { item, context } = props;
  const { isColor, isThumbnail, color, fileDatas } = item.thumbnail;
  return (
    <div>
      {isColor && (
        <div className='flex-row flex-around'>
          <div
            style={{ width: '100px', height: 'auto', backgroundColor: color }}
          />
          <TwitterPicker
            triangle='hide'
            color={color}
            onChange={newColor => {
              context.setState(prevState => ({
                ...prevState,
                currentItem: {
                  ...prevState.currentItem,
                  thumbnail: {
                    ...prevState.currentItem.thumbnail,
                    color: newColor.hex
                  }
                }
              }));
            }}
          />
        </div>
      )}
      {isThumbnail && (
        <AttachmentUploader
          showPreview
          type='image'
          allreadyUploaded={fileDatas.id ? [fileDatas] : undefined}
          onUpload={response => {
            if (response.status === 200) {
              context.setState(prevState => ({
                ...prevState,
                currentItem: {
                  ...prevState.currentItem,
                  thumbnail: {
                    ...prevState.currentItem.thumbnail,
                    isColor: false,
                    isThumbnail: true,
                    url: response.data.upload.url,
                    fileDatas: { ...response.data.upload } // keep id and some file datas
                  }
                }
              }));
            }
          }}
          onDelete={response => {
            if (response.status === 200) {
              context.setState(prevState => ({
                ...prevState,
                currentItem: {
                  ...prevState.currentItem,
                  thumbnail: {
                    ...prevState.currentItem.thumbnail,
                    isColor: false,
                    isThumbnail: true,
                    url: '',
                    fileDatas: {}
                  }
                }
              }));
            }
          }}
        />
      )}
    </div>
  );
}
