import React from 'react';

import SublimeTheme from '../../../ui-kit/theme';

import './ProcessStatus.scss';

export default function ProcessStatus({ isRunning }) {
  const color = isRunning
    ? SublimeTheme.sublime.color.mainGreen
    : SublimeTheme.sublime.color.mainRed;
  const text = isRunning
    ? 'Process images is running'
    : 'Process images is stoped';
  return (
    <span className='flex-row'>
      <div
        style={{ backgroundColor: color }}
        className='sublime-status-pill'
      ></div>
      <span className='marged-left'>{text}</span>
    </span>
  );
}
