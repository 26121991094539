import React, { Component } from 'react';
import { Col, FormGroup, Label, Input } from 'reactstrap';
import { SublimeInfo, SublimeSwitch, getImage } from 'exalt3d-sublime-ui';
import { allCurrencies } from '../../../../utils/currency';

export default class Price extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currencies: []
    };
  }

  componentDidMount() {
    const currencies = allCurrencies();
    this.setState({ currencies });
  }

  render() {
    const { currencies } = this.state;
    const {
      handleChange,
      handleCheckChange,
      currency,
      is_single_price,
      single_price,
      is_admin_price,
      admin_price,
      display_price_on_each,
      price_increase,
      isPlayerOnly
    } = this.props;

    return (
      <Col xs={12} md={12}>
        <Col xs={12} md={6}>
          <FormGroup
            className={
              currencies.length >= 1
                ? 'sublime-switch-content-active'
                : 'sublime-switch-content'
            }
          >
            <Input
              type='select'
              name='currency'
              id='currency'
              onChange={handleChange}
              value={currency}
            >
              <option value='EUR'>Euro</option>
              <option value='USD'>United States Dollar</option>
              <option disabled>--------------------</option>
              {currencies.map(mapedCurrency => (
                <option
                  key={`mapedCurrency-${mapedCurrency.value}`}
                  value={mapedCurrency.value}
                >
                  {mapedCurrency.label}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup className='flex-row flex-center-items'>
            <SublimeSwitch
              disabled={isPlayerOnly}
              onChange={handleCheckChange}
              checked={is_single_price}
              name='is_single_price'
              id='is_single_price'
            />
            <Label className='flex-row' for='is_single_price'>
              Single price
              <SublimeInfo
                target='is_single_price'
                imgSrc={getImage('price')}
              />
            </Label>
          </FormGroup>
          <Col
            xs={12}
            md={12}
            className={
              is_single_price
                ? 'sublime-switch-content-active'
                : 'sublime-switch-content'
            }
          >
            <FormGroup>
              <Input
                onChange={handleChange}
                value={single_price}
                type='number'
                min={0}
                name='single_price'
                id='single_price'
                placeholder='ex: 1,000'
              />
            </FormGroup>
          </Col>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup className='flex-row flex-center-items'>
            <SublimeSwitch
              disabled={isPlayerOnly}
              onChange={handleCheckChange}
              checked={is_admin_price}
              name='is_admin_price'
              id='is_admin_price'
            />
            <Label className='flex-row' for='is_admin_price'>
              Price admin
              <SublimeInfo
                target='is_admin_price'
                imgSrc={getImage('price-admin')}
              />
            </Label>
          </FormGroup>
        </Col>
        <Col
          xs={12}
          md={12}
          className={
            is_admin_price
              ? 'sublime-switch-content-active'
              : 'sublime-switch-content'
          }
        >
          <Col xs={12} md={6}>
            <FormGroup>
              <Label for='name'>Base price (without option) : </Label>
              <Input
                onChange={handleChange}
                value={admin_price}
                type='number'
                min={0}
                name='admin_price'
                id='admin_price'
                placeholder='ex: 1,000'
              />
            </FormGroup>
          </Col>
          <Col xs={12} md={12}>
            <FormGroup className='flex-row flex-center-items'>
              <SublimeSwitch
                onChange={handleCheckChange}
                checked={display_price_on_each}
                name='display_price_on_each'
                id='display_price_on_each'
              />
              <Label className='flex-row' for='display_price_on_each'>
                Display the price of each option.
                <SublimeInfo
                  target='display_price_on_each'
                  imgSrc={getImage('price-option')}
                />
              </Label>
            </FormGroup>
          </Col>
          <Col xs={12} md={12}>
            <FormGroup className='flex-row flex-center-items'>
              <SublimeSwitch
                onChange={handleCheckChange}
                checked={price_increase}
                name='price_increase'
                id='price_increase'
              />
              <Label className='flex-row' for='price_increase'>
                Automatically increase the total price with selected options
                <SublimeInfo
                  target='price_increase'
                  imgSrc={getImage('automatically-increase')}
                />
              </Label>
            </FormGroup>
          </Col>
        </Col>
      </Col>
    );
  }
}
