import React, { Component } from 'react';
import SortableTree, { addNodeUnderParent } from 'react-sortable-tree';
import { Row, Col, Button } from 'reactstrap';
import { Confirm, Alert, SublimeTheme } from 'exalt3d-sublime-ui';
import ReactTooltip from 'react-tooltip';
import {
  FaPlus,
  FaPen,
  FaTrash,
  FaCopy
  // @ts-ignore
} from 'react-icons/fa';
// @ts-ignore
import MenuElement from './step2/MenuElementModal';
import {
  generateItem,
  addItem,
  duplicateItem,
  deleteItem,
  defaultItem,
  findMenuById
} from '../../../utils/configurator/menu';
import { isOnMobile, isOnTablet } from '../../../utils/screen';
import { updateConfigField } from '../../../store/actions/project';
// @ts-ignore
import './step2/style.scss';

const maxDepth = 4;

export default class SublimeConfStep2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newItem: defaultItem(),
      currentItem: defaultItem(),
      currentDragableItem: {},
      isSelected: false,
      treeData: props.store.getState().projects.project.menu
    };
  }

  componentDidMount() {
    setTimeout(() => {
      ReactTooltip.rebuild();
    }, 300);
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  addMenuElement = () => {
    const { treeData, newItem } = this.state;
    const newItemGenerated = generateItem({
      ...newItem
    });
    const newTree = addNodeUnderParent({
      treeData,
      newNode: newItemGenerated,
      expandParent: true,
      parentKey: null,
      getNodeKey: ({ node }) => node.id
    });
    this.setState({ treeData: [...newTree.treeData] });
    const formatedItem = findMenuById(newTree.treeData, newItemGenerated.id);
    this.editAction(formatedItem);
  };

  handleNewItemChange = event => {
    const { newItem } = this.state;
    this.setState(prevState => ({
      ...prevState,
      newItem: { ...newItem, [event.target.name]: event.target.value }
    }));
  };

  resetSelected = () => {
    this.setState(prevState => ({
      ...prevState,
      newItem: defaultItem(),
      currentItem: defaultItem(),
      currentDragableItem: {},
      isSelected: false
    }));
  };

  editAction = item => {
    this.setState({
      currentDragableItem: { ...item },
      currentItem: { ...item.node.data, id: item.node.id },
      isSelected: true
    });
  };

  submitForm = () => {
    const { treeData } = this.state;
    const { submit } = this.props;
    submit({ menu: treeData });
  };

  save = () => {
    const { treeData } = this.state;
    const { store } = this.props;
    store.dispatch(updateConfigField(store, { menu: treeData }));
  };

  currentInEditAlert() {
    Alert(
      'Alert',
      'An item is currently editing, please validate first',
      () => {}
    );
  }

  render() {
    const {
      treeData,
      currentItem,
      isSelected,
      currentDragableItem
    } = this.state;
    const { store, prevStep } = this.props;
    return (
      <>
        <ReactTooltip />
        <Col xs={12} md={12} className='marged-top'>
          <Row>
            <Button
              color='primary'
              onClick={() => {
                if (isSelected) {
                  return this.currentInEditAlert();
                }
                this.addMenuElement();
              }}
            >
              <span className='marged-right'>
                <FaPlus />
              </span>
              Add new menu item
            </Button>
          </Row>
          <Row
            style={{
              width: `${isOnTablet() || isOnMobile() ? '110%' : '100%'}`
            }}
          >
            <Col
              className='sublime-tree-container boxed marged-top marged-bottom'
              xs={6}
              md={6}
              style={{ height: '70vh' }}
            >
              <SortableTree
                treeData={treeData}
                onChange={newTreeData => {
                  this.setState({ treeData: [...newTreeData] });
                  store.dispatch(updateConfigField(store, { menu: treeData }));
                }}
                onMoveNode={this.resetSelected}
                getNodeKey={({ node }) => node.id}
                maxDepth={maxDepth}
                generateNodeProps={item => ({
                  buttons: [
                    // AddNew button (conditional)
                    item.path.length < maxDepth ? (
                      <span
                        data-tip='Click to add a child menu here'
                        ref={ref => (this.addItemRef = ref)}
                        role='button'
                        className='marged-right pointed'
                      >
                        <FaPlus
                          onClick={() => {
                            ReactTooltip.hide(this.addItemRef);
                            if (isSelected) {
                              return this.currentInEditAlert();
                            }
                            addItem(item, this);
                          }}
                          color={SublimeTheme.sublime.color.menu.action.add}
                        />
                      </span>
                    ) : null,
                    // Duplicate button
                    <span
                      data-tip='Click to duplicate menu as a child'
                      ref={ref => (this.dupItemRef = ref)}
                      role='button'
                      className='marged-right pointed'
                    >
                      <FaCopy
                        onClick={() => {
                          ReactTooltip.hide(this.dupItemRef);
                          if (isSelected) {
                            return this.currentInEditAlert();
                          }
                          duplicateItem(item, this);
                        }}
                        color={SublimeTheme.sublime.color.menu.action.duplicate}
                      />
                    </span>,
                    // Edit button
                    <span
                      data-tip='Click to edit menu'
                      ref={ref => (this.editItemRef = ref)}
                      role='button'
                      className='marged-right pointed'
                    >
                      <FaPen
                        onClick={() => {
                          ReactTooltip.hide(this.editItemRef);
                          if (isSelected) {
                            return this.currentInEditAlert();
                          }
                          this.editAction(item);
                        }}
                        color={SublimeTheme.sublime.color.menu.action.edit}
                      />
                    </span>,
                    // Delete button
                    <span
                      data-tip='Click to delete menu'
                      ref={ref => (this.delItemRef = ref)}
                      role='button'
                      className='marged-right pointed'
                    >
                      <FaTrash
                        onClick={() => {
                          ReactTooltip.hide(this.delItemRef);
                          if (isSelected) {
                            return this.currentInEditAlert();
                          }
                          // eslint-disable-next-line no-restricted-globals
                          Confirm(
                            `Confirm delete menu : ${item.node.title}`,
                            'Are you sure you want delete this menu?',
                            async () => {
                              await deleteItem(item, this);
                              this.resetSelected();
                            }, // Confirmed
                            () => {}, // Canceled
                            undefined
                          );
                        }}
                        color={SublimeTheme.sublime.color.menu.action.delete}
                      />
                    </span>
                  ],
                  className:
                    item.node.id === currentItem.id
                      ? 'sublime-config-menu sublime-config-menu-active'
                      : 'sublime-config-menu'
                })}
              />
            </Col>
            <Col
              id='menu-element-modal'
              className={`marged-top ${isSelected ? 'boxed' : ''}`}
              xs={6}
              md={6}
              style={{ height: '70vh', overflow: 'scroll', borderLeft: 'none' }}
            >
              {isSelected && (
                <div className='marged-top'>
                  <MenuElement
                    item={currentItem}
                    dragableItem={currentDragableItem}
                    context={this}
                  />
                </div>
              )}
            </Col>
          </Row>
          <Row className='marged-bottom'>
            <Col xs={12}>
              <div
                className={`marged-top flex-row ${
                  isOnMobile() ? 'flex-between' : 'flex-end'
                }`}
              >
                <div className='marged-right'>
                  <Button disabled={isSelected} onClick={prevStep}>
                    Prev step
                  </Button>
                </div>
                <div className='marged-right'>
                  <Button
                    color='primary'
                    disabled={isSelected || treeData.length < 1}
                    onClick={this.submitForm}
                  >
                    Next step
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}
