import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { IStoreState, IAuth } from '../../../interfaces/store';
import SublimeTheme from '../../../ui-kit/theme';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { IoIosStats } from 'react-icons/io';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { TiHome } from 'react-icons/ti';
import { MdCreateNewFolder, MdLibraryBooks } from 'react-icons/md';
import { IoMdHelpCircle } from 'react-icons/io';
import { GoFileSubmodule } from 'react-icons/go';
//@ts-ignore
// import LogOut from '../../shared/logout';
//@ts-ignore
// import Avatar from '../../shared/user/Avatar';

interface MenuDrawerProps {
  children: any;
  auth: IAuth;
  history: any;
  t: any;
  isMenuOpen: boolean;
}

const MenuDrawer: React.FunctionComponent<MenuDrawerProps> = props => {
  const { history, t, auth, isMenuOpen } = props;
  const [currentPage, setcurrentPage] = React.useState('');

  const iconSize = '22px';

  return (
    <div>
      <List>
        <ListItem
          selected={currentPage === 'home'}
          onClick={() => {
            setcurrentPage('home');
            history.push('/dashboard/home');
          }}
          button
          key={'dashboard'}
        >
          <ListItemIcon>
            <IoIosStats
              color={
                currentPage === 'home'
                  ? SublimeTheme.sublime.color.mainGold
                  : 'white'
              }
              size={iconSize}
            />
          </ListItemIcon>
          <ListItemText>
            <span
              style={{
                color:
                  currentPage === 'home'
                    ? SublimeTheme.sublime.color.mainGold
                    : 'white'
              }}
            >
              {t('menu.dashboard')}
            </span>
          </ListItemText>
        </ListItem>
        {
          // ACOUNT
        }
        <ListItem
          selected={currentPage === 'account'}
          onClick={() => {
            setcurrentPage('account');
          }}
          button
          key={'account'}
        >
          <ListItemIcon>
            <TiHome
              color={
                currentPage === 'account'
                  ? SublimeTheme.sublime.color.mainGold
                  : 'white'
              }
              size={iconSize}
            />
          </ListItemIcon>
          <ListItemText>
            <span
              style={{
                color:
                  currentPage === 'account'
                    ? SublimeTheme.sublime.color.mainGold
                    : 'white'
              }}
            >
              {t('menu.account')}
            </span>
          </ListItemText>
          {currentPage === 'account' ? (
            <ExpandLess color='primary' />
          ) : (
            <ExpandMore color='secondary' />
          )}
        </ListItem>
        <Collapse in={currentPage === 'account'} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            <ListItem
              selected={currentPage === 'account'}
              onClick={() => {
                history.push('/dashboard/account/profile');
              }}
              button
              key={'profile'}
            >
              <ListItemText>
                <span
                  style={{
                    whiteSpace: 'normal',
                    fontSize: isMenuOpen ? '15px' : '11px'
                  }}
                >
                  {t('menu.subMenus.account.profile')}
                </span>
              </ListItemText>
            </ListItem>
            <ListItem
              selected={currentPage === 'account'}
              onClick={() => {
                history.push('/dashboard/account/offers');
              }}
              button
              key={'package'}
            >
              <ListItemText>
                <span
                  style={{
                    whiteSpace: 'normal',
                    fontSize: isMenuOpen ? '15px' : '11px'
                  }}
                >
                  {t('menu.subMenus.subscriptions.package')}
                </span>
              </ListItemText>
            </ListItem>
            <ListItem
              selected={currentPage === 'account'}
              onClick={() => {
                history.push('/dashboard/account/stats');
              }}
              button
              key={'stats'}
            >
              <ListItemText>
                <span
                  style={{
                    whiteSpace: 'normal',
                    fontSize: isMenuOpen ? '15px' : '11px'
                  }}
                >
                  {t('menu.subMenus.account.stats')}
                </span>
              </ListItemText>
            </ListItem>
            <ListItem
              selected={currentPage === 'account'}
              onClick={() => {
                history.push('/dashboard/account/billing');
              }}
              button
              key={'billing'}
            >
              <ListItemText>
                <span
                  style={{
                    whiteSpace: 'normal',
                    fontSize: isMenuOpen ? '15px' : '11px'
                  }}
                >
                  {t('menu.subMenus.account.billing')}
                </span>
              </ListItemText>
            </ListItem>
          </List>
        </Collapse>
        <ListItem
          selected={currentPage === 'projects/new'}
          onClick={() => {
            setcurrentPage('projects/new');
            history.push('/dashboard/projects/new');
          }}
          button
          key={'projects/new'}
        >
          <ListItemIcon>
            <MdCreateNewFolder
              color={
                currentPage === 'projects/new'
                  ? SublimeTheme.sublime.color.mainGold
                  : 'white'
              }
              size={iconSize}
            />
          </ListItemIcon>
          <ListItemText>
            <span
              style={{
                color:
                  currentPage === 'projects/new'
                    ? SublimeTheme.sublime.color.mainGold
                    : 'white'
              }}
            >
              {t('menu.subMenus.projects.new')}
            </span>
          </ListItemText>
        </ListItem>
        {
          // PROJECTS
        }
        <ListItem
          selected={currentPage === 'projects'}
          onClick={() => {
            setcurrentPage('projects');
          }}
          button
          key={'projects'}
        >
          <ListItemIcon>
            <GoFileSubmodule
              color={
                currentPage === 'projects'
                  ? SublimeTheme.sublime.color.mainGold
                  : 'white'
              }
              size={iconSize}
            />
          </ListItemIcon>
          <ListItemText>
            <span
              style={{
                color:
                  currentPage === 'projects'
                    ? SublimeTheme.sublime.color.mainGold
                    : 'white'
              }}
            >
              {t('menu.projects')}
            </span>
          </ListItemText>
          {currentPage === 'projects' ? (
            <ExpandLess color='primary' />
          ) : (
            <ExpandMore color='secondary' />
          )}
        </ListItem>
        <Collapse in={currentPage === 'projects'} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            <ListItem
              selected={currentPage === 'projects'}
              onClick={() => {
                history.push('/dashboard/projects/in-progress');
              }}
              button
              key={'projects/in-progress'}
            >
              <ListItemText>
                <span
                  style={{
                    whiteSpace: 'normal',
                    fontSize: isMenuOpen ? '15px' : '11px'
                  }}
                >
                  {t('menu.subMenus.projects.inProgress')}
                </span>
              </ListItemText>
            </ListItem>
            <ListItem
              selected={currentPage === 'projects'}
              onClick={() => {
                history.push('/dashboard/projects/availables');
              }}
              button
              key={'projects/availables'}
            >
              <ListItemText>
                <span
                  style={{
                    whiteSpace: 'normal',
                    fontSize: isMenuOpen ? '15px' : '11px'
                  }}
                >
                  {t('menu.subMenus.projects.availables')}
                </span>
              </ListItemText>
            </ListItem>
            <ListItem
              selected={currentPage === 'projects'}
              onClick={() => {
                history.push('/dashboard/projects/archives');
              }}
              button
              key={'projects/archives'}
            >
              <ListItemText>
                <span
                  style={{
                    whiteSpace: 'normal',
                    fontSize: isMenuOpen ? '15px' : '11px'
                  }}
                >
                  {t('menu.subMenus.projects.archives')}
                </span>
              </ListItemText>
            </ListItem>
          </List>
        </Collapse>
        {
          // LIBRARY
        }
        <ListItem
          onClick={() => {
            setcurrentPage('library');
            history.push('/dashboard/library');
          }}
          selected={currentPage === 'library'}
          button
          key={'library'}
        >
          <ListItemIcon>
            <MdLibraryBooks
              color={
                currentPage === 'library'
                  ? SublimeTheme.sublime.color.mainGold
                  : 'white'
              }
              size={iconSize}
            />
          </ListItemIcon>
          <ListItemText>
            <span
              style={{
                color:
                  currentPage === 'library'
                    ? SublimeTheme.sublime.color.mainGold
                    : 'white'
              }}
            >
              {t('menu.library')}
            </span>
          </ListItemText>
        </ListItem>
        {
          // SUPPORT
        }
        <ListItem
          selected={currentPage === 'help'}
          onClick={() => {
            setcurrentPage('help');
            history.push('/dashboard/help');
          }}
          button
          key={'help'}
        >
          <ListItemIcon>
            <IoMdHelpCircle
              color={
                currentPage === 'help'
                  ? SublimeTheme.sublime.color.mainGold
                  : 'white'
              }
              size={iconSize}
            />
          </ListItemIcon>
          <ListItemText>
            <span
              style={{
                color:
                  currentPage === 'help'
                    ? SublimeTheme.sublime.color.mainGold
                    : 'white'
              }}
            >
              {t('menu.help')}
            </span>
          </ListItemText>
        </ListItem>

        {// ADMIN SIDE
        auth.isAdmin && (
          <>
            <ListItem
              selected={currentPage === 'admin'}
              onClick={() => {
                setcurrentPage('admin');
              }}
              button
              key={'admin'}
            >
              <ListItemIcon>
                <GoFileSubmodule
                  color={
                    currentPage === 'admin'
                      ? SublimeTheme.sublime.color.mainGold
                      : 'white'
                  }
                  size={iconSize}
                />
              </ListItemIcon>
              <ListItemText>
                <span
                  style={{
                    color:
                      currentPage === 'admin'
                        ? SublimeTheme.sublime.color.mainGold
                        : 'white'
                  }}
                >
                  {t('menu.admin')}
                </span>
              </ListItemText>
              {currentPage === 'admin' ? (
                <ExpandLess color='primary' />
              ) : (
                <ExpandMore color='secondary' />
              )}
            </ListItem>
            <Collapse in={currentPage === 'admin'} timeout='auto' unmountOnExit>
              <List component='div' disablePadding>
                <ListItem
                  selected={currentPage === 'admin'}
                  onClick={() => {
                    history.push('/dashboard/admin/users');
                  }}
                  button
                  key={'/admin/users'}
                >
                  <ListItemText>
                    <span
                      style={{
                        whiteSpace: 'normal',
                        fontSize: isMenuOpen ? '15px' : '11px'
                      }}
                    >
                      {t('menu.subMenus.admin.users')}
                    </span>
                  </ListItemText>
                </ListItem>
                <ListItem
                  selected={currentPage === 'admin'}
                  onClick={() => {
                    history.push('/dashboard/admin/projects');
                  }}
                  button
                  key={'/admin/projects'}
                >
                  <ListItemText>
                    <span
                      style={{
                        whiteSpace: 'normal',
                        fontSize: isMenuOpen ? '15px' : '11px'
                      }}
                    >
                      {t('menu.subMenus.admin.projects')}
                    </span>
                  </ListItemText>
                </ListItem>
                <ListItem
                  selected={currentPage === 'admin'}
                  onClick={() => {
                    history.push('/dashboard/admin/posts');
                  }}
                  button
                  key={'/admin/posts'}
                >
                  <ListItemText>
                    <span
                      style={{
                        whiteSpace: 'normal',
                        fontSize: isMenuOpen ? '15px' : '11px'
                      }}
                    >
                      {t('menu.subMenus.admin.posts')}
                    </span>
                  </ListItemText>
                </ListItem>
                <ListItem
                  selected={currentPage === 'admin'}
                  onClick={() => {
                    history.push('/dashboard/admin/offers');
                  }}
                  button
                  key={'/admin/offers'}
                >
                  <ListItemText>
                    <span
                      style={{
                        whiteSpace: 'normal',
                        fontSize: isMenuOpen ? '15px' : '11px'
                      }}
                    >
                      {t('menu.subMenus.admin.offers')}
                    </span>
                  </ListItemText>
                </ListItem>
                <ListItem
                  selected={currentPage === 'admin'}
                  onClick={() => {
                    history.push('/dashboard/admin/logs');
                  }}
                  button
                  key={'/admin/logs'}
                >
                  <ListItemText>
                    <span
                      style={{
                        whiteSpace: 'normal',
                        fontSize: isMenuOpen ? '15px' : '11px'
                      }}
                    >
                      {t('menu.subMenus.admin.logs')}
                    </span>
                  </ListItemText>
                </ListItem>
                <ListItem
                  selected={currentPage === 'admin'}
                  onClick={() => {
                    history.push('/dashboard/admin/statistics');
                  }}
                  button
                  key={'/admin/statistics'}
                >
                  <ListItemText>
                    <span
                      style={{
                        whiteSpace: 'normal',
                        fontSize: isMenuOpen ? '15px' : '11px'
                      }}
                    >
                      {t('menu.subMenus.admin.statistics')}
                    </span>
                  </ListItemText>
                </ListItem>
              </List>
            </Collapse>
          </>
        )}
      </List>
    </div>
  );
};

const mapStateToProps = (state: IStoreState) => ({
  auth: state.auth,
  user: state.user
});

const mapDispatchToProps = {};

export default withRouter(
  withTranslation(['dashboard', 'navbar'])(
    // @ts-ignore
    connect(mapStateToProps, mapDispatchToProps)(MenuDrawer)
  )
);
