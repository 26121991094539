import React, { Component } from 'react';
import { connect } from 'react-redux';
import SublimeHeader from '../../shared/SublimeHeader';
import SublimeTheme from '../../../ui-kit/theme';
import SublimeSpnr from '../../../ui-kit/Spinners/SublimeSpinner/index';
export class Show extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      post: null
    };
  }

  componentDidMount() {
    const { match, posts } = this.props;
    const { id } = match.params;
    const post = posts.find(post => post.id === parseInt(id));
    console.log(post);
    this.setState({ loading: post === undefined, post });
  }

  render() {
    const { loading, post } = this.state;
    const { history } = this.props;
    return (
      <div className='layout-margin'>
        <div
          style={{ color: SublimeTheme.sublime.color.mainGold }}
          role='link'
          className='pointed'
          onClick={() => history.push('/dashboard/home')}
        >
          {'<- Back'}
        </div>
        <div className='marged-top'>
          {loading ? (
            <SublimeSpnr />
          ) : (
            <div>
              <span className='text-center'>
                <SublimeHeader title={post.title} />
              </span>
              <h6 className='text-center'>{post.subtitle}</h6>
              <div
                dangerouslySetInnerHTML={{ __html: post.body }}
                className='marged-top text-center'
              ></div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  posts: state.posts
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Show);
