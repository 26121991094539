export const truncate = (string: string, length: number, addDots = false) => {
  if (string.length <= length) return string;

  return `${string.substr(0, length)}${addDots ? ' ...' : ''}`;
};

export const projectStatusFormater = (status: string) => {
  switch (status) {
    case 'in_progress':
      return 'In progress';
    case 'ready':
      return 'Available';
    case 'inactive':
      return 'Archived';
    default:
      return status;
  }
};

export function parametrize(string: string) {
  const splitedString = string.split('.');
  splitedString.pop();
  const joinedString = splitedString.join('-');
  return joinedString
    .trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9 -]/, '')
    .replace(/\s/g, '-');
}

export function generateRandomFileName(originalFileName: string) {
  const ext = originalFileName.split('.').pop();
  return `${new Date().getTime()}.${ext}`;
}
