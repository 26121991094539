import React, { Component } from "react";
import { Alert } from "reactstrap";
import { connect } from "react-redux";
import { clearMessages } from "../../../store/actions/app";
import "./index.scss";

function formatTime(type) {
  switch (type) {
    case "danger":
      return 20000;
    case "warning":
      return 15000;
    case "info":
      return 10000;
    case "success":
      return 5000;
    default:
      return 5000;
  }
}

export class AlertBar extends Component {
  componentDidMount() {
    const { clearMessagesBox } = this.props;
    clearMessagesBox();
  }

  componentDidUpdate(prevProps, prevState) {
    const { app, clearMessagesBox } = this.props;
    const { messages } = app;

    if (messages.length >= 1) {
      clearTimeout(this.reset);
      this.reset = setTimeout(() => {
        clearMessagesBox();
      }, formatTime(messages[0].type));
    }
  }

  componentWillUnmount() {
    const { clearMessagesBox } = this.props;
    clearMessagesBox();
  }

  render() {
    const { app, clearMessagesBox } = this.props;
    const { messages } = app;
    return messages.map((msg, idx) => {
      return (
        <Alert
          style={{ zIndex: 9999 }}
          className="sublime-notifications"
          toggle={clearMessagesBox}
          key={idx}
          color={msg.type}
        >
          <div>{msg.message}</div>
        </Alert>
      );
    });
  }
}

const mapStateToProps = state => ({
  app: state.app
});

const mapDispatchToProps = {
  clearMessagesBox: clearMessages
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertBar);
