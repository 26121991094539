const escapteObjects = ['offer'];

// This function is used to return a builded formData whith files if passed or without if not
export function buildFormData(objectDatas) {
  let formData = new FormData();
  const keys = Object.keys(objectDatas);
  keys.forEach((key, idx) => {
    if (!isObject(objectDatas[key])) {
      formData.append(key, objectDatas[key]);
    } else {
      if (escapteObjects.includes(key)) {
        return;
      }
      formData.append(key, objectDatas[key], objectDatas[key].name);
    }
  });
  return formData;
}

function isObject(value) {
  return value && typeof value === 'object' && value.constructor === Object;
}