import {
  UPDATE_CONFIGURATOR_FIELD,
  LOGIN_USER,
  LOGOUT_USER,
  CLEAR_PROJECT_DATA
} from '../types/project';

const initialState = {
  project: {
    id: null,
    user: {},
    name: '',
    player_size: '',
    title: '',
    subtitle: '',
    currency: 'EUR',
    domains: [],
    top_description: '',
    bottom_description: '',
    display_player_only: false,
    display_price: false,
    is_single_price: false,
    single_price: '',
    is_admin_price: false,
    admin_price: '',
    display_price_on_each: false,
    price_increase: false,
    top_level_rule: 'OR', // Add this to migration
    is_grid: true,
    horizontal_axis_count: 12,
    vertical_axis_count: 12,
    horizontal_loop: true,
    vertical_loop: true,
    menu: [],
    flat_menu: [],
    configurations: [],
    default_position: { h: 1, v: 1 },
    default_configuration: null,
    named_nomenclatures: {},
    original_size: undefined
  },
  userTokens: {},
  uploaderToken: '',
  isConnected: false,
  onCreate: true
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGIN_USER:
      return {
        ...state,
        userTokens: payload.tokens,
        uploaderToken: payload.uploaderToken,
        isConnected: true
      };

    case LOGOUT_USER:
      return initialState;

    case UPDATE_CONFIGURATOR_FIELD:
      return {
        ...state,
        project: {
          ...state.project,
          ...payload,
          domains: payload.domains
            ? [...payload.domains]
            : [...state.project.domains],
          configurations: payload.configurations
            ? [...payload.configurations]
            : [...state.project.configurations]
        }
      };

    case CLEAR_PROJECT_DATA:
      return { ...initialState };

    default:
      return state;
  }
};
