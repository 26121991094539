import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  fetchPosts,
  updatePost,
  createPost,
  deletePost
} from '../../../store/actions/posts';
import {
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  ButtonGroup
} from 'reactstrap';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { FaTrash, FaPen } from 'react-icons/fa';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ReactTable from 'react-table';
import ReactTooltip from 'react-tooltip';
import Axios from 'axios';
import Confirm from '../../../ui-kit/messageBox/Confirm/index';
import url from '../../../api/url';
import { buildTokenHeaders } from '../../../store/actions/auth';
import { MdNoteAdd } from 'react-icons/md';
import SublimeHeader from '../../shared/SublimeHeader';

const defaultPost = {
  id: null,
  title: '',
  subtitle: '',
  image: {},
  image_url: '',
  newImage: false,
  body: EditorState.createEmpty(),
  status: 'published'
};
const status = ['published', 'inactive'];
export class PostsManagment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      onCreate: true,
      post: defaultPost
    };
  }

  componentDidMount() {
    const { fetchPosts } = this.props;
    fetchPosts();
  }

  toggleModal = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };

  handleChange = event => {
    event.persist();
    this.setState(prevState => ({
      ...prevState,
      post: {
        ...prevState.post,
        [event.target.name]: event.target.value
      }
    }));
  };

  columnsTemplate = () => {
    const { t, deletePost } = this.props;
    return [
      { Header: t('posts.fields.id'), accessor: 'id', maxWidth: 30 },
      { Header: t('posts.fields.title'), accessor: 'title' },
      { Header: t('posts.fields.status'), accessor: 'status' },
      {
        Header: t('shared:words.actions'),
        maxWidth: 150,
        Cell: ({ row, tdProps }) => {
          return (
            <ButtonGroup size='sm'>
              <Button
                data-tip={t('shared:ressources.edit')}
                color='warning'
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  tdProps.rest.onClick();
                }}
              >
                <FaPen />
              </Button>
              <Button
                data-tip={t('shared:ressources.delete')}
                color='danger'
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  Confirm(
                    t('posts.delete.confirm.title'),
                    t('posts.delete.confirm.message', {
                      title: row.title || row.id
                    }),
                    async () => {
                      deletePost(row.id);
                    },
                    () => {},
                    undefined
                  );
                }}
              >
                <FaTrash />
              </Button>
            </ButtonGroup>
          );
        }
      }
    ];
  };

  submitForm = async event => {
    const { onCreate, post } = this.state;
    const { updatePost, createPost } = this.props;
    post.body = draftToHtml(convertToRaw(post.body.getCurrentContent()));
    event.preventDefault();
    if (onCreate) {
      await createPost(post);
    } else {
      await updatePost(post, post.id);
    }
    this.setState({ isOpen: false, post: { ...defaultPost }, onCreate: true });
  };

  render() {
    const { isOpen, post } = this.state;
    const { posts, t } = this.props;
    return (
      <div className='layout-margin'>
        <SublimeHeader title={t('posts.title')} />
        <ReactTooltip />
        <div className='marged-top marged-left marged-bottom'>
          <Button
            onClick={() =>
              this.setState({
                post: { ...defaultPost },
                onCreate: true,
                isOpen: true
              })
            }
          >
            <MdNoteAdd size={26} />
          </Button>
        </div>
        <ReactTable
          className='-striped -highlight'
          defaultPageSize={10}
          expanded={false}
          data={posts}
          columns={this.columnsTemplate()}
          getTdProps={(state, rowInfo) => {
            return {
              onClick: e => {
                if (rowInfo) {
                  const html = rowInfo.original.body;
                  const contentBlock = htmlToDraft(html);
                  if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(
                      contentBlock.contentBlocks
                    );
                    const editorState = EditorState.createWithContent(
                      contentState
                    );
                    this.setState({
                      isOpen: true,
                      onCreate: false,
                      post: { ...rowInfo.original, body: editorState }
                    });
                  }
                }
              }
            };
          }}
        />
        <Modal size='lg' centered toggle={this.toggleModal} isOpen={isOpen}>
          <ModalHeader>{post.title}</ModalHeader>
          <ModalBody>
            <Form onSubmit={this.submitForm}>
              <FormGroup>
                <Label for='title'>{t('posts.fields.title')}</Label>
                <Input
                  name='title'
                  id='title'
                  value={post.title}
                  onChange={this.handleChange}
                  placeholder={t('posts.fields.title')}
                />
              </FormGroup>
              <FormGroup>
                <Label for='subtitle'>{t('posts.fields.subtitle')}</Label>
                <Input
                  name='subtitle'
                  id='subtitle'
                  value={post.subtitle}
                  onChange={this.handleChange}
                  placeholder={t('posts.fields.subtitle')}
                />
              </FormGroup>
              <FormGroup>
                <Label for='image'>{t('posts.fields.image')}</Label>
                {post.image_url && post.image_url.length > 1 ? (
                  <div
                    role='button'
                    className='pointed'
                    onClick={() => {
                      this.setState(prevState => {
                        return {
                          ...prevState,
                          post: {
                            ...prevState.post,
                            image_url: ''
                          }
                        };
                      });
                    }}
                  >
                    <img src={post.image_url} alt={post.title} width='100' />
                  </div>
                ) : (
                  <Input
                    type='file'
                    name='image'
                    id='image'
                    onChange={e => {
                      const file = e.target.files[0];
                      this.setState(prevState => {
                        return {
                          ...prevState,
                          post: {
                            ...prevState.post,
                            image: file,
                            newImage: true
                          }
                        };
                      });
                    }}
                  />
                )}
              </FormGroup>
              <FormGroup>
                <Label for='status'>{t('posts.fields.status')}</Label>
                <Input
                  type='select'
                  name='status'
                  id='status'
                  value={post.status}
                  onChange={this.handleChange}
                  placeholder={t('posts.fields.status')}
                >
                  {status.map(stat => {
                    return (
                      <option key={stat} value={stat}>
                        {stat}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
              <div>
                <Label for='body'>{t('posts.fields.body')}</Label>
                <Editor
                  editorState={post.body}
                  wrapperClassName='demo-wrapper'
                  editorClassName='demo-editor'
                  onEditorStateChange={editorState => {
                    this.setState(prevState => ({
                      ...prevState,
                      post: { ...prevState.post, body: editorState }
                    }));
                  }}
                  toolbar={{
                    image: {
                      uploadCallback: file => {
                        const formData = new FormData();
                        formData.append('file', file);
                        return Axios.post(`${url.editors}/image`, formData, {
                          headers: { ...buildTokenHeaders() }
                        });
                      }
                    }
                  }}
                />
              </div>
              <div className='flex-row flex-end marged-top'>
                <Button disabled={!(post.title.length >= 1)} type='submit'>
                  Valid
                </Button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  posts: state.posts
});

const mapDispatchToProps = {
  fetchPosts,
  updatePost,
  createPost,
  deletePost
};

// @ts-ignore
export default withTranslation(['admin', 'shared'])(
  connect(mapStateToProps, mapDispatchToProps)(PostsManagment)
);
