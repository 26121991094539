export const getNameAndExt = (url: string): any => {
  let basename = url.split('/').pop() || '';
  const baseExt = basename.split('.').pop() || '';
  basename = basename.replace(`.${baseExt}`, '');
  return { name: basename, ext: baseExt }
}

export const isValidFileName = (url: string): boolean => {
  return /\d+[x]\d+\b/.test(getNameAndExt(url).name)
}

export const replaceWithValidFilename = (namedNomenclature: any): string => {
  const basename = getNameAndExt(namedNomenclature.defaultImageUrl);
  const newFilename = `${namedNomenclature.defaultPosition.x}x${namedNomenclature.defaultPosition.y}.jpg`;
  return namedNomenclature.defaultImageUrl.replace(`${basename.name}.${basename.ext}`, newFilename)
}