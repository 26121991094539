import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  CustomInput,
  Badge
} from 'reactstrap';
import Axios from 'axios';
import { Confirm } from 'exalt3d-sublime-ui';
import { withRouter } from 'react-router-dom';
import SingleUpload from './step4/SingleUpload';
import GridUpload from './step4/GridUpload';
import {
  updateConfigField,
  clearProject
} from '../../../store/actions/project';
import { formatedTree } from '../../../utils/configurator/restriction';
import { getProjectId, getUploaderToken } from '../../../utils/sessions';
import url from '../../../utils/api';
import destroyAllUploadsConfigsForProject from '../../../utils/uploader/delete';
import projectIsCookingAction from '../../../store/actions/projectIsCooking';
import { isOnMobile } from '../../../utils/screen';
import { resetConfigs } from '../../../utils/uploader/config';

class SublimeConfStep4 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isGrid: false,
      configSaved: false,
      configurations: [],
      alreadyUploadedFiles: 0,
      needUploadsFiles: 0
    };

    this.keysObjectForCounter = {};
  }

  componentDidMount() {
    const { store } = this.props;
    const { project } = store.getState().projects;
    this.setState({
      configurations: [...project.configurations],
      isGrid: project.is_grid
    });
    // this.countAlreadyUploaded();
  }

  countAlreadyUploaded = () => {
    const { store } = this.props;
    const { project } = store.getState().projects;
    const { projectId, userId, projectDisk } = getProjectId();
    const uploadToken = getUploaderToken();
    project.configurations.forEach(key => {
      const formatedKey = key.map(i => i.nomenclature).join('&');
      Axios.get(url.uploaderUrl.byFolders, {
        params: {
          sublimeUserId: userId,
          sublimeProjectId: projectId,
          sublimeDiskName: projectDisk,
          sublimeFolderName: formatedKey.replace('&', '-'),
          originalSize: project.original_size
        },
        headers: {
          Authorization: uploadToken
        }
      })
        .then(response => {
          this.keysObjectForCounter[formatedKey] = response.data.files
            ? response.data.files.length
            : 0;
          this.buildUploadsCounter();
          console.log(
            'Success',
            formatedKey,
            this.keysObjectForCounter[formatedKey]
          );
        })
        .catch(error => {
          console.log('Error when fetchin already uploaded files', error);
        });
    });
  };

  totalAlreadyUploaded = () => {
    const keys = Object.keys(this.keysObjectForCounter);
    const values = keys.map(key => this.keysObjectForCounter[key]);
    if (values.length >= 1) {
      return values.reduce((total, current) => (total += current));
    }
    return 0;
  };

  toggleGridOrSingle = async () => {
    const { isGrid } = this.state;
    const { store } = this.props;
    const { id } = store.getState().projects.project;
    if (this.totalAlreadyUploaded() >= 1) {
      Confirm(
        'Grid update',
        'If you change grid now, all your uploads for this project will be lost, are you sure?',
        () => {
          destroyAllUploadsConfigsForProject(id)
            .then(async response => {
              // Use timeout because in some case the backend return deleted uploads
              setTimeout(async () => {
                await this.setState({
                  isGrid: !isGrid,
                  alreadyUploadedFiles: 0
                });
                resetConfigs(store, { is_grid: !isGrid });
                this.keysObjectForCounter = {};
              }, 500);
              // this.countAlreadyUploaded();
            })
            .catch(error => {
              console.log(error);
            });
        }, // Confirmed
        () => {}, // Canceled
        undefined
      );
    } else {
      await this.setState({ isGrid: !isGrid });
      resetConfigs(store, { is_grid: !isGrid });
      // this.countAlreadyUploaded();
    }
  };

  canSubmit = () => {
    const { isGrid } = this.state;
    const { store } = this.props;
    const {
      horizontal_axis_count,
      vertical_axis_count
    } = store.getState().projects.project;
    let itCanSubmit = true;
    switch (isGrid) {
      case true: // SpinGrid is selected
        Object.keys(this.keysObjectForCounter).forEach(key => {
          if (
            this.keysObjectForCounter[key] !==
            horizontal_axis_count * vertical_axis_count
          ) {
            itCanSubmit = false;
          }
          if (horizontal_axis_count * vertical_axis_count < 1) {
            itCanSubmit = false;
          }
        });
        return itCanSubmit;
      case false: // SingleImage is selected
        Object.keys(this.keysObjectForCounter).forEach(key => {
          if (this.keysObjectForCounter[key] !== 1) {
            itCanSubmit = false;
          }
        });
        return itCanSubmit;
      default:
        return false;
    }
  };

  buildUploadsCounter = () => {
    const { isGrid } = this.state;
    const { store } = this.props;
    const {
      horizontal_axis_count,
      vertical_axis_count,
      configurations
    } = store.getState().projects.project;
    const totalByConf = isGrid
      ? horizontal_axis_count * vertical_axis_count
      : 1;
    const needUploadsFiles = totalByConf * configurations.length;
    const alreadyUploadedFiles =
      Object.keys(this.keysObjectForCounter).length >= 1
        ? Object.keys(this.keysObjectForCounter)
            .map(key => this.keysObjectForCounter[key])
            .reduce((accumulator, currentValue) => accumulator + currentValue)
        : 0;
    this.setState({ alreadyUploadedFiles, needUploadsFiles });
  };

  uploadCounterCallback = result => {
    this.keysObjectForCounter[result.folderName] = result.uploadsCount;
    this.buildUploadsCounter();
  };

  submitForm = () => {
    const submit = async () => {
      const { store, history } = this.props;
      const { menu } = store.getState().projects.project;
      const flat_menu = await formatedTree(store, menu);
      store.dispatch(
        updateConfigField(store, {
          flat_menu: JSON.stringify(flat_menu),
          final_put: true
        })
      );
      setTimeout(() => {
        history.push('/');
        store.dispatch(projectIsCookingAction);
        store.dispatch(clearProject());
      }, 300);
    };
    Confirm(
      'Finish project',
      'Are you sure you have finished your project?',
      () => {
        submit();
      }, // Confirmed
      () => {}, // Canceled
      undefined
    );
  };

  toggleConfig = (fields = undefined, resetDefaultPos = false) => {
    this.setState(prevState => ({ configSaved: !prevState.configSaved }));
    if (fields) {
      const { store } = this.props;
      if (resetDefaultPos) {
        resetConfigs(store, {
          horizontal_axis_count: fields.horizontalAxisCount,
          vertical_axis_count: fields.verticalAxisCount,
          horizontal_loop: fields.horizontalLoop,
          vertical_loop: fields.verticalLoop
        });
      } else {
        store.dispatch(
          updateConfigField(store, {
            horizontal_axis_count: fields.horizontalAxisCount,
            vertical_axis_count: fields.verticalAxisCount,
            horizontal_loop: fields.horizontalLoop,
            vertical_loop: fields.verticalLoop
          })
        );
      }
    }
  };

  render() {
    const {
      isGrid,
      configurations,
      alreadyUploadedFiles,
      needUploadsFiles,
      configSaved
    } = this.state;
    const { prevStep, store } = this.props;
    // const { projects } = store.getState();
    // const { project } = projects;

    const canSubmit = this.canSubmit();
    return (
      <Container id='sublime-configs-uploader'>
        <Row>
          <Col xs={12} md={12} className='marged-top'>
            <FormGroup tag='fieldset' className='flex-column flex-between'>
              <FormGroup check className='flex-row'>
                <Label check className='flex-row flex-center-items pointed'>
                  <CustomInput
                    className='pointed'
                    id='singleImage'
                    checked={!isGrid}
                    type='radio'
                    name='grid'
                    onChange={this.toggleGridOrSingle}
                  />
                  Single image fixed
                </Label>
              </FormGroup>
              <FormGroup check className='flex-row'>
                <Label check className='flex-row flex-center-items pointed'>
                  <CustomInput
                    className='pointed'
                    id='spinImage'
                    checked={isGrid}
                    type='radio'
                    name='grid'
                    onChange={this.toggleGridOrSingle}
                  />
                  For images spin, grid size 360°/180° interactive
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} className='marged-top'>
            {isGrid ? (
              <GridUpload
                uploadCounterCallback={upload =>
                  this.uploadCounterCallback(upload)
                }
                store={store}
                configurations={configurations}
                alreadyUploaded={this.keysObjectForCounter}
                configSaved={configSaved}
                configSaveCallback={this.toggleConfig}
              />
            ) : (
              <SingleUpload
                uploadCounterCallback={upload =>
                  this.uploadCounterCallback(upload)
                }
                store={store}
                configurations={configurations}
                alreadyUploaded={this.keysObjectForCounter}
              />
            )}
          </Col>
        </Row>
        <Row className='marged-bottom'>
          <Col xs={12}>
            <div
              className={`marged-top flex-row ${
                isOnMobile() ? 'flex-between' : 'flex-end'
              }`}
            >
              <div className='marged-right'>
                <Button onClick={prevStep}>Prev step</Button>
              </div>
              {!configSaved && isGrid && (
                <div className='marged-right'>
                  <Button
                    color='success'
                    onClick={() => {
                      const saveButton = document.getElementById(
                        'saveConfigButton'
                      );
                      if (saveButton) {
                        saveButton.click();
                        setTimeout(() => {
                          this.buildUploadsCounter();
                        }, 200);
                      }
                    }}
                  >
                    Next step
                  </Button>
                </div>
              )}
              {(!isGrid || configSaved) && (
                <div className='marged-right'>
                  <Button
                    style={{ width: '110%' }}
                    className='flex flex-arround flex-center-items'
                    outline
                    disabled={!canSubmit}
                    color='success'
                    onClick={this.submitForm}
                  >
                    <Badge color='secondary' className='flex-row flex-center'>
                      {`${alreadyUploadedFiles}/${needUploadsFiles}`}
                    </Badge>
                    Finish Project
                  </Button>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(SublimeConfStep4);
