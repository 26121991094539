/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import combinations from 'combinations';
import {
  walkInTree, findMenuById, generateItem, updateItem
} from './menu';

export async function clearAllForbidden(store, treeDatas) {
  async function clearForbids(item) {
    item.node.data.forbids = [];
    item.node.data.isMandatory = false;

    // A wait to keep already forbidden if is possible
    // const results = await menusCanBeProhibited(store, treeDatas, item);
    // item.node.data.forbids = item.node.data.forbids.filter(forbiddenId => (
    //   results.includes(forbiddenId)
    // ));
  }

  walkInTree(treeDatas, clearForbids);
}

export async function flatTree(treeDatas) {
  const tree = [];
  function addToTree(node) {
    tree.push(node);
  }
  await walkInTree(treeDatas, addToTree);
  return tree;
} // return an flat array of treeDatas

export async function allPossibleMenuCombinaison(store, treeData) {
  const { top_level_rule } = store.getState().projects.project;
  const flatTreeData = await formatedTree(store, treeData);
  let allCombinaisons = combinations(flatTreeData.filter(i => i.isLeaf));
  const parents = flatTreeData.filter(i => i.node.children.length >= 1);
  parents.forEach(parent => {
    if (parent.childrenRule === 'OR') {
      allCombinaisons = filterUniqueChildrenCombinaison(
        allCombinaisons,
        parent,
        flatTreeData
      );
    }
  });
  if (top_level_rule === 'OR') {
    allCombinaisons = filterTopLevelCombinaison(allCombinaisons);
  }
  flatTreeData.forEach((menuItem, idx, originalArray) => {
    let { forbids } = menuItem.node.data;
    const { isMandatory } = menuItem.node.data;
    forbids = forbids.map(id => originalArray.find(m => m.node.id === id).nomenclature);
    allCombinaisons = removeForbiddens(allCombinaisons, menuItem, forbids);
    if (isMandatory) {
      allCombinaisons = removeIfNotContainsMandatory(allCombinaisons, menuItem);
    }
  });
  return allCombinaisons;
} // return all possible combinaison after filtered

function filterUniqueChildrenCombinaison(allCombinaisons, parent, flatTreeData) {
  // we get the nomenclatures of the parent's direct children
  const directChildrenNomenclatures = parent.node.children.map(child => flatTreeData.find(i => i.node.id === child.id).nomenclature);
  return allCombinaisons.filter(combinaison => {
    // we recover in this combination the direct children of this parent
    const descendantsInCombinaison = combinaison.filter(e => directChildrenNomenclatures.some(nom => e.nomenclature.startsWith(nom)));
    // the parent's nomenclature is removed from the name of each child
    // then we only keep the first level of descent
    const regex = new RegExp(`^${parent.nomenclature}_(\\d*)(_?.*|$)`);
    const childrenIndexes = descendantsInCombinaison.map(
      comb => comb.nomenclature.match(regex)[1]
    );
    // Finally we keep the combination if there is only one child index (1 branch)
    return [...new Set(childrenIndexes)].length <= 1;
  });
} // Nomenclature filter

function removeForbiddens(combinaisons, menuItem, forbiddenItems) {
  forbiddenItems.forEach(forbiddenNomenclature => {
    combinaisons = combinaisons.filter(combinaison => !(combinaison.map(e => e.nomenclature).includes(menuItem.nomenclature) && combinaison.some(e => e.nomenclature.startsWith(forbiddenNomenclature))));
  });
  return combinaisons;
} // Forbiddens filter

function removeIfNotContainsMandatory(combinaisons, mandatoryItem) {
  const mandatoryNomenclature = mandatoryItem.nomenclature;
  // The combination is kept if it contains the mandatory element or at least one item from the mandatory branch (startsWith)
  return combinaisons.filter(combinaison => combinaison.some(menu => menu.nomenclature.startsWith(mandatoryNomenclature)));
} // Mandatory filter

function filterTopLevelCombinaison(allCombinaisons) {
  return allCombinaisons.filter(combinaison => {
    const splitedNoms = combinaison
      .map(c => c.nomenclature)
      .map(nom => nom.split('_')[0]);
    return [...new Set(splitedNoms)].length <= 1;
  });
}

function getMenuIndex(treeDatas, item) {
  return item.parentNode
    ? item.parentNode.children.map(i => i.id).indexOf(item.node.id) + 1
    : treeDatas.map(i => i.id).indexOf(item.node.id) + 1;
} // return the index of element in children array of parent

export async function formatedTree(store, treeDatas) {
  const { top_level_rule } = store.getState().projects.project;
  const flatTreeData = await flatTree(treeDatas);
  return flatTreeData
    .map(item => ({
      node: item.node,
      parentNode: item.parentNode,
      path: item.path,
      level: item.parentNode ? item.path.length - 1 : 0,
      index: getMenuIndex(treeDatas, item),
      parentRule: item.parentNode
        ? findMenuById(treeDatas, item.parentNode.id).node.data.childrenRule
        : top_level_rule,
      childrenRule:
        item.node.children && item.node.children.length >= 1
          ? item.node.children[0].data.parentRule
          : item.parentNode
            ? item.parentNode.data.childrenRule
            : top_level_rule,
      isLeaf: !(item.node.children && item.node.children.length >= 1)
    }))
    .map((item, index, originalArray) => ({
      ...item,
      nomenclature: item.path
        .map(i => originalArray.find(m => m.node.id === i).index)
        .join('_')
    }));
} // return formated flat tree with level, parentRule, childrenRule, isLeaf...

function formatedItem(items, item) {
  return items.find(i => i.node.id === item.node.id);
} // return the formated given item (formatedTree)

export async function menusCanBeProhibited(store, treeDatas, currentItem) {
  const items = await formatedTree(store, treeDatas);
  currentItem = formatedItem(items, currentItem);
  const { top_level_rule } = store.getState().projects.project;
  const results = [];
  items.forEach(item => {
    if (currentItem.parentNode) {
      // currentItem is level 1 or >
      if (currentItem.parentRule === 'AND') {
        if (!item.path.every(id => !currentItem.path.includes(id))) {
          // currentItem is a brother or nephew of item
          if (item.parentNode) {
            if (item.parentNode.id !== currentItem.node.id) {
              results.push(item);
            }
          } else {
            results.push(item);
          }
        }
      }
      const parentItem = items.find(
        i => i.node.id === currentItem.parentNode.id
      );
      if (parentItem.node.data.parentRule === 'AND') {
        if (!item.path.every(id => !currentItem.path.includes(id))) {
          // currentItem is a brother or nephewof item
          if (item.parentNode && item.parentNode.id !== parentItem.node.id) {
            results.push(item);
          }
        }
      }
    }
    // Need confirmation to do this
    if (top_level_rule === 'AND') {
      results.push(item);
    }
  });
  return results.filter(
    (v, i, self) => self.indexOf(v) === i // remove duplicate
      && v.node.id !== currentItem.node.id // remove current item
      && !currentItem.path.includes(v.node.id) // remove if currentItem is in item tree
      && !v.path.includes(currentItem.node.id) // remove if currentItem is in item tree
  );
}

export function toggleForbiddenMenu(currentItem, clickedItem, context) { // clickedItem is the selected item of possible forbidden list
  // currentItem Item we clicked on the forbidden button
  const currentForbids = currentItem.node.data.forbids; // Already forbidden for currentItem
  const possibleProhibited = clickedItem.node.data.forbids; // Already forbidden for clickedItem
  if (clickedItem.node.children) {
    clickedItem.node.children.forEach(child => {
      const childItem = findMenuById(context.state.treeData, child.id);
      toggleForbiddenMenu(currentItem, childItem, context);
    });
  } // recurcive function for toggle child if a parent is selected

  if (currentForbids.includes(clickedItem.node.id)) {
    // menu is already forbidden
    const index = currentForbids.indexOf(clickedItem.node.id);
    currentForbids.splice(index, 1); // remove forbidden from currentItem

    const index2 = possibleProhibited.indexOf(currentItem.node.id);
    possibleProhibited.splice(index2, 1); // remove forbidden for clickedItem
  } else {
    // menu is not forbidden
    currentForbids.push(clickedItem.node.id); // add menu to forbidden of currentItem
    possibleProhibited.push(currentItem.node.id); // add menu to forbidden of clickedItem
  }
  // Generate new values, updates and refresh
  const newValue = generateItem({
    ...currentItem.node.data,
    forbids: [...currentForbids]
  });
  const newValue2 = generateItem({
    ...clickedItem.node.data,
    forbids: [...possibleProhibited]
  });
  updateItem(currentItem, newValue, context);
  updateItem(clickedItem, newValue2, context);

  context.fetchMenus();
}
