import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import {
  SublimeInfo,
  SublimeSwitch,
  SwitchInput,
  getImage
} from 'exalt3d-sublime-ui';
import { updateItem, generateItem } from '../../../../utils/configurator/menu';
import Thumbnail from './Thumbnail';
import Attachment from './Attachment';

export default class MenuElementModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: []
    };
  }

  componentDidMount = () => {
    document.getElementById('menuTitle').focus();
  };

  handleItemChange = event => {
    event.persist();
    this.clearErrors();
    const { context } = this.props;
    context.setState(prevState => ({
      ...prevState,
      currentItem: {
        ...prevState.currentItem,
        [event.target.name]: event.target.value
      }
    }));
  };

  handleItemCheckChange = event => {
    const { context } = this.props;
    context.setState(prevState => ({
      ...prevState,
      currentItem: {
        ...prevState.currentItem,
        [event.target.name]: event.target.checked
      }
    }));
  };

  submitForm = async () => {
    const { item, dragableItem, context } = this.props;
    const beforeUpdateItem = dragableItem;
    const newValue = generateItem(item);
    const formError = this.validForm();
    if (formError.length < 1) {
      // keep path and other  params given by react-tree and merge it with new field data
      await updateItem(beforeUpdateItem, newValue, context, true);
      context.resetSelected();
    } else {
      document.getElementById('menu-element-modal').scrollTo(0, 0);
    }
  };

  clearErrors = () => {
    this.setState({ errors: [] });
  };

  validForm = () => {
    const { item } = this.props;
    const errors = [];

    ['title'].forEach(field => {
      if (item[field].length < 1) {
        errors.push(field);
      }
    });
    this.setState({ errors: [...errors] });
    return errors;
  };

  render() {
    const { errors } = this.state;
    const { item, context } = this.props;
    const { store } = context.props;
    const {
      is_single_price,
      display_price,
      single_price,
      is_admin_price,
      price_increase,
      admin_price,
      currency
    } = store.getState().projects.project;
    const amount = price_increase
      ? parseInt(admin_price) + parseInt(item.price.optionAmount)
      : item.price.optionAmount;
    const adminPriceLabel = `Price option - (total : ${amount} ${currency})`;
    return (
      <Container>
        <form
          onSubmit={e => {
            e.preventDefault();
            this.submitForm();
          }}
        >
          <Row className='marged-bottom'>
            <Col xs={12} md={12}>
              <FormGroup>
                <Label for='menuTitle'>
                  {'Menu title '}
                  <span className='sublime-mandatory'>*</span>
                  <SublimeInfo
                    target='menuTitle'
                    imgSrc={getImage('menu-title')}
                  />
                </Label>
                <Input
                  className={errors.includes('title') ? 'is-invalid' : ''}
                  onChange={this.handleItemChange}
                  value={item.title}
                  type='text'
                  name='title'
                  id='menuTitle'
                  placeholder='Add a title for this menu'
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={12}>
              <FormGroup>
                <Label className='flex-row flex-center-items'>
                  <SublimeSwitch
                    onChange={this.handleItemCheckChange}
                    checked={item.displayTitle}
                    name='displayTitle'
                    id='displayTitle'
                  />
                  Display the menu title on the web page
                </Label>
              </FormGroup>
            </Col>
            <Col xs={12} md={12}>
              <FormGroup className='flex-row flex-between'>
                <div className='flex-row'>
                  <SublimeSwitch
                    id='isThumbnail'
                    checked={item.thumbnail.isThumbnail}
                    name='isColor'
                    onChange={e => {
                      const isChecked = e.target.checked;
                      context.setState(prevState => ({
                        ...prevState,
                        currentItem: {
                          ...prevState.currentItem,
                          thumbnail: {
                            ...prevState.currentItem.thumbnail,
                            isThumbnail: isChecked,
                            isColor: isChecked
                              ? !isChecked
                              : prevState.currentItem.thumbnail.isColor
                          }
                        }
                      }));
                    }}
                  />
                  <Label className='flex-row flex-center-items'>
                    Add a thumbnail
                    <SublimeInfo
                      target='isThumbnail'
                      imgSrc={getImage('thumbnail-image')}
                    />
                  </Label>
                </div>
                <div className='flex-row'>
                  <SublimeSwitch
                    id='isColor'
                    checked={item.thumbnail.isColor}
                    name='isColor'
                    onChange={e => {
                      const isChecked = e.target.checked;
                      context.setState(prevState => ({
                        ...prevState,
                        currentItem: {
                          ...prevState.currentItem,
                          thumbnail: {
                            ...prevState.currentItem.thumbnail,
                            isThumbnail: isChecked
                              ? !isChecked
                              : prevState.currentItem.thumbnail.isThumbnail,
                            isColor: isChecked
                          }
                        }
                      }));
                    }}
                  />
                  <Label className='flex-row flex-center-items'>
                    Add a coloured sticker
                    <SublimeInfo
                      target='isColor'
                      imgSrc={getImage('thumbnail-color')}
                    />
                  </Label>
                </div>
              </FormGroup>
              <Thumbnail item={item} context={context} />
            </Col>
          </Row>
          <SwitchInput
            infoName='ref'
            switchLabel='Add a product reference'
            inputLabel='Ref'
            inputId='reference'
            switchId='isReferenced'
            placeholder='ex: RF0001'
            checked={item.productReference.isReferenced}
            value={item.productReference.reference}
            onChange={reference => {
              context.setState({
                ...context.state,
                currentItem: {
                  ...context.state.currentItem,
                  productReference: {
                    ...context.state.currentItem.productReference,
                    reference
                  }
                }
              });
            }}
            onToggle={isReferenced => {
              context.setState({
                ...context.state,
                currentItem: {
                  ...context.state.currentItem,
                  productReference: {
                    ...context.state.currentItem.productReference,
                    isReferenced,
                    display: isReferenced
                  }
                }
              });
            }}
          >
            <Col xs={12} md={12}>
              <FormGroup>
                <Label className='flex-row flex-center-items'>
                  <SublimeSwitch
                    checked={item.productReference.display}
                    name='displayRef'
                    id='displayRef'
                    onChange={event => {
                      context.setState(prevState => ({
                        ...prevState,
                        currentItem: {
                          ...prevState.currentItem,
                          productReference: {
                            ...prevState.currentItem.productReference,
                            display: event.target.checked
                          }
                        }
                      }));
                    }}
                  />
                  Display the product reference
                </Label>
              </FormGroup>
            </Col>
          </SwitchInput>
          {display_price && (
            <FormGroup>
              <Label className='flex-row' for='singlePrice'>
                {is_single_price ? 'Price :' : 'Base price :'}
                <SublimeInfo target='singlePrice' imgSrc={getImage('price')} />
              </Label>
              <Input
                disabled
                value={is_single_price ? single_price : admin_price}
                type='number'
                name='title'
                id='singlePrice'
              />
            </FormGroup>
          )}
          {is_admin_price && display_price && (
            <FormGroup>
              <Label className='flex-row' for='adminPrice'>
                {adminPriceLabel}
                <SublimeInfo
                  target='adminPrice'
                  imgSrc={getImage('price-option')}
                />
              </Label>
              <Input
                placeholder='ex: 1,000'
                min={0}
                value={item.price.optionAmount}
                onChange={event => {
                  event.persist();
                  context.setState(prevState => ({
                    ...prevState,
                    currentItem: {
                      ...prevState.currentItem,
                      price: {
                        ...prevState.currentItem.price,
                        optionAmount: event.target.value
                      }
                    }
                  }));
                }}
                type='number'
                name='title'
                id='adminPrice'
              />
            </FormGroup>
          )}
          <Row>
            <Col xs={12} md={12}>
              <FormGroup>
                <Label className='flex-row' for='title'>
                  Short description
                  <SublimeInfo
                    target='subtitle'
                    imgSrc={getImage('short-description')}
                  />
                </Label>
                <Input
                  onChange={this.handleItemChange}
                  value={item.subtitle}
                  type='textarea'
                  name='subtitle'
                  id='subtitle'
                  placeholder='Add a short description for this menu'
                />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label className='flex-row' id='attachment'>
              Attachment
              <SublimeInfo
                target='attachment'
                imgSrc={getImage('download-file')}
              />
            </Label>
            <Attachment item={item} context={context} />
          </FormGroup>
          <Row>
            <Col xs={12} md={12}>
              <div className='flex-row flex-around'>
                <Button
                  color='danger'
                  onClick={e => {
                    e.preventDefault();
                    context.resetSelected();
                  }}
                >
                  Cancel
                </Button>
                <Button color='primary' type='submit'>
                  Valid
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </Container>
    );
  }
}
