import * as React from "react";
import { Toast, ToastHeader, ToastBody } from "reactstrap";
import { Line } from "react-chartjs-2";
import SublimeHeader from "../../../../shared/SublimeHeader";
import { IStatistic } from "../../../../../interfaces/statistics";
import { IOffer } from "../../../../../interfaces/offer";
//@ts-ignore
import { buildPlayerVisit } from "../../../../../utils/stats";

interface IPlayersVisitProps {
  stats: IStatistic;
  currentOffer: IOffer;
}

const PlayersVisit: React.FunctionComponent<IPlayersVisitProps> = ({
  stats
}) => {
  return (
    <Toast>
      <ToastHeader>
        <SublimeHeader title="Players visits" />
      </ToastHeader>
      <ToastBody>
        <div className="flex-column flex-center flex-center-items">
          <Line data={buildPlayerVisit(stats.projects)} />
        </div>
      </ToastBody>
    </Toast>
  );
};

export default PlayersVisit;
