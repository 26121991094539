// @ts-nocheck
function buildBaseUrl() {
  switch (process.env.NODE_ENV) {
    case "development":
      return "/api/v1";
    case "staging":
      return "/api/v1";
    case "production":
      return "/api/v1";
    default:
      return "/api/v1";
  }
}

function buildUploaderUrlBase() {
  let url = "";
  switch (process.env.NODE_ENV) {
    case "development":
      url = "http://127.0.0.1:5000";
      break;
    case "production":
      url = "https://upload.exalt3d.com";
      break;
    default:
      url = "https://upload.exalt3d.com";
      break;
  }
  return url;
}

function buildUploaderUrl() {
  return `${buildUploaderUrlBase()}/api/v1`;
}

export default {
  uploader: `${buildUploaderUrl()}/uploads`,
  uploaderSystemStats: `${buildUploaderUrl()}/stats/system`,
  files: `${buildUploaderUrl()}/files`,
  logs: {
    imagesProcess: {
      files: `${buildUploaderUrl()}/process`,
      logs: `${buildUploaderUrlBase()}/images_process_logs`,
      pending: `${buildUploaderUrlBase()}/images_process/pending`,
      success: `${buildUploaderUrlBase()}/images_process/success`,
      errors: `${buildUploaderUrlBase()}/images_process/errors`
    }
  },
  appStats: `${buildBaseUrl()}/services/statistics/system_usage`,
  auth: {
    signIn: `${buildBaseUrl()}/auth/sign_in`,
    signUp: `${buildBaseUrl()}/auth`,
    validateToken: `${buildBaseUrl()}/auth/validate_token`,
    changePassword: `${buildBaseUrl()}/users/change_password`,
    resetPasswordRequest: `${buildBaseUrl()}/users/request_reset`,
    resetPassword: `${buildBaseUrl()}/users/reset_password`
  },
  users: `${buildBaseUrl()}/users`,
  contacts: `${buildBaseUrl()}/contacts`,
  offers: `${buildBaseUrl()}/offers`,
  projects: `${buildBaseUrl()}/projects`,
  posts: `${buildBaseUrl()}/posts`,
  roles: `${buildBaseUrl()}/roles`,
  stats: `${buildBaseUrl()}/stats`,
  editors: `${buildBaseUrl()}/editors`
};
