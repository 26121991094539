// @ts-nocheck
import Axios from 'axios';
import baseUrl from '../../api/url';
import {
  FETCH_OFFERS,
  FETCH_OFFERS_ERROR,
  UPDATE_OFFER,
  UPDATE_OFFER_ERROR
} from '../types/offer';
import { successMessage, errorMessage } from './app';
import { buildTokenHeaders } from './auth';
import i18n from '../../i18n';

export function fetchOffers() {
  return async function (dispatch, getState) {
    function onSuccess(response) {
      dispatch({ type: FETCH_OFFERS, payload: [...response.data.offers] });
      return response.data.offers;
    }
    function onError(error) {
      dispatch({ type: FETCH_OFFERS_ERROR, payload: error });
      return error.response;
    }

    try {
      const response = await Axios.get(baseUrl.offers, {
        headers: {
          ...buildTokenHeaders()
        },
      });
      return onSuccess(response);
    } catch (error) {
      return onError(error);
    }
  };
}

export function updateOffer(fields, offerId) {
  return async function (dispatch, getState) {
    function onSuccess(response) {
      dispatch({ type: UPDATE_OFFER, payload: response.data.offer });
      dispatch(fetchOffers());
      dispatch(successMessage(i18n.store.data[i18n.language].admin.offers.update.success));
      return response.data.offer;
    }
    function onError(error) {
      dispatch({ type: UPDATE_OFFER_ERROR, payload: error });
      dispatch(errorMessage(i18n.store.data[i18n.language].admin.offers.update.error));
      return error.response;
    }

    try {
      const response = await Axios.put(`${baseUrl.offers}/${offerId}`, { ...fields }, {
        headers: {
          ...buildTokenHeaders()
        }
      });
      return onSuccess(response);
    } catch (error) {
      return onError(error);
    }
  };
}