import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// @ts-ignore
import en from './locales/en/en.json';
// @ts-ignore
import fr from './locales/fr/fr.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en,
      fr
    },
    lng: 'en',
    fallbackLng: 'en',
    debug: false,

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;

// Switch language (not in use)
// https://www.i18next.com/overview/api