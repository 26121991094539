import SublimeTheme from '../ui-kit/theme';
import { buildStyles } from 'react-circular-progressbar';
import { formatFileSize } from './bytes';
export const formatFileTypes = usedFilesType => {
  const keys = Object.keys(usedFilesType);
  return {
    labels: keys,
    datasets: [
      {
        data: keys.map(key => usedFilesType[key]),
        backgroundColor: [
          SublimeTheme.sublime.color.mainGreen,
          SublimeTheme.sublime.color.mainGold,
          SublimeTheme.sublime.color.mainSilver,
          SublimeTheme.sublime.color.mainBlack
        ],
        hoverBackgroundColor: [
          SublimeTheme.sublime.color.mainGreen,
          SublimeTheme.sublime.color.mainGold,
          SublimeTheme.sublime.color.mainSilver,
          SublimeTheme.sublime.color.mainBlack
        ]
      }
    ]
  };
};

export const buildProjectsUploadsStats = projects => {
  return {
    labels: Object.keys(projects).map(key => projects[key].name), // here is project ids
    datasets: [
      {
        label: 'Total space Mb',
        backgroundColor: SublimeTheme.sublime.color.mainGreen,
        borderColor: SublimeTheme.sublime.color.mainSilver,
        borderWidth: 1,
        hoverBackgroundColor: SublimeTheme.sublime.color.mainBlack,
        hoverBorderColor: SublimeTheme.sublime.color.mainSilver,
        data: Object.keys(projects).map(key =>
          formatFileSize(projects[key].used_disk_space, 3, false)
        )
      },
      {
        label: 'Total Files',
        backgroundColor: SublimeTheme.sublime.color.mainGold,
        borderColor: SublimeTheme.sublime.color.mainSilver,
        borderWidth: 1,
        hoverBackgroundColor: SublimeTheme.sublime.color.mainBlack,
        hoverBorderColor: SublimeTheme.sublime.color.mainSilver,
        data: Object.keys(projects).map(key => projects[key].files)
      }
    ]
  };
};

export const buildPlayerVisit = projects => {
  return {
    labels: Object.keys(projects).map(key => projects[key].name), // here is project ids
    datasets: [
      {
        label: 'Total of sessions',
        backgroundColor: SublimeTheme.sublime.color.mainGreen,
        borderColor: SublimeTheme.sublime.color.mainSilver,
        borderWidth: 1,
        hoverBackgroundColor: SublimeTheme.sublime.color.mainBlack,
        hoverBorderColor: SublimeTheme.sublime.color.mainSilver,
        data: Object.keys(projects).map(
          key => projects[key].player_usage.total_sessions
        )
      }
    ]
  };
};

export const buildPlayerRemainingTime = projects => {
  return {
    labels: Object.keys(projects).map(key => projects[key].name), // here is project ids
    datasets: [
      {
        label: 'Total of remaining time (in minutes)',
        backgroundColor: SublimeTheme.sublime.color.mainGreen,
        borderColor: SublimeTheme.sublime.color.mainSilver,
        borderWidth: 1,
        hoverBackgroundColor: SublimeTheme.sublime.color.mainBlack,
        hoverBorderColor: SublimeTheme.sublime.color.mainSilver,
        data: Object.keys(projects).map(
          key => projects[key].player_usage.total_time
        )
      }
    ]
  };
};

export const buildPlayerSessionsRemainingTime = sessions => {
  return {
    labels: sessions.map(
      session =>
        `xxx.xxx.${session.ip.slice(
          session.ip.length - 5,
          session.ip.length
        )} - ${session.domain}`
    ),
    datasets: [
      {
        label: 'Total time by session (in minutes)',
        backgroundColor: SublimeTheme.sublime.color.mainGreen,
        borderColor: SublimeTheme.sublime.color.mainSilver,
        borderWidth: 1,
        hoverBackgroundColor: SublimeTheme.sublime.color.mainBlack,
        hoverBorderColor: SublimeTheme.sublime.color.mainSilver,
        data: sessions.map(session => session.remaining_time)
      }
    ]
  };
};

export const buildPlayerSessionsByDomain = domains => {
  return {
    labels: Object.keys(domains),
    datasets: [
      {
        label: 'Total time by domain (in minutes)',
        backgroundColor: SublimeTheme.sublime.color.mainGreen,
        borderColor: SublimeTheme.sublime.color.mainSilver,
        borderWidth: 1,
        hoverBackgroundColor: SublimeTheme.sublime.color.mainBlack,
        hoverBorderColor: SublimeTheme.sublime.color.mainSilver,
        data: Object.keys(domains).map(key => domains[key].total_remaining_time)
      }
    ]
  };
};

export function buildCirclePercentStyle(percentage) {
  return buildStyles({
    rotation: 0.25,
    strokeLinecap: 'butt',
    textSize: '16px',
    pathTransitionDuration: 0.5,
    pathColor: SublimeTheme.sublime.color.mainGold,
    textColor: SublimeTheme.sublime.color.mainGreen,
    trailColor: SublimeTheme.sublime.color.mainBlack
  });
}
