import React from 'react';
import SublimeLogo from '../logos/Sublime';
export default function index() {
  return (
    <div className="full-height-screen flex-column flex-center flex-center-items">
      <SublimeLogo />
      <h2 className="marged-top">Coming soon ...</h2>
    </div>
  );
}
