// @ts-nocheck
import React from 'react';
import store from '../../../../store';
import SublimeConfigurator from '../../../SublimeConfigurator';

export default function New() {
  return (
    <div style={{ minHeight: 'calc(100vh - 80px)', marginRight: '50px' }}>
      <SublimeConfigurator
        autoRedirect={false}
        store={store}
        tokens={store.getState().auth}
        uploaderToken={store.getState().user.uploader_token}
      />
    </div>
  );
}
