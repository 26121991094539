import React, { useState } from "react";
import { Collapse } from "reactstrap";

interface ISeparatorProps {
  title: string;
  children: any;
  fix: boolean;
  isOpen?: boolean;
}

const Separator: React.FunctionComponent<ISeparatorProps> = ({
  title,
  children,
  fix = false,
  isOpen
}) => {
  const [isCollapseOpen, setisCollapseOpen] = useState(isOpen ? isOpen : false);
  const icon = isCollapseOpen ? "&#8854;" : "&#8853;";
  const styles = {
    container: {
      marginTop: "20px"
    },
    titleContainer: {
      marginBottom: "20px",
      cursor: fix ? "initial" : "pointer"
    },
    icon: {
      fontSize: "25px",
      marginLeft: "10px"
    },
    content: {
      paddingLeft: "20px"
    }
  };
  return (
    <div style={styles.container}>
      <span
        onClick={() => setisCollapseOpen(!isCollapseOpen)}
        style={styles.titleContainer}
        role="button"
        className="flex-row flex-center-items"
      >
        <h2>{title}</h2>
        {!fix && (
          <span
            style={styles.icon}
            dangerouslySetInnerHTML={{ __html: icon }}
          />
        )}
      </span>
      <Collapse isOpen={fix ? true : isCollapseOpen} style={styles.content}>
        {children}
      </Collapse>
    </div>
  );
};

export default Separator;
