import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import { withTranslation } from "react-i18next";
import Offer from "./Offer";
import { fetchOffers } from "../../../../../store/actions/offers";
import SublimeHeader from "../../../../shared/SublimeHeader";

export class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOffer: "free",
      color: "#73B0A0"
    };
  }

  changeSelction = (name, color) => {
    this.setState({ selectedOffer: name, color });
  };

  selectOffer = () => {
    // Free offer is attribued by default on each user creation
    this.props.history.push("/dashboard/home");
  };

  componentDidMount() {
    const { fetchOffers } = this.props;
    fetchOffers();
  }

  render() {
    const { t, user, offers } = this.props;
    const { first_connexion } = user;
    if (!first_connexion) {
      return <Redirect to="/dashboard/home" />;
    }
    return (
      <Container className="layout-margin">
        <SublimeHeader title={t("title")} />
        <Row>
          {offers.map(offer => {
            const buttonLabel = offer.is_active
              ? t("package.offers.all.valid", { offerName: offer.name })
              : t("package.offers.all.soon");
            return (
              <Col
                className="marged-top"
                key={offer.id}
                id={`col-offer${offer.name}`}
                offset
                lg={3}
                md={6}
                sm={6}
                xs={12}
              >
                <Container className="offer-container flex-column flex-center-items">
                  <Row className="marged-bottom">
                    <Button
                      onClick={this.selectOffer}
                      disabled={!offer.is_active}
                      style={{ backgroundColor: offer.color, border: "none" }}
                    >
                      {buttonLabel}
                    </Button>
                  </Row>
                  <Row>
                    <Offer
                      onSelect={this.changeSelction}
                      selected={this.state.selectedOffer === offer.name}
                      {...offer}
                      idx={`offer-${offer.id}`}
                    />
                  </Row>
                </Container>
              </Col>
            );
          })}
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  offers: state.offers
});

const mapDispatchToProps = {
  fetchOffers
};

// @ts-ignore
export default withTranslation("subscriptions")(
  connect(mapStateToProps, mapDispatchToProps)(index)
);
