import React from 'react';
import { Container, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';

function NoContent({ t, history }) {
  const createNewProject = e => {
    e.preventDefault();
    history.push('/dashboard/projects/new');
  };
  return (
    <Container className="full-height flex-column flex-center flex-center-items">
      <h2>{t('noContent')}</h2>
      <Button color="primary" onClick={createNewProject}>
        <FaPlus className="marged-right" />
        {t('newProject')}
      </Button>
    </Container>
  );
}

export default withTranslation('library')(withRouter(NoContent));
