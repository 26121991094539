import Axios from 'axios';
import store from '../../../../../store';
import url from '../../../../../api/url';

export async function fetchProjectsFolders() {
  const response = await Axios.get(`${url.files}`, {
    headers: {
      Authorization: store.getState().user.uploader_token
    }
  });
  return response;
}

export async function fetchProjectFolders(project, folderName) {
  const response = await Axios.get(`${url.files}/${project.id}`, {
    params: {
      folderName: folderName.replace('&', '-'), // replace otherwise nomenclature is considered has params
      originalSize: project.original_size
    },
    headers: {
      Authorization: store.getState().user.uploader_token
    }
  });
  return response;
}