import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { formatFileSize } from '../../../../../utils/bytes';
import SublimeSpnr from '../../../../../ui-kit/Spinners/SublimeSpinner/index';
import {
  formatFileTypes,
  buildCirclePercentStyle,
  buildProjectsUploadsStats
} from '../../../../../utils/stats';
import { fetchStat } from '../../../../../store/actions/stats';
import { Container, Row, Col, Toast, ToastHeader, ToastBody } from 'reactstrap';
import { CircularProgressbar } from 'react-circular-progressbar';
import { Pie, Bar } from 'react-chartjs-2';
import 'react-circular-progressbar/dist/styles.css';
import SublimeHeader from '../../../../shared/SublimeHeader';
import PlayersVisit from './PlayersVisit';
import PlayersRemainingTime from './PlayersRemainingTime';
import { isOnMobile } from '../../../../../utils/screen.ts';
export class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      found: false
    };
  }

  async componentDidMount() {
    const { fetchStat, userId } = this.props; // userId is undefined if component was render by react-router
    const response = await fetchStat(userId);
    switch (response.status) {
      case 200:
        this.setState({ loading: false, found: true });
        break;
      case 404:
        this.setState({ loading: false, found: false });
        break;
      default:
        this.setState({ loading: false, found: false });
        break;
    }
  }

  render() {
    const { loading, found } = this.state;
    const { stats, t, inModal = false, currentOffer } = this.props;
    const {
      projects,
      projects_total,
      projects_with_uploads,
      total_disk_space,
      uploads_count,
      used_files_type
    } = stats;

    const percentUsage = Math.round(
      (total_disk_space * 100) / currentOffer.max_storage_size
    );
    if (loading) {
      return <SublimeSpnr />;
    }
    if (!found) {
      return (
        <div className='full-height flex-row flex-center flex-center-items'>
          <p>Statistic for this user was not found !</p>
        </div>
      );
    }
    return (
      <Container
        className={
          inModal || isOnMobile()
            ? 'stats-container'
            : 'stats-container layout-margin'
        }
      >
        {!inModal && <SublimeHeader title={t('title')} />}
        <div>
          <Row>
            <Col xs={12} md={6}>
              <Toast>
                <ToastHeader>
                  <SublimeHeader title='Disk Usage' />
                </ToastHeader>
                <ToastBody>
                  <div className='flex-column flex-center flex-center-items'>
                    <CircularProgressbar
                      styles={{
                        ...buildCirclePercentStyle(percentUsage),
                        root: !inModal
                          ? {
                              width: '200px'
                            }
                          : null
                      }}
                      value={percentUsage}
                      text={`${percentUsage}%`}
                      strokeWidth={1}
                    />
                    <p className='marged-top text-center'>
                      {formatFileSize(total_disk_space, 3)} /{' '}
                      {formatFileSize(currentOffer.max_storage_size, 3)}
                    </p>
                  </div>
                </ToastBody>
              </Toast>
            </Col>
            <Col xs={12} md={6}>
              <Toast>
                <ToastHeader>
                  <SublimeHeader title='Files Type Usage' />
                </ToastHeader>
                <ToastBody>
                  <div className='flex-column flex-center flex-center-items'>
                    <Pie
                      height={!inModal ? 134 : 285}
                      data={formatFileTypes(used_files_type)}
                    />
                  </div>
                </ToastBody>
              </Toast>
            </Col>
          </Row>
          <Row className={'marged-top'}>
            <Col xs={12} md={12}>
              <Toast>
                <ToastHeader>
                  <SublimeHeader title='Counters' />
                </ToastHeader>
                <ToastBody>
                  <div className='full-width flex-row flex-around'>
                    <div className='flex-column flex-center-items text-center'>
                      <h4 className='sublime-green'>Total of Projects</h4>
                      <h3 className='sublime-gold'>{projects_total}</h3>
                    </div>
                    <div className='flex-column flex-center-items text-center'>
                      <h4 className='sublime-green'>With Uploads</h4>
                      <h3 className='sublime-gold'>{projects_with_uploads}</h3>
                    </div>
                    <div className='flex-column flex-center-items text-center'>
                      <h4 className='sublime-green'>Total of Uploads</h4>
                      <h3 className='sublime-gold'>{uploads_count}</h3>
                    </div>
                  </div>
                </ToastBody>
              </Toast>
            </Col>
          </Row>
          <Row className={'marged-top'}>
            <Col xs={12} md={12}>
              <Toast>
                <ToastHeader>
                  <SublimeHeader title='Combined' />
                </ToastHeader>
                <ToastBody>
                  <Bar
                    data={buildProjectsUploadsStats(projects)}
                    height={300}
                    options={{
                      maintainAspectRatio: false
                    }}
                  />
                </ToastBody>
              </Toast>
            </Col>
          </Row>
          <Row className={'marged-top'}>
            <Col xs={12} md={12}>
              <PlayersVisit currentOffer={currentOffer} stats={stats} />
            </Col>
          </Row>
          <Row className={'marged-top'}>
            <Col xs={12} md={12}>
              <PlayersRemainingTime currentOffer={currentOffer} stats={stats} />
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  stats: state.stats,
  currentOffer: state.user.offer
});

const mapDispatchToProps = {
  fetchStat
};

// @ts-ignore
export default withTranslation(['stats'])(
  connect(mapStateToProps, mapDispatchToProps)(index)
);
