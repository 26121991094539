import {
  FETCH_PROJECTS,
  FETCH_PROJECTS_ERROR
} from '../types/sublime-projects';

const initialState = [];

export default (state = initialState, { type, payload }) => {
  switch (type) {

    case FETCH_PROJECTS:
      return [...payload];

    case FETCH_PROJECTS_ERROR:
      return state;

    default:
      return state;
  }
};
