import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Table,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap';
import {
  SublimeTheme,
  SublimeSwitch,
  getImage,
  SublimeInfo
} from 'exalt3d-sublime-ui';
import ConfigUploader from '../../uploader/ConfigUploader';
import { getTitleTree } from '../../../../utils/configurator/menu';
import { updateConfigField } from '../../../../store/actions/project';
import DefaultImageSelector from './DefaultImageSelector';

const successColor = SublimeTheme.sublime.color.mainGreen;
const warningColor = SublimeTheme.sublime.color.mainGold;

export default class GridUpload extends Component {
  constructor(props) {
    super(props);
    const {
      horizontal_axis_count,
      vertical_axis_count,
      horizontal_loop,
      vertical_loop,
      default_configuration
    } = props.store.getState().projects.project;
    this.state = {
      defaultConf: default_configuration,
      horizontalAxisCount: horizontal_axis_count,
      verticalAxisCount: vertical_axis_count,
      horizontalLoop: horizontal_loop,
      verticalLoop: vertical_loop
    };
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleCheckChange = event => {
    this.setState({ [event.target.name]: !event.target.checked });
  };

  saveGridConfig = () => {
    const { configSaveCallback, store } = this.props;
    const { horizontalAxisCount, verticalAxisCount } = this.state;
    const {
      horizontal_axis_count,
      vertical_axis_count
    } = store.getState().projects.project;
    const originalGridCount = horizontal_axis_count * vertical_axis_count;
    const newGridCount = horizontalAxisCount * verticalAxisCount;
    // reset default images if grid change
    configSaveCallback(this.state, originalGridCount !== newGridCount);
  };

  editGridConfig = () => {
    const { configSaveCallback } = this.props;
    configSaveCallback();
  };

  render() {
    const {
      defaultConf,
      horizontalAxisCount,
      verticalAxisCount,
      horizontalLoop,
      verticalLoop
    } = this.state;
    const {
      store,
      configurations,
      uploadCounterCallback,
      alreadyUploaded,
      configSaved
    } = this.props;
    const {
      menu,
      original_size,
      horizontal_axis_count,
      vertical_axis_count,
      root_url
    } = store.getState().projects.project;
    const gridCount = horizontal_axis_count * vertical_axis_count;

    return (
      <Container>
        <Row>
          <Col
            xs={12}
            md={12}
            className={
              !configSaved
                ? 'sublime-switch-content-active'
                : 'sublime-switch-content'
            }
          >
            <div>
              <h5>
                For an image spin, all the image sets should be numbered in the
                same way (from 01 to 0X)
              </h5>
              <Row>
                <Col className='marged-top'>
                  <form>
                    <FormGroup
                      style={{ alignItems: 'baseline' }}
                      className='flex-row'
                    >
                      <Label
                        className='flex-row marged-right'
                        for='horizontalAxisCount'
                      >
                        Number of images on the Horizontal axis :
                        <SublimeInfo
                          target='horizontalAxisCount'
                          imgSrc={getImage('number-of-image-horizontal-full')}
                        />
                      </Label>
                      <Input
                        onChange={this.handleChange}
                        value={horizontalAxisCount}
                        pattern='\d+'
                        onInput={e => {
                          if (!e.target.validity.valid) {
                            e.target.value = 1;
                          }
                        }}
                        min={1}
                        max={9999}
                        type='number'
                        name='horizontalAxisCount'
                        id='horizontalAxisCount'
                      />
                    </FormGroup>
                    <FormGroup
                      style={{ alignItems: 'baseline' }}
                      className='flex-row'
                    >
                      <Label
                        className='flex-row marged-right'
                        for='verticalAxisCount'
                      >
                        Number of images on the Vertical axis :
                        <SublimeInfo
                          target='verticalAxisCount'
                          imgSrc={getImage('number-of-image-vertical-full')}
                        />
                      </Label>
                      <Input
                        onChange={this.handleChange}
                        value={verticalAxisCount}
                        pattern='\d+'
                        onInput={e => {
                          if (!e.target.validity.valid) {
                            e.target.value = 1;
                          }
                        }}
                        min={1}
                        max={9999}
                        type='number'
                        name='verticalAxisCount'
                        id='verticalAxisCount'
                      />
                    </FormGroup>
                    <FormGroup className='flex-row flex-center-items'>
                      <SublimeSwitch
                        onChange={this.handleCheckChange}
                        checked={!horizontalLoop}
                        name='horizontalLoop'
                        id='horizontalLoop'
                      />
                      <Label
                        style={{ marginBottom: 0 }}
                        className='flex-row marged-right'
                        for='horizontalLoop'
                      >
                        Desactivate complete loop on the Horizontal axis :
                        <SublimeInfo
                          target='horizontalLoop'
                          imgSrc={getImage('loop-horizontal')}
                        />
                      </Label>
                    </FormGroup>
                    <FormGroup className='flex-row flex-center-items'>
                      <SublimeSwitch
                        onChange={this.handleCheckChange}
                        checked={!verticalLoop}
                        name='verticalLoop'
                        id='verticalLoop'
                      />
                      <Label
                        style={{ marginBottom: 0 }}
                        className='flex-row marged-right'
                        for='verticalLoop'
                      >
                        Desactivate complete loop on the Vertical axis :
                        <SublimeInfo
                          target='verticalLoop'
                          imgSrc={getImage('loop-vertical')}
                        />
                      </Label>
                    </FormGroup>
                  </form>
                </Col>
                <Col style={{ display: 'none' }} xs={3} md={3}>
                  <div className='full-height flex-column flex-center flex-center-items'>
                    <Button
                      id='saveConfigButton'
                      size='sm'
                      disabled={
                        horizontalAxisCount === 0 || verticalAxisCount === 0
                      }
                      color='primary'
                      onClick={this.saveGridConfig}
                    >
                      Save choice
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            xs={12}
            md={12}
            className={
              configSaved
                ? 'sublime-switch-content-active'
                : 'sublime-switch-content'
            }
          >
            <div className='marged-bottom'>
              <Button size='sm' onClick={this.editGridConfig}>
                Edit config
              </Button>
            </div>
            <Table responsive>
              <thead>
                <tr>
                  <th colSpan={1}>Config title</th>
                  <th colSpan={2}>Images</th>
                  <th colSpan={1}>Default image</th>
                  <th colSpan={1}>Default config</th>
                </tr>
              </thead>
              <tbody>
                {configurations.map((config, idx) => {
                  const joinedNomenclature = config
                    .map(i => i.nomenclature)
                    .join('&');
                  const color =
                    alreadyUploaded[joinedNomenclature] === gridCount
                      ? successColor
                      : warningColor;
                  return (
                    <tr key={joinedNomenclature}>
                      <td
                        align='left'
                        style={{ verticalAlign: 'middle' }}
                        colSpan={1}
                      >
                        <span
                          style={{ color }}
                          dangerouslySetInnerHTML={{
                            __html: config
                              .map(i => getTitleTree(menu, i))
                              .join('<span class="sublime-red"> & </span>')
                          }}
                        />
                      </td>
                      <td
                        align='center'
                        style={{ verticalAlign: 'middle' }}
                        colSpan={2}
                      >
                        <ConfigUploader
                          canDeleteItem={root_url ? false : true}
                          showCounter
                          isValid={
                            alreadyUploaded[joinedNomenclature] === gridCount
                          }
                          onUpload={() => {
                            const event = document.createEvent('Event');
                            event.initEvent('newUploads', true, true);
                            document.dispatchEvent(event);
                          }}
                          limit={gridCount}
                          uploadCounterCallback={uploadCounterCallback}
                          folderName={joinedNomenclature}
                          originalSize={original_size}
                          updateOriginalSize={({ width, height }) => {
                            return store.dispatch(
                              updateConfigField(store, {
                                original_size: `${width}x${height}`
                              })
                            );
                          }}
                        />
                      </td>
                      <td
                        align='center'
                        style={{ verticalAlign: 'middle' }}
                        colSpan={1}
                      >
                        <DefaultImageSelector
                          configTitle={config
                            .map(i => getTitleTree(menu, i))
                            .join('->')}
                          folderName={joinedNomenclature}
                          store={store}
                        />
                      </td>
                      <td
                        align='center'
                        style={{ verticalAlign: 'middle' }}
                        colSpan={1}
                      >
                        <SublimeSwitch
                          onChange={() => {
                            this.setState({ defaultConf: joinedNomenclature });
                            store.dispatch(
                              updateConfigField(store, {
                                default_configuration: joinedNomenclature
                              })
                            );
                          }}
                          checked={defaultConf === joinedNomenclature}
                          name='defaultConf'
                          id='defaultConf'
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    );
  }
}
