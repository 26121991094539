import * as React from "react";
import ContactForm from "./ContactForm";

interface IContactSupportProps {}

const ContactSupport: React.FunctionComponent<IContactSupportProps> = () => {
  return (
    <div className="layout-margin">
      <ContactForm />
    </div>
  );
};

export default ContactSupport;
