export function formatFileSize(
  bytes: number,
  decimalPoint: number,
  withUnit = true
) {
  if (bytes === 0) return '0 Bytes';
  var k = 1000,
    dm = decimalPoint || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  if (withUnit) {
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
}
