/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import Configurator from './configurator/Configurator';
import projectReducer from '../store/reducers/project';
import {
  login,
  initializeProject,
  editProject,
  clearProject
} from '../store/actions/project';
import '../assets/styles/switch.scss';
import 'react-sortable-tree/style.css';
// import preventRefresh, { unmountPreventRefresh } from '../utils/app';
/*
import it from your combine reducers
import { projectReducer } from 'sublime-configurator';

export default combineReducers({
  ...
  project: projectReducer
});
*/
export { projectReducer };

/*
  Import it where you want use it and pass your store object

  import store from './store';
  import Test from 'sublime-configurator';

  <Configurator
    store={store}
    tokens={store.getState().auth}
    uploaderToken={store.getState().user.uploader_token}
  />
*/

export default class index extends Component {
  state = {
    loading: true
  };
  componentDidMount = async () => {
    const {
      tokens,
      store,
      uploaderToken,
      projectId,
      autoRedirect
    } = this.props;
    store.subscribe(() => {
      this.forceUpdate();
    });

    // fetch credentials if passed to <Configurator />
    if (tokens) {
      store.dispatch(login(tokens, uploaderToken));
      if (!projectId) {
        // request a projectId if not present
        await store.dispatch(initializeProject(store));
        this.setState({ loading: false });
        if (autoRedirect) {
          const { id } = store.getState().projects.project;
          // @ts-ignore
          window.location = `/dashboard/projects/edit/${id}`;
        }
      } else {
        await store.dispatch(editProject(store, projectId));
        this.setState({ loading: false });
      }
    }
  };

  componentWillUnmount() {
    const { store } = this.props;
    store.dispatch(clearProject());
  }

  render() {
    const { store } = this.props;
    const { loading } = this.state;

    if (store.getState().projects.isConnected) {
      return (
        <Configurator
          loading={loading}
          store={store}
          className='sublime-menu-configurator'
        />
      );
    }
    return <p>Please connect you !</p>;
  }
}
