import * as React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  Toast,
  ToastBody,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
  Label,
  Button,
  Spinner
} from "reactstrap";
import { WithT } from "i18next";
import SublimeHeader from "../../shared/SublimeHeader";
//@ts-ignore
import * as appActions from "../../../store/actions/app";
import { IStoreState, IUserState } from "../../../interfaces/store";

interface IContactFormProps extends WithT {
  user: IUserState;
  sendSupportEmail: any;
}

const ContactForm: React.FunctionComponent<IContactFormProps> = ({
  t,
  user,
  sendSupportEmail
}) => {
  const defaultState = {
    email: user.email,
    subject: "",
    message: ""
  };
  const [supportForm, setsupportForm] = React.useState(defaultState);
  const [loading, setloading] = React.useState(false);

  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    setloading(true);
    await sendSupportEmail(
      supportForm.email,
      supportForm.subject,
      supportForm.message
    );
    setsupportForm({ ...defaultState });
    setloading(false);
  };
  return (
    <>
      <SublimeHeader title={t("contact.title")} />
      <Toast>
        <ToastBody>
          <Form onSubmit={submitForm}>
            <Row>
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label for="email">{t("admin:users.fields.email")}</Label>
                  <Input
                    value={supportForm.email}
                    onChange={e => {
                      setsupportForm({
                        ...supportForm,
                        email: e.target.value
                      });
                    }}
                    type="text"
                    name="email"
                    id="email"
                    placeholder={t("contact.email")}
                  />
                </FormGroup>
              </Col>
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label for="subject">{t("shared:words.subject")}</Label>
                  <Input
                    value={supportForm.subject}
                    onChange={e => {
                      setsupportForm({
                        ...supportForm,
                        subject: e.target.value
                      });
                    }}
                    type="text"
                    name="subject"
                    id="subject"
                    placeholder={t("contact.subjectPH")}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FormGroup>
                  <Label for="message">{t("shared:words.message")}</Label>
                  <Input
                    style={{ minHeight: "30vh" }}
                    type="textarea"
                    value={supportForm.message}
                    onChange={e => {
                      setsupportForm({
                        ...supportForm,
                        message: e.target.value
                      });
                    }}
                    name="message"
                    id="message"
                    placeholder={t("contact.messagePH")}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FormGroup className="flex-center">
                  <Button
                    size="sm"
                    disabled={loading}
                    type="submit"
                    color="success"
                  >
                    {loading && (
                      <Spinner
                        className="marged-right"
                        color="secondary"
                        size="sm"
                      />
                    )}
                    {t("contact.send")}
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ToastBody>
      </Toast>
    </>
  );
};

const mapStateToProps = (state: IStoreState) => ({
  user: state.user
});

const mapDispatchToProps = {
  sendSupportEmail: appActions.sendSupportEmail
};

export default withTranslation("support")(
  //@ts-ignore
  connect(mapStateToProps, mapDispatchToProps)(ContactForm)
);
