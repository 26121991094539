//@ts-ignore
import { b64ToImage, fileToB64 } from '../images.js';
import { FileWithPath } from 'file-selector';

export interface IImage {
  width: string;
  height: string;
}

// Get width and height of the first upload (used to create root folder of uploads)
export const getFirstUploadDimentions = async (
  file: FileWithPath
): Promise<IImage> => {
  return new Promise(async (resolve, reject) => {
    try {
      const b64File = await fileToB64(file);
      const img = await b64ToImage(b64File);
      resolve(img);
    } catch (error) {
      reject(error);
    }
  });
};
