// @ts-nocheck
import React from 'react';
import store from '../../../../store';
import SublimeConfigurator from '../../../SublimeConfigurator';

export default function Edit(props) {
  const { match } = props;
  const { id } = match.params;
  return (
    <div
      style={{
        minHeight: 'calc(100vh - 80px)',
        marginRight: '50px'
      }}
    >
      <SublimeConfigurator
        store={store}
        tokens={store.getState().auth}
        uploaderToken={store.getState().user.uploader_token}
        projectId={id}
      />
    </div>
  );
}
