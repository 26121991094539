const sublimeUploaderKey = 'sublime-uploader-token';
const sublimeProjectKey = 'sublime-project';

export function setProjectId(projectId, projectDisk, userId) {
  try {
    localStorage.setItem(sublimeProjectKey, JSON.stringify({ projectId, projectDisk, userId }));
    return 'project id set !';
  } catch (err) {
    console.log('Error while set projectId', err);
    return err;
  }
}

export function getProjectId() {
  try {
    return JSON.parse(localStorage.getItem(sublimeProjectKey));
  } catch (err) {
    console.log('Error while get projectId', err);
    return err;
  }
}

export function setUploaderToken(token) {
  try {
    localStorage.setItem(sublimeUploaderKey, token);
    return 'token set !';
  } catch (err) {
    console.log('Error while set token', err);
    return err;
  }
}

export function getUploaderToken() {
  try {
    return localStorage.getItem(sublimeUploaderKey);
  } catch (err) {
    console.log('Error while get token', err);
    return err;
  }
}
