import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { Modal, ModalBody, Button, ButtonGroup } from 'reactstrap';
import { FaTrash } from 'react-icons/fa';
import { IoMdStats } from 'react-icons/io';
import ReactTable from 'react-table';
import Select from 'react-select';
import SublimeSwitch from '../../../ui-kit/SublimeSwitch/index';
import Confirm from '../../../ui-kit/messageBox/Confirm/index';
import {
  fetchUsers,
  updateUser,
  fetchRoles,
  deleteUser
} from '../../../store/actions/user';
import Stat from '../../private/dashboard/account/statistics/index';
import { colourStyles } from '../../shared/styles/select.ts';
import SublimeHeader from '../../shared/SublimeHeader';

export class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roles: [],
      status: [],
      makeConfirm: true,
      stat: {
        currentSelected: null,
        modal: false
      }
    };
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  componentDidMount() {
    this.fetchUsers();
    this.props
      .fetchRoles()
      .then(response => {
        this.setState({
          roles: [...response.data.roles],
          status: [...response.data.status]
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  fetchUsers = () => {
    const { fetchUsers } = this.props;
    fetchUsers();
  };

  updateUser = async (values, userId) => {
    const { makeConfirm } = this.state;
    const { updateUser, t } = this.props;
    if (makeConfirm) {
      Confirm(
        t('users.update.confirm.title', { id: userId }),
        t('users.update.confirm.message', { values: JSON.stringify(values) }),
        async () => {
          await updateUser(values, userId);
          this.fetchUsers();
        },
        () => {
          this.fetchUsers();
        },
        undefined
      );
    } else {
      await updateUser(values, userId);
      this.fetchUsers();
    }
  };

  deleteUser = async (userId, userEmail) => {
    const { deleteUser, t } = this.props;
    Confirm(
      t('users.delete.confirm.title'),
      t('users.delete.confirm.message', { email: userEmail }),
      async () => {
        await deleteUser(userId);
        this.fetchUsers();
      },
      () => {
        this.fetchUsers();
      },
      undefined
    );
  };

  editableContent = (field, row) => {
    return (
      <div
        contentEditable
        suppressContentEditableWarning
        onInput={e => {
          e.persist();
          clearTimeout(this.updateTimeout);
          this.updateTimeout = setTimeout(() => {
            // @ts-ignore
            this.updateUser({ [field]: e.target.innerHTML }, row._original.id);
          }, 1000);
        }}
        dangerouslySetInnerHTML={{
          __html: row._original[field]
        }}
      />
    );
  };

  editableRolesContent = row => {
    const { roles } = this.state;
    const { t, updateUser } = this.props;
    const options = roles.map(role => ({
      value: role.name,
      label: t(`users.roles.${role.name}`),
      id: role.id
    }));
    return (
      <Select
        isMulti
        isSearchable={false}
        styles={colourStyles}
        value={row._original.roles.map(role => ({
          value: role.name,
          label: t(`users.roles.${role.name}`),
          id: role.id
        }))}
        name='roles'
        options={options}
        onChange={async selecteds => {
          if (selecteds) {
            await updateUser(
              { roles: selecteds.map(sel => ({ id: sel.id })) },
              row._original.id
            );
            this.fetchUsers();
          }
        }}
      />
    );
  };

  editableStatusContent = row => {
    const { status } = this.state;
    const { t } = this.props;
    const options = status
      .filter(s => s.name !== 'deleted')
      .map(s => ({
        value: s.name,
        label: t(`users.status.${s.name}`)
      }));
    return (
      <Select
        styles={colourStyles}
        isSearchable={false}
        value={options.filter(o => o.value === row._original.status)}
        name='status'
        options={options}
        onChange={selected => {
          this.updateUser({ status: selected.value }, row._original.id);
        }}
      />
    );
  };

  columnsTemplate() {
    const { t } = this.props;
    return [
      { Header: t('users.fields.id'), accessor: 'id', maxWidth: 30 },
      {
        Header: t('users.fields.email'),
        width: 300,
        accessor: 'email',
        Cell: ({ row }) => this.editableContent('email', row)
      },
      {
        Header: t('users.fields.roles'),
        width: 300,
        sortable: false,
        Cell: ({ row }) => this.editableRolesContent(row),
        getProps: () => ({
          style: {
            overflow: 'unset'
          }
        })
      },
      {
        Header: t('users.fields.firstName'),
        accessor: 'firstname',
        Cell: ({ row }) => this.editableContent('firstname', row)
      },
      {
        Header: t('users.fields.lastName'),
        accessor: 'lastname',
        Cell: ({ row }) => this.editableContent('lastname', row)
      },
      {
        Header: t('users.fields.company'),
        accessor: 'company',
        Cell: ({ row }) => this.editableContent('company', row)
      },
      {
        Header: t('users.fields.status'),
        accessor: 'status',
        Cell: ({ row }) => this.editableStatusContent(row),
        getProps: () => ({
          style: {
            overflow: 'unset'
          }
        })
      },
      {
        Header: t('users.fields.actions'),
        sortable: false,
        Cell: ({ row }) => {
          return (
            <ButtonGroup size='sm'>
              <Button
                data-tip={t('users.fields.stat')}
                color='success'
                onClick={() => this.toggleModal(row._original.id)}
              >
                <IoMdStats />
              </Button>
              <Button
                data-tip={t('shared:ressources.delete')}
                color='danger'
                onClick={() => {
                  this.deleteUser(row._original.id, row._original.email);
                }}
              >
                <FaTrash />
              </Button>
            </ButtonGroup>
          );
        }
      }
    ];
  }

  toggleModal = (currentSelected = null) => {
    this.setState(prevState => ({
      ...prevState,
      stat: { currentSelected, modal: !prevState.stat.modal }
    }));
  };
  render() {
    const { users, t } = this.props;
    const { makeConfirm, stat } = this.state;
    return (
      <div className='layout-margin'>
        <SublimeHeader title={t('users.title')} />
        <ReactTooltip />
        <div className='marged-left marged-top marged-bottom flex-row flex-center flex-center-items'>
          <SublimeSwitch
            onChange={e => this.setState({ makeConfirm: e.target.checked })}
            checked={makeConfirm}
            name='makeConfirm'
            id='makeConfirm'
            className='marged-right'
          />
          <label htmlFor='makeConfirm'>confirm before update ?</label>
        </div>
        <ReactTable
          data={users}
          columns={this.columnsTemplate()}
          className='-striped -highlight'
          defaultPageSize={10}
        />
        <Modal centered isOpen={stat.modal} toggle={this.toggleModal}>
          <ModalBody>
            <Stat inModal userId={stat.currentSelected} />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  users: state.user.allUsers
});

const mapDispatchToProps = {
  fetchUsers,
  updateUser,
  fetchRoles,
  deleteUser
};

// @ts-ignore
export default withTranslation(['admin'])(
  connect(mapStateToProps, mapDispatchToProps)(Index)
);
