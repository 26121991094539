import { IStore } from '../../interfaces/store';
//@ts-ignore
import { updateConfigField } from '../../store/actions/project';

export const resetConfigs = (store: IStore, otherFields = {}) => {
  const { projects } = store.getState();
  const { project } = projects;
  const { named_nomenclatures } = project;
  const resetedConfig = named_nomenclatures;
  Object.keys(resetedConfig).forEach(nn => {
    resetedConfig[nn].defaultPosition = { x: 1, y: 1 };
  });
  store.dispatch(
    updateConfigField(store, {
      ...otherFields,
      named_nomenclatures: JSON.stringify(resetedConfig),
      original_size: null
    })
  );
};
