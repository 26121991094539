import * as React from "react";
import { useDropzone } from "react-dropzone";
import { formatFileSize } from "../../../utils/bytes";
import { truncate } from "../../../utils/string";
//@ts-ignore
import { FaTrash } from "react-icons/fa";
//@ts-ignore
import { MdCancel } from "react-icons/md";
//@ts-ignore
import { IoIosAddCircleOutline } from "react-icons/io";
import { Confirm, Alert, SublimeTheme } from "exalt3d-sublime-ui";
//@ts-ignore
import { Spinner, Progress, Button } from "reactstrap";
//@ts-ignore
import Axios from "axios";

import "./uploader.scss";
import {
  uploadFiles,
  fetchAlreadyUploaded,
  deleteUpload,
  deleteFolder
} from "../../../utils/uploader/configurationsUploader";
import { IUpload } from "../../../interfaces/project";
import { acceptTypes } from "../../../utils/uploader/file";
import { getFirstUploadDimentions } from "../../../utils/uploader/pictures";
export interface FileWithPath extends File {
  readonly path?: string;
}

const CancelToken = Axios.CancelToken;
const source = CancelToken.source();

interface IConfigUploaderProps {
  onUpload: any;
  onDelete: any;
  limit: number;
  folderName: string;
  originalSize: string;
  updateOriginalSize: any;
  uploadCounterCallback: any;
  unfechedFiles: number;
  isValid: boolean;
  showCounter?: boolean;
  canDeleteItem?: boolean;
}

const ConfigUploader: React.FunctionComponent<IConfigUploaderProps> = ({
  onUpload,
  onDelete = () => {},
  limit = 1,
  folderName,
  originalSize,
  updateOriginalSize,
  uploadCounterCallback,
  isValid,
  showCounter = false,
  canDeleteItem = true
}) => {
  const [alreadyUploaded, setalreadyUploaded] = React.useState<Array<IUpload>>(
    []
  );
  const [loading, setloading] = React.useState(false);
  const [progressValue, setprogressValue] = React.useState(0);
  const fetchFiles = () => {
    setloading(true);
    let caller;
    clearTimeout(caller);
    caller = setTimeout(() => {
      fetchAlreadyUploaded(folderName)
        .then(response => {
          setalreadyUploaded([...response.data.uploads]);
          uploadCounterCallback({
            folderName,
            uploadsCount: response.data.uploads.length
          });
          setloading(false);
        })
        .catch(error => {
          setloading(false);
          console.log("error while fetchinf files", error);
        });
    }, 300);
  };
  const onDrop = (acceptedFiles: Array<FileWithPath>) => {
    const totalAfterDrop = alreadyUploaded.length + acceptedFiles.length;
    const itCanUpload = totalAfterDrop <= limit;
    if (itCanUpload) {
      setloading(true);
      if (originalSize) {
        uploadFiles(
          acceptedFiles,
          folderName,
          originalSize,
          setprogressValue,
          source.token
        )
          .then(response => {
            onUpload(response);
            fetchFiles();
          })
          .catch(onUpload);
      } else {
        getFirstUploadDimentions(acceptedFiles[0])
          .then(img => {
            updateOriginalSize(img);
            uploadFiles(
              acceptedFiles,
              folderName,
              `${img.width}x${img.height}`,
              setprogressValue,
              source.token
            )
              .then(response => {
                onUpload(response);
                fetchFiles();
                setloading(false);
              })
              .catch(error => {
                onUpload(error);
                setloading(false);
              });
          })
          .catch(error => {
            console.log("error when taking file dimentions", error);
          });
      }
    } else {
      Alert(
        "Files number error",
        "You are trying to add more files than possible",
        undefined
      );
    }
  };

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    onDrop,
    accept: acceptTypes("image"),
    multiple: limit > 1
  });

  const allreadyUploadedList = alreadyUploaded.map((file: IUpload) => (
    <li
      className="sublime-uploader-item full-width flex-row flex-between flex-center-items"
      key={file.fileName}
    >
      <span>
        {truncate(file.fileName, 30)} - {formatFileSize(file.fileSize, 2)}
      </span>
      {canDeleteItem && (
        <span
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            deleteUpload(file.id)
              .then(response => {
                onDelete(response);
                fetchFiles();
              })
              .catch(error => {
                console.log(error);
              });
          }}
          role="button"
          className="sublime-uploader-delete pointed"
        >
          <FaTrash size="14px" color={SublimeTheme.sublime.color.mainRed} />
        </span>
      )}
    </li>
  ));

  interface DeleteAllButtonProps {}
  const DeleteAllButton: React.FC<DeleteAllButtonProps> = () => {
    return (
      <div className="marged-top marged-right">
        <Button
          onClick={() => {
            Confirm(
              "Confirm delete all",
              "Are you sure you want delete all files for this config?",
              () => {
                deleteFolder(folderName)
                  .then(response => {
                    onDelete(response);
                    fetchFiles();
                  })
                  .catch(error => {
                    console.log(error);
                  });
              },
              () => {}, // Canceled
              undefined
            );
          }}
          color="danger"
          size="sm"
        >
          Delete all
        </Button>
      </div>
    );
  };

  interface CounterProps {}
  const Counter: React.FC<CounterProps> = () => {
    if (!showCounter) return null;
    return (
      <div className="marged-top">
        <p
          style={{
            color: isValid
              ? SublimeTheme.sublime.color.mainGreen
              : SublimeTheme.sublime.color.mainRed
          }}
        >
          {alreadyUploaded.length}/{limit}
        </p>
      </div>
    );
  };

  React.useEffect(() => {
    if (originalSize) {
      fetchFiles();
    }
  }, []);
  return (
    <>
      <div
        style={{
          maxHeight: "150px",
          overflow: "scroll",
          borderColor: isValid
            ? SublimeTheme.sublime.color.mainGreen
            : SublimeTheme.sublime.color.mainRed
        }}
        className={`sublime-uploader pointed flex-column ${
          alreadyUploaded.length > 6
            ? "flex-start"
            : alreadyUploaded.length < 1
            ? "flex-end"
            : "flex-center"
        }`}
        {...getRootProps()}
      >
        {loading && (
          <div className="uploads-loader">
            <Spinner type="grow" color="success" size="xs" />
          </div>
        )}
        <input {...getInputProps()} disabled={isValid || loading} />
        {alreadyUploaded.length < 1 && !loading && (
          <div className="flex-column flex-center-items flex-center">
            <IoIosAddCircleOutline
              size={60}
              color={SublimeTheme.sublime.color.mainGreen}
            />
            <p className="upload-info-text">
              Drag 'n' drop some files here, or click to select files
            </p>
          </div>
        )}
        {loading && (
          <div className="full-width flex-row flex-center-items">
            <Progress
              style={{ width: "90%" }}
              color="success"
              striped
              value={progressValue}
            />
            <span
              role="button"
              className="pointed marged-left"
              onClick={() => {
                source.cancel("Operation canceled by the user.");
                setloading(false);
              }}
            >
              <MdCancel color={SublimeTheme.sublime.color.mainRed} size={20} />
            </span>
          </div>
        )}
        <div className="sublime-uploader-content">
          <aside>
            <ul>{allreadyUploadedList}</ul>
          </aside>
        </div>
      </div>
      <div className="flex-row flex-center">
        {(alreadyUploaded.length >= 3 || (limit === 1 && !canDeleteItem)) && (
          <DeleteAllButton />
        )}
        <div className="marged-top">
          <Button
            disabled={isValid || loading}
            onClick={() => {
              if (inputRef.current) {
                //@ts-ignore
                inputRef.current.click();
              }
            }}
            color="success"
            size="sm"
          >
            Add file
          </Button>
        </div>
      </div>
      <Counter />
    </>
  );
};

export default ConfigUploader;
