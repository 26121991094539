import React from 'react';
import { connect } from 'react-redux';
import { signOut } from '../../../store/actions/auth';
import { FaPowerOff } from 'react-icons/fa';
import SublimeTheme from '../../../ui-kit/theme';
function index({ signOut }) {
  return (
    <FaPowerOff
      className='pointed'
      role='button'
      onClick={signOut}
      size={20}
      color={SublimeTheme.sublime.color.mainGold}
    />
  );
}

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = {
  signOut
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
