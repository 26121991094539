import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { signOut } from '../../../store/actions/auth';
import { fetchPosts } from '../../../store/actions/posts';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { Container, Row, Col } from 'reactstrap';
import Card from '../../shared/posts/card/index';
import SublimeHeader from '../../shared/SublimeHeader';

import './Home.scss';

export class Home extends Component {
  componentDidMount() {
    const { fetchPosts } = this.props;
    fetchPosts({ query: { status: 'published' } });
  }

  render() {
    const { posts, t } = this.props;
    return (
      <div className='layout-margin'>
        <SublimeHeader title={t('title')} />
        <Container style={{ margin: 0, paddingLeft: 0 }}>
          <Row>
            {posts.length >= 1 ? (
              posts.map(post => {
                return (
                  <LazyLoadComponent key={`post-${post.id}`}>
                    <Col lg={3} md={6} sm={12}>
                      <Card {...post} />
                    </Col>
                  </LazyLoadComponent>
                );
              })
            ) : (
              <p>No post at this time !</p>
            )}
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  posts: state.posts
});

const mapDispatchToProps = {
  signOut,
  fetchPosts
};

// @ts-ignore
export default withTranslation(['dashboard'])(
  connect(mapStateToProps, mapDispatchToProps)(Home)
);
