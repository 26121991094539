// import Axios from 'axios';
import Axios, { AxiosResponse } from 'axios';
import { FileWithPath } from 'file-selector';
import { getProjectId, getUploaderToken } from '../sessions';
import { generateRandomFileName } from '../string';
//@ts-ignore
import url from '../api';

export const uploadAttachmentFile = async (
  file: FileWithPath
): Promise<AxiosResponse> => {
  const formData = new FormData();
  const { projectId, userId, projectDisk } = getProjectId();
  const uploadToken = getUploaderToken();
  formData.append('sublimeProjectId', projectId);
  formData.append('projectDisk', projectDisk);
  formData.append('sublimeUserId', userId);
  formData.append('pictures', file, generateRandomFileName(file.name));
  return new Promise(async (resolve, reject) => {
    Axios.post(url.uploaderUrl.upload, formData, {
      headers: {
        Authorization: uploadToken
      }
    })
      .then(resolve)
      .catch(reject);
  });
};
