import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Button, ButtonGroup } from 'reactstrap';
import ReactTable from 'react-table';
import {
  FaPen,
  FaTrash,
  FaFolderOpen,
  FaCopy,
  FaTrashRestoreAlt
} from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import SublimeSpnr from '../../../ui-kit/Spinners/SublimeSpinner/index';
import SublimeSwitch from '../../../ui-kit/SublimeSwitch/index';
import Confirm from '../../../ui-kit/messageBox/Confirm/index';
import moment from 'moment';
import {
  fetchProjects,
  deleteProject,
  restoreProject,
  updateProjects,
  duplicateProject
} from '../../../store/actions/sublime-projects';
import AlertMessage from '../../shared/messages/Alert';
import SublimeHeader from '../../shared/SublimeHeader';
export class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      seeDeleted: false
    };
  }

  async componentDidMount() {
    this.fetchProjects();
  }

  componentDidUpdate(prevProps, prevState) {
    const { seeDeleted, loading } = this.state;
    if (seeDeleted && loading) {
      this.fetchProjects('deleted');
    }
    if (!seeDeleted && loading) {
      this.fetchProjects('all');
    }
    ReactTooltip.rebuild();
  }

  fetchProjects = async (status = 'all') => {
    await this.props.fetchProjects({ query: { status } });
    this.setState({ loading: false });
  };

  editProject = projectId => {
    const { history } = this.props;
    history.push(`/dashboard/projects/edit/${projectId}`);
  };

  duplicateProject = async projectId => {
    const { duplicateProject } = this.props;
    await duplicateProject(projectId);
    this.fetchProjects();
  };

  restoreProject = async project => {
    const { restoreProject } = this.props;
    await restoreProject(project.id);
    this.fetchProjects('deleted');
  };

  deleteProject = async project => {
    const { deleteProject, t } = this.props;
    Confirm(
      t('delete.confirm.title'),
      t('delete.confirm.message', { name: project.name || project.id }),
      async () => {
        await deleteProject(project.id);
        this.fetchProjects();
      },
      () => {},
      undefined
    );
  };

  disableProject = async project => {
    const { updateProjects, t } = this.props;
    Confirm(
      t('update.status.confirm.title'),
      t('update.status.confirm.message', { name: project.name || project.id }),
      async () => {
        await updateProjects(project.id, { status: 'inactive' });
        this.fetchProjects();
      },
      () => {},
      undefined
    );
  };

  columnsTemplate = () => {
    const { t } = this.props;
    const { seeDeleted } = this.state;

    return [
      { Header: t('fields.id'), accessor: 'id', maxWidth: 30 },
      {
        Header: t('fields.createdAt'),
        accessor: 'created_at',
        Cell: ({ row }) => {
          return <span>{moment(row.created_at).format('DD/MM/YYYY')}</span>;
        }
      },
      {
        Header: t('fields.updatedAt'),
        accessor: 'updated_at',
        Cell: ({ row }) => {
          return <span>{moment(row.updated_at).format('DD/MM/YYYY')}</span>;
        }
      },
      { Header: t('fields.title'), accessor: 'name' },
      {
        Header: t('fields.status'),
        accessor: 'status',
        Cell: ({ row }) => <span>{t(`status.${row.status}`)}</span>
      },
      { Header: t('fields.author'), id: 'author', accessor: d => d.user.email },
      {
        Header: t('fields.configFile'),
        accessor: 'config_file_url',
        Cell: ({ row }) => {
          return (
            <>
              {row.config_file_url ? (
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  className='sublime-link'
                  href={row.config_file_url}
                >
                  {t('shared:words.download')}
                </a>
              ) : (
                <span>-</span>
              )}
            </>
          );
        }
      },
      {
        Header: t('fields.configFile'),
        accessor: 'config_file_url',
        Cell: ({ row }) => {
          let indexFile = undefined;
          if (row.config_file_url) {
            indexFile = row.config_file_url.split('/');
            indexFile.pop();
            indexFile.push('index.html');
            indexFile = indexFile.join('/');
          }
          return (
            <>
              {indexFile ? (
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  className='sublime-link'
                  href={indexFile}
                >
                  {t('shared:words.preview')}
                </a>
              ) : (
                <span>-</span>
              )}
            </>
          );
        }
      },
      {
        Header: t('fields.actions'),
        sortable: false,
        Cell: ({ row }) => {
          return (
            <ButtonGroup size='sm'>
              <Button
                data-tip={t('shared:ressources.edit')}
                color='warning'
                onClick={() => this.editProject(row.id)}
              >
                <FaPen />
              </Button>
              <Button
                data-tip={t('shared:ressources.duplicate')}
                color='success'
                onClick={() => this.duplicateProject(row.id)}
              >
                <FaCopy />
              </Button>
              {seeDeleted ? (
                <Button
                  data-tip={t('shared:ressources.restore')}
                  color='light'
                  onClick={() => this.restoreProject(row)}
                >
                  <FaTrashRestoreAlt />
                </Button>
              ) : (
                <Button
                  data-tip={t('shared:ressources.delete')}
                  color='danger'
                  onClick={() => this.deleteProject(row)}
                >
                  <FaTrash />
                </Button>
              )}
              {row.status !== 'inactive' && (
                <Button
                  data-tip={t('shared:ressources.disable')}
                  color='info'
                  onClick={() => this.disableProject(row)}
                >
                  <FaFolderOpen />
                </Button>
              )}
            </ButtonGroup>
          );
        }
      }
    ];
  };

  render() {
    const { loading, seeDeleted } = this.state;
    const { projects, t } = this.props;
    if (loading) {
      return <SublimeSpnr />;
    }

    return (
      <div className='layout-margin'>
        <SublimeHeader title={t('admin:projects.title')} />
        <ReactTooltip />
        <div className='marged-left marged-top marged-bottom flex-row flex-center flex-center-items'>
          <SublimeSwitch
            onChange={e =>
              this.setState({
                seeDeleted: e.target.checked,
                loading: true
              })
            }
            checked={seeDeleted}
            name='seeDeleted'
            id='seeDeleted'
            className='marged-right'
          />
          <label htmlFor='seeDeleted'>
            {t('admin:projects.filters.deletedProjects')}
          </label>
        </div>
        <div className='flex-center'>
          <AlertMessage />
        </div>
        <ReactTable
          className='-striped -highlight'
          defaultPageSize={10}
          expanded={false}
          data={projects}
          columns={this.columnsTemplate()}
          getProps={() => ({ style: { textAlign: 'center' } })}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  projects: state.sublimeProjects
});

const mapDispatchToProps = {
  fetchProjects,
  deleteProject,
  restoreProject,
  updateProjects,
  duplicateProject
};

// @ts-ignore
export default withTranslation(['projects', 'shared', 'admin'])(
  connect(mapStateToProps, mapDispatchToProps)(Index)
);
