import React from 'react';
import { Row, Col } from 'reactstrap';
import AttachmentUploader from '../../uploader/Attachment.tsx';

export default function(props) {
  const { context, item } = props;
  const { fileDatas } = item.file;
  return (
    <Row className='flex-center full-width sublime-file-attachment-container'>
      <Col xs={12} md={12}>
        <AttachmentUploader
          allreadyUploaded={fileDatas.id ? [fileDatas] : undefined}
          type='file'
          onUpload={response => {
            if (response.status === 200) {
              context.setState({
                ...context.state,
                currentItem: {
                  ...context.state.currentItem,
                  file: {
                    ...context.state.currentItem.file,
                    url: response.data.upload.url,
                    fileDatas: { ...response.data.upload } // keep id and some file datas
                  }
                }
              });
            }
          }}
          onDelete={response => {
            if (response.status === 200) {
              context.setState({
                ...context.state,
                currentItem: {
                  ...context.state.currentItem,
                  file: {
                    ...context.state.currentItem.file,
                    url: '',
                    fileDatas: {}
                  }
                }
              });
            }
          }}
        />
      </Col>
    </Row>
  );
}
