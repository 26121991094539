import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Button, ButtonGroup, Modal, ModalBody } from 'reactstrap';
import Confirm from '../../../../ui-kit/messageBox/Confirm/index';
import SublimeTheme from '../../../../ui-kit/theme';
import SublimeSpnr from '../../../../ui-kit/Spinners/SublimeSpinner/index';
import ReactTable from 'react-table';
import { FaPen, FaTrash, FaFolderOpen, FaCopy, FaCheck } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import {
  fetchProjects,
  deleteProject,
  updateProjects,
  toggleStatus,
  duplicateProject
} from '../../../../store/actions/sublime-projects';
import { infoMessage } from '../../../../store/actions/app';
import { projectStatusFormater } from '../../../../utils/string';
import codeEmbedParser from '../../../../utils/codeEmbedParser';
import SublimeHeader from '../../../shared/SublimeHeader';

import './index.scss';

function Index({
  projects,
  t,
  fetchProjects,
  updateProjects,
  infoMessage,
  toggleStatus,
  deleteProject,
  duplicateProject,
  history,
  query
}) {
  const [loading, setLoading] = useState(true);
  const [codeEmbeded, setcodeEmbeded] = useState();

  const formatedHtmlCode = codeEmbeded
    ? `<html>\n  <head>${
        codeEmbeded.head.innerHTML
      }  </head>\n  <body>${codeEmbeded.body.innerHTML.replace(
        /\s\s+/g,
        ' '
      )}  </body>\n</html>`
    : '';

  const displayCodeEmbed = async url => {
    const code = await codeEmbedParser(url);
    console.log('allala', code.head.innerHTML.toString());
    setcodeEmbeded(code);
  };

  useEffect(() => {
    async function fetchDatas() {
      setLoading(true);
      await fetchProjects({ query: { status: query } });
      setLoading(false);
      ReactTooltip.rebuild();
    }
    fetchDatas();
  }, [query]);

  const editProject = projectId => {
    infoMessage(t('edit.infoMessage'));
    history.push(`/dashboard/projects/edit/${projectId}`);
  };

  const deleteSelectedProject = async project => {
    Confirm(
      t('delete.confirm.title'),
      t('delete.confirm.message', { name: project.name || project.id }),
      async () => {
        await deleteProject(project.id);
        fetchProjects({ query: { status: query } });
      },
      () => {},
      undefined
    );
  };

  const duplicateSelectedProject = async projectId => {
    await duplicateProject(projectId);
    fetchProjects({ query: { status: query } });
  };

  const disableProject = async project => {
    Confirm(
      t('update.status.confirm.title'),
      t('update.status.confirm.message', { name: project.name || project.id }),
      async () => {
        await toggleStatus(project.id, 'inactive');
        fetchProjects({ query: { status: query } });
      },
      () => {},
      undefined
    );
  };

  const reActive = async project => {
    Confirm(
      t('update.status.confirm.title'),
      t('update.status.confirm.message', { name: project.name || project.id }),
      async () => {
        await toggleStatus(project.id, 'ready');
        fetchProjects({ query: { status: query } });
      },
      () => {},
      undefined
    );
  };

  const columnsTemplate = () => {
    return [
      { Header: t('fields.id'), accessor: 'id', maxWidth: 30 },
      {
        Header: t('fields.createdAt'),
        accessor: 'created_at',
        Cell: ({ row }) => {
          return <span>{moment(row.created_at).format('MMM Do YY')}</span>;
        }
      },
      { Header: t('fields.title'), accessor: 'name' },
      {
        Header: t('fields.status'),
        accessor: 'status',
        Cell: ({ row }) => <span>{t(`status.${row.status}`)}</span>
      },
      {
        Header: t('fields.configFile'),
        accessor: 'config_file_url',
        show: query === 'ready',
        Cell: ({ row }) => {
          return (
            <>
              {row.config_file_url ? (
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  className='sublime-link'
                  href={row.config_file_url}
                >
                  {t('shared:words.download')}
                </a>
              ) : (
                <span>-</span>
              )}
            </>
          );
        }
      },
      {
        Header: t('fields.codeEmbed'),
        accessor: 'root_url',
        show: query === 'ready',
        Cell: ({ row }) => {
          return (
            <>
              {row.root_url ? (
                <div
                  className='pointed'
                  onClick={() => displayCodeEmbed(row.root_url)}
                  role='button'
                >
                  <p>{t('shared:words.code')}</p>
                </div>
              ) : (
                <span>-</span>
              )}
            </>
          );
        }
      },
      {
        Header: t('fields.rootUrl'),
        accessor: 'root_url',
        show: query === 'ready',
        Cell: ({ row }) => {
          return (
            <>
              {row.root_url ? (
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  className='sublime-link'
                  href={row.root_url}
                >
                  {t('shared:words.preview')}
                </a>
              ) : (
                <span>-</span>
              )}
            </>
          );
        }
      },
      {
        Header: t('fields.actions'),
        width: 150,
        Cell: ({ row }) => {
          return (
            <ButtonGroup size='sm'>
              <Button
                data-tip={t('shared:ressources.edit')}
                color='warning'
                onClick={() => editProject(row.id)}
              >
                <FaPen />
              </Button>
              <Button
                data-tip={t('shared:ressources.duplicate')}
                color='success'
                onClick={() => duplicateSelectedProject(row.id)}
              >
                <FaCopy />
              </Button>
              {row.status !== 'inactive' ? (
                <Button
                  data-tip={t('shared:ressources.disable')}
                  color='info'
                  onClick={() => disableProject(row)}
                >
                  <FaFolderOpen />
                </Button>
              ) : (
                <Button
                  data-tip={t('shared:ressources.reactivate')}
                  color='dark'
                  onClick={() => reActive(row)}
                >
                  <FaCheck color={SublimeTheme.sublime.color.mainGreen} />
                </Button>
              )}
              <Button
                data-tip={t('shared:ressources.delete')}
                color='danger'
                onClick={() => deleteSelectedProject(row)}
              >
                <FaTrash />
              </Button>
            </ButtonGroup>
          );
        }
      }
    ];
  };

  if (loading) {
    return <SublimeSpnr />;
  }
  return (
    <div className='layout-margin'>
      <SublimeHeader
        title={t('title', { type: projectStatusFormater(query) })}
      />
      <ReactTooltip />
      <ReactTable
        className='-striped -highlight'
        defaultPageSize={10}
        expanded={false}
        data={projects}
        columns={columnsTemplate()}
        getProps={() => ({ style: { textAlign: 'center' } })}
      />
      {codeEmbeded && (
        <Modal
          size='lg'
          centered
          toggle={() => setcodeEmbeded(undefined)}
          isOpen={codeEmbeded}
        >
          <ModalBody style={{ margin: 0, padding: 0 }}>
            <code>
              <pre className='sublime-code'>{formatedHtmlCode}</pre>
            </code>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
}

const mapStateToProps = state => ({
  projects: state.sublimeProjects
});

const mapDispatchToProps = {
  fetchProjects,
  deleteProject,
  updateProjects,
  duplicateProject,
  toggleStatus,
  infoMessage
};

export default withTranslation(['projects', 'shared'])(
  // @ts-ignore
  connect(mapStateToProps, mapDispatchToProps)(Index)
);
