import { asyncForEach } from './array';

export function fileToB64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

export function b64ToImage(src) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
}

export async function filesToBlob(files) {
  const blobFiles = [];
  await asyncForEach(files, async file => {
    const req = await fetch(file.url);
    const blob = await req.blob();
    blob.name = file.fileName;
    blob.id = file.id;
    blobFiles.push(blob);
  });
  return blobFiles;
}
