/* eslint-disable no-param-reassign */
import {
  walk,
  find,
  removeNodeAtPath,
  getNodeAtPath,
  addNodeUnderParent,
  changeNodeAtPath,
  getFlatDataFromTree,
  getTreeFromFlatData
} from 'react-sortable-tree';

export function defaultItem(fields = {}) {
  return {
    id: null,
    title: '',
    subtitle: '',
    displayTitle: false,
    thumbnail: {
      isColor: false,
      isThumbnail: false,
      color: '',
      url: '',
      fileDatas: {}
    },
    productReference: {
      isReferenced: false,
      display: false,
      reference: ''
    },
    price: {
      defaultPrice: false,
      option: false,
      optionAmount: ''
    },
    file: {
      url: '',
      fileDatas: {}
    },
    childrenRule: 'OR', // "OR" || "AND"
    parentRule: 'OR', // "OR" || "AND"
    isMandatory: false,
    forbids: [],
    ...fields // it override all default values if fields is given
  };
}

export function generateItem(fields) {
  // generate item with default values or with taken values
  if (fields.data) {
    delete fields.data; // remove data field because is a clone of node || menu
    // otherwise fields.data is nested in fields.data on each update
  }
  const formatedField = defaultItem(fields);
  return {
    id: formatedField.id || new Date().getTime(),
    title: formatedField.title,
    subtitle: formatedField.subtitle,
    children: [],
    data: { ...formatedField }
  };
}

// add a new item inside another
export async function addItem(item, context) {
  const newItem = generateItem({
    ...context.state.newItem,
    price: { ...item.node.data.price }
  }); // keep the parent price and share to the new child
  const { path } = item;
  const parentNode = getNodeAtPath({
    treeData: context.state.treeData,
    path,
    getNodeKey,
    ignoreCollapsed: true
  });

  const newTree = addNodeUnderParent({
    treeData: context.state.treeData,
    newNode: newItem,
    expandParent: true,
    parentKey: parentNode.node.id,
    getNodeKey
  });
  await context.setState({ treeData: [...newTree.treeData] });
  const formatedItem = findMenuById(newTree.treeData, newItem.id);
  context.editAction(formatedItem);
  context.save();
}

export async function duplicateItem(item, context) {
  // duplicate an item at the same level
  const newItem = generateItem({
    ...context.state.newItem,
    ...item.node.data,
    ...{ 
      id: undefined, 
      title: `${item.node.title} - Copy`,
      thumbnail: { ...generateItem({}).data.thumbnail },
      file: { ...generateItem({}).data.file },
    }
  });

  const newTree = addNodeUnderParent({
    treeData: context.state.treeData,
    newNode: newItem,
    expandParent: true,
    // if item is on topLevel add duplicate under it then add like a brother
    parentKey: item.parentNode ? item.parentNode.id : item.node.id,
    getNodeKey
  });
  await context.setState({ treeData: [...newTree.treeData] });
  const formatedItem = findMenuById(newTree.treeData, newItem.id);
  context.editAction(formatedItem);
  context.save();
}

export async function deleteItem(item, context) {
  // delete a given item from tree
  const { path } = item;
  await context.setState({
    treeData: [
      ...removeNodeAtPath({
        treeData: context.state.treeData,
        path,
        getNodeKey,
        ignoreCollapsed: false
      })
    ]
  });
  context.save();
}

// update a given item in tree
export async function updateItem(oldItem, newValue, context, saveOnDb = false) {
  const { path } = oldItem;
  if (oldItem.node.expanded) {
    // keep menu expanded if already expanded
    newValue.expanded = true;
  }
  newValue.children = [...oldItem.node.children];
  await context.setState({
    treeData: [
      ...changeNodeAtPath({
        treeData: context.state.treeData,
        path,
        getNodeKey,
        newNode: { ...oldItem.node, ...newValue }
      })
    ]
  });
  if (saveOnDb) {
    clearTimeout(context.updateTimer);
    context.updateTimer = setTimeout(() => {
      context.save();
    }, 1000);
  }
}

// treeData is complex nested object, covert to flatData before save in DB
export function formatBeforeSave(treeData) {
  return getFlatDataFromTree({
    treeData,
    getNodeKey,
    ignoreCollapsed: false
  }).map(item => ({
    id: item.node.id,
    title: item.node.title,
    subtitle: item.node.subtitle,
    data: item.node.data,
    expanded: item.node.expanded,
    parent: item.path.length > 1 ? item.path[item.path.length - 2] : null
  }));
}

export function findMenuById(treeData, id) {
  // Get a menu item by id
  return find({
    treeData,
    getNodeKey,
    searchQuery: id,
    searchMethod(searchData) {
      return searchData.node.id === id;
    },
    searchFocusOffset: 0
  }).matches[0];
}

export function walkInTree(treeData, callback) {
  // Parse all items in tree
  return walk({
    treeData,
    getNodeKey,
    callback,
    ignoreCollapsed: false
  });
}

// flatData come form DB, need to format for use in react-sortable-tree component
export function formatBeforeEdit(flatData) {
  return getTreeFromFlatData({
    flatData,
    // @ts-ignore
    getKey: node => node.id,
    // @ts-ignore
    getParentKey: node => node.parent,
    rootKey: null
  });
}

function getNodeKey({ node }) {
  return node.id;
}

export function getTitleTree(treeData, menuItem, title = menuItem.node.title) {
  if (menuItem && menuItem.path.length > 1) {
    try {
      const parentNode = findMenuById(
        treeData,
        menuItem.path[menuItem.path.length - 2]
      );
      return getTitleTree(
        treeData,
        parentNode,
        `(${
          parentNode ? parentNode.node.title : menuItem.parentNode.title
        }-${title})`
      );
    } catch (error) {
      console.log('Error when generating title', { error, menuItem });
    }
  }
  return title;
}
