import { SublimeTheme } from "exalt3d-sublime-ui";

export default {
  //@ts-ignore
  control: (styles: any, state: any) => {
    return {
      ...styles,
      boxShadow: state.isFocused ? `inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px ${SublimeTheme.sublime.color.mainGreenRgba(0.6)}` : 0,
    borderColor: state.isFocused
      ? SublimeTheme.sublime.color.mainGreenRgba(0.6)
      : styles.borderColor,
    '&:hover': {
      borderColor: state.isFocused
        ? SublimeTheme.sublime.color.mainGreenRgba(0.6)
        : styles.borderColor,
    }
    };
  },
  //@ts-ignore
  multiValue: (styles: any, { data }: any) => {
    return {
      ...styles,
      backgroundColor: SublimeTheme.sublime.color.mainGreenRgba(0.8),
    };
  },
  //@ts-ignore
  multiValueLabel: (styles: any, { data }: any) => ({
    ...styles,
    color: 'white',
  }),
  //@ts-ignore
  multiValueRemove: (styles: any, { data }: any) => ({
    ...styles,
    color: SublimeTheme.sublime.color.mainGold,
    ':hover': {
      backgroundColor: SublimeTheme.sublime.color.mainRed,
      color: 'white',
    },
  }),
}