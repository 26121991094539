import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
// Components
import SignInForm from '../components/public/SignInForm';
import SignUpForm from '../components/public/SignUpForm';
import PrivateRouter from './PrivateRouter';
import ForgetPassword from '../components/public/ForgetPassword';
import { signOut } from '../store/actions/auth';
import { errorMessage } from '../store/actions/app';

export class Router extends Component {
  componentDidCatch(error, info) {
    const { signOut, errorMessage } = this.props;
    // @ts-ignore
    const env = process.env.NODE_ENV;
    if (env === 'development') {
      console.log('Error on render component', error, info);
    } else {
      errorMessage(info.componentStack);
      signOut();
    }
  }

  componentDidMount() {
    window.addEventListener('beforeunload', ev => {
      ev.preventDefault();
      const { auth, signOut } = this.props;
      if (!auth.rememberMe) {
        signOut();
      }
    });
  }

  render() {
    const { isConnected } = this.props.auth;
    return (
      <Switch>
        {/* All public routes here */}
        <Route exact path="/" component={SignInForm} />
        <Route exact path="/signup" component={SignUpForm} />
        <Route exact path="/forget-password" component={ForgetPassword} />
        {/* All routes for connected users here */}
        <PrivateRoute
          path="/dashboard"
          component={PrivateRouter}
          isConnected={isConnected}
        />
      </Switch>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = {
  signOut,
  errorMessage
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Router);
