export default async (url: string) => {
  try {
    const response = await fetch(url)
    const html = await response.text()
    //@ts-ignore
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    return doc;
  } catch (error) {
    console.log('Failed to fetch page: ', error);
    return undefined
  }
}