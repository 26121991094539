import React from "react";
import "./index.scss";

interface SublimeHeaderProps {
  title: string;
}

const App: React.FunctionComponent<SublimeHeaderProps> = ({ title }) => {
  return <h2 className="sublime-header">{title}</h2>;
};

export default App;
