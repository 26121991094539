import React from "react";
import "./index.scss";

interface IButtonProps {
  onClick: any;
  disabled?: boolean;
  label: string;
  labelColor?: string;
}

const Button: React.FunctionComponent<IButtonProps> = ({
  onClick,
  disabled,
  label,
  labelColor
}) => {
  function handleOnClick(event: React.MouseEvent<HTMLDivElement>) {
    onClick(event);
  }
  return (
    <div
      onClick={event => (disabled ? false : handleOnClick(event))}
      className={`sublime-button flex-center flex-center-items ${
        disabled ? "sublime-button-disabled" : ""
      }`}
    >
      <p style={{ color: labelColor }}>{label}</p>
    </div>
  );
};

export default Button;
