import React from 'react';
import { SublimeTheme, getImage, SublimeInfo } from 'exalt3d-sublime-ui';
import { FaBan } from 'react-icons/fa';
import { IoIosUnlock } from 'react-icons/io';
import AndOrToggle from './AndOrToggle';

import './tuto.scss';

const greenColor = SublimeTheme.sublime.color.mainGreen;
const redColor = SublimeTheme.sublime.color.mainRed;

export default function tuto() {
  return (
    <div className="full-width flex-row flex-center sublime-tuto boxed">
      <div className="sublime-tuto-content">
        <div className="tuto-line">
          <span className="flex-row flex-center-items full-width">
            <AndOrToggle id="and" arrow={false} value="AND" />
            <span className="marged-left">
            In these menus, multiple options can be selected
            </span>
            <SublimeInfo target="and" imgSrc={getImage('and-rule')} />
          </span>
        </div>
        <div className="tuto-line">
          <span className="flex-row flex-center-items full-width">
            <AndOrToggle id="or" arrow={false} value="OR" />
            <span className="marged-left">
            In these menus, only one option can be selected
            </span>
            <SublimeInfo target="or" imgSrc={getImage('or-rule')} />
          </span>
        </div>
        <div className="tuto-line marged-bottom">
          <span
            style={{ paddingLeft: '30px' }}
            className="flex-row flex-center-items full-width"
          >
            <FaBan id="forbidden" color={redColor} />
            <span style={{ paddingLeft: '45px' }}>
              Allows to select options forbidden with this menu
            </span>
            <SublimeInfo target="forbidden" imgSrc={getImage('forbidden')} />
          </span>
        </div>
        <div className="tuto-line marged-bottom">
          <span
            style={{ paddingLeft: '30px' }}
            className="flex-row flex-center-items full-width"
          >
            <IoIosUnlock id="mandatory" color={greenColor} size="18px" />
            <span style={{ paddingLeft: '45px' }}>
              Allows an option to be mandatory
            </span>
            <SublimeInfo target="mandatory" imgSrc={getImage('mandatory')} />
          </span>
        </div>
      </div>
    </div>
  );
}
