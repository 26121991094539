import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
  createStyles,
  makeStyles,
  createMuiTheme,
  useTheme,
  Theme,
  ThemeProvider
} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SublimeTheme from '../../../ui-kit/theme';
// @ts-ignore
import MenuDrawer from './MenuDrawer';
import { IStoreState, IUser, IAuth } from '../../../interfaces/store';
import ExaltLogo from '../../../assets/images/logos/logo-exalt3d-sublime.png';
//@ts-ignore
import Avatar from '../../shared/user/Avatar';
//@ts-ignore
import LogOut from '../../shared/logout/index';

const drawerWidth = 240;

const customTheme = createMuiTheme({
  overrides: {
    MuiDrawer: {
      paper: {
        background: 'rgba(36, 37, 41, 1)'
      }
    },
    MuiToolbar: {
      root: {
        background: SublimeTheme.sublime.color.mainBlack
      }
    },
    MuiListItem: {
      root: {
        '&$selected': {
          background: SublimeTheme.sublime.color.mainBlack,
          color: SublimeTheme.sublime.color.mainGold
        }
      }
    },
    MuiIconButton: {
      root: {
        color: 'white'
      }
    },
    MuiSvgIcon: {
      colorPrimary: {
        color: SublimeTheme.sublime.color.mainGold
      },
      colorSecondary: {
        color: 'white'
      }
    }
  }
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    menuButton: {
      marginRight: 15
    },
    hide: {
      display: 'none'
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap'
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1
      }
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar
    },
    content: {
      flexGrow: 1
    }
  })
);
interface ResponsiveDrawerProps {
  container?: Element;
  children: any;
  user: IUser;
  auth: IAuth;
  history: any;
  t: any;
}

function LeftMenu(props: ResponsiveDrawerProps) {
  const { children, user, history, t } = props;
  // const { children, user, history, t, auth } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={customTheme}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          style={{
            margin: 0
          }}
          position='fixed'
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open
          })}
        >
          <Toolbar>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={handleDrawerOpen}
              edge='start'
              className={clsx(classes.menuButton, {
                [classes.hide]: open
              })}
            >
              <MenuIcon />
            </IconButton>
            <div className='full-width flex-row flex-between'>
              <div className='flex-row flex-center-items'>
                <img width='60px' src={ExaltLogo} alt='Exalt3d logo' />
              </div>
              <div
                style={{ marginRight: open ? 0 : '40px' }}
                className='flex-row flex-center-items'
              >
                <div
                  className='offer-infos flex-center flex-center-items pointed marged-right'
                  role='link'
                  onClick={() => history.push('/dashboard/account/offers')}
                >
                  <p
                    style={{
                      color: SublimeTheme.sublime.color.offers[user.offer.name]
                    }}
                  >
                    {t('navbar:offer', { offer: user.offer.name })}
                  </p>
                </div>
                <div
                  className='pointed flex-column flex-center'
                  role='link'
                  onClick={() =>
                    props.history.push('/dashboard/account/profile')
                  }
                >
                  <Avatar
                    avatarUrl={user.avatar_url}
                    username={user.nickname}
                  />
                </div>
                <div className='full-height flex-row flex-center-items'>
                  <LogOut />
                </div>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          variant='permanent'
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })
          }}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          {
            //@ts-ignore
            <MenuDrawer isMenuOpen={open} />
          }
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {children}
        </main>
      </div>
    </ThemeProvider>
  );
}

const mapStateToProps = (state: IStoreState) => ({
  auth: state.auth,
  user: state.user
});

const mapDispatchToProps = {};

export default withRouter(
  // @ts-ignore
  withTranslation(['dashboard', 'navbar'])(
    // @ts-ignore
    connect(mapStateToProps, mapDispatchToProps)(LeftMenu)
  )
);
