import React from "react";
import "./Avatar.scss";
export default function Avatar({ avatarUrl, username }) {
  return (
    <img
      className="sublime-avatar"
      src={avatarUrl}
      alt={username}
      width="40"
      height="40"
    />
  );
}
