export const SIGN_IN = 'SIGN_IN_SUCCESS';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';

export const SET_USER_ROLE = 'SET_USER_ROLE_SUCCESS';
export const SET_USER_ROLE_ERROR = 'SET_USER_ROLE_ERROR';

export const SIGN_UP = 'SIGN_UP_SUCCESS';
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR';

export const SIGN_OUT = 'SIGN_OUT_SUCCESS';
export const SIGN_OUT_ERROR = 'SIGN_OUT_ERROR';