import React from "react";
import ImagesProcessLogs from "./ImagesProcessLogs";
import SublimeHeader from "../../shared/SublimeHeader";
import { withTranslation } from "react-i18next";

function IndexLog({ t }) {
  return (
    <div className="layout-margin">
      <SublimeHeader title={t("logs.title")} />
      <ImagesProcessLogs />
    </div>
  );
}

export default withTranslation("admin")(IndexLog);
