import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/bootstrap-overrides.scss';
import 'react-table/react-table.css';
import 'react-widgets/dist/css/react-widgets.css';
// Configurator-lib style
import './ui-kit/assets/styles/flexbox.scss';
import './index.scss';
import './assets/styles/shared.scss';
// Sortable-tree style
import './assets/styles/configurator-theme/index.scss';
import App from './App';
// import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store from './store';
import persistor from './store/persistStore';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
