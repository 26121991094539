import Axios from "axios";
import baseUrl from "../../api/url";
import {
  ERROR_MESSAGE,
  WARNING_MESSAGE,
  SUCCESS_MESSAGE,
  INFO_MESSAGE,
  CLEAR_MESSAGES,
  START_ANIMATION,
  CLEAR_ANIMATION
} from "../types/app";
import { buildTokenHeaders } from "./auth";
import i18n from "../../i18n";

export function successMessage(message) {
  return {
    type: SUCCESS_MESSAGE,
    payload: message
  };
}
export function infoMessage(message) {
  return {
    type: INFO_MESSAGE,
    payload: message
  };
}
export function warningMessage(message) {
  return {
    type: WARNING_MESSAGE,
    payload: message
  };
}
export function errorMessage(message) {
  return {
    type: ERROR_MESSAGE,
    payload: message
  };
}
export function clearMessages() {
  return {
    type: CLEAR_MESSAGES,
    payload: null
  };
}
export function startAnimations(animations) {
  /*
{
  type: 'START_ANIMATION',
  payload: {
    loader: 'Watch',
    color: '#fdfdfd',
    message: {
      body: 'Animation message here',
      color: '#dafefe'
    }
}
*/
  return {
    type: START_ANIMATION,
    payload: animations
  };
}
export function clearAnimation() {
  return {
    type: CLEAR_ANIMATION,
    payload: null
  };
}

export function sendSupportEmail(email, subject, message) {
  return async function(dispatch, getState) {
    function onSuccess(response) {
      dispatch(successMessage(i18n.t("support:contact.message.success")));
      return true;
    }
    function onError(error) {
      dispatch(errorMessage(i18n.t("support:contact.message.success")));
      return error.response;
    }

    try {
      const response = await Axios.post(
        baseUrl.contacts,
        { email, subject, message },
        {
          headers: {
            ...buildTokenHeaders()
          }
        }
      );
      return onSuccess(response);
    } catch (error) {
      return onError(error);
    }
  };
}
