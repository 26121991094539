import React from "react";
import { SublimeTheme } from 'exalt3d-sublime-ui';
import {Container} from 'reactstrap'
let steps = [
  { title: "Start your project" },
  { title: "Create configuration menus" },
  { title: "Create forbidden menus" },
  { title: "Upload files" }
];

const StepItem = ({ step, isActive, isPassed, isDisabled }) => {
  let backgroundColor = isActive
    ? SublimeTheme.sublime.color.mainGreen
    : SublimeTheme.sublime.color.mainGreenRgba(0.3);
  if (isPassed) backgroundColor = SublimeTheme.sublime.color.mainGreenRgba(0.5);
  if (isDisabled) backgroundColor = SublimeTheme.sublime.color.mainSilver;
  return (
    <div
      role="button"
      onClick={step.onClick}
      className={`flex-column flex-center-items ${
        isPassed && !isDisabled ? "pointed" : ""
      }`}
    >
      <div
        className="flex-row flex-center flex-center-items"
        style={{
          backgroundColor,
          color: "white",
          width: "50px",
          height: "50px",
          borderRadius: "25px"
        }}
      >
        <h3 style={{ margin: 0 }}>{step.id + 1}</h3>
      </div>
      <p className="marged-top text-center" style={{ color: SublimeTheme.sublime.color.mainGold, marginRight: '15px' }}>
        {step.title}
      </p>
    </div>
  );
};

export default function SublimeStepper({
  activeStep = 0,
  setStep,
  disabledSteps = []
}) {
  function stepActions(item, idx) {
    const isDisabled = disabledSteps.includes(idx);
    return {
      title: item.title,
      onClick: idx < activeStep && !isDisabled ? () => setStep(idx) : undefined
    };
  }
  steps = steps.map(stepActions);
  return (
    <Container className="flex-row flex-around marged-bottom">
      {steps.map((step, idx) => (
        <StepItem
          key={step.title}
          isPassed={activeStep > idx}
          isActive={activeStep === idx}
          isDisabled={disabledSteps.includes(idx)}
          step={{ ...step, id: idx }}
        />
      ))}
    </Container>
  );
}
