import {
  FETCH_USER,
  FETCH_USER_ERROR,
  FETCH_USERS,
  FETCH_USERS_ERROR
} from '../types/user';

const initialState = {
  id: null,
  firstname: '',
  lastname: '',
  email: '',
  company: '',
  country: '',
  avatar_url: 'https://png.pngtree.com/svg/20161212/f93e57629c.svg',
  first_connexion: true,
  offer: { name: 'free' },
  uploader_token: '',
  allUsers: []
};

export default (state = initialState, { type, payload }) => {
  switch (type) {

    case FETCH_USER:
      return { ...state, ...payload };

    case FETCH_USER_ERROR:
      return state;

    case FETCH_USERS:
      return { ...state, allUsers: [...payload] };

    case FETCH_USERS_ERROR:
      return state;

    default:
      return state;
  }
};
