// @ts-nocheck
import {
  FETCH_USER,
  FETCH_USER_ERROR,
  FETCH_USERS,
  FETCH_USERS_ERROR
} from "../types/user";
import { SUCCESS_MESSAGE, ERROR_MESSAGE } from "../types/app";
import baseUrl from "../../api/url";
import Axios from "axios";
import i18n from "../../i18n";
import { buildFormData } from "../../utils/form";
import { buildTokenHeaders } from "./auth";
import { getErrorMessagesFromBackend } from "../../utils/errors";
import { errorMessage, successMessage } from "./app";

export function fetchUser(userData) {
  return {
    type: FETCH_USER,
    payload: userData
  };
}

export function fetchUserError(error) {
  return {
    type: FETCH_USER_ERROR,
    payload: error
  };
}

export function fetchUserById(userId) {
  return async function(dispatch, getState) {
    function onSucces(repsonse) {
      dispatch(fetchUser(repsonse.data.user));
    }
    function onError(error) {
      dispatch(fetchUserError(error));
    }
    try {
      const reponse = await Axios.get(`${baseUrl.users}/${userId}`, {
        headers: { ...buildTokenHeaders() }
      });
      onSucces(reponse);
    } catch (err) {
      onError(err);
    }
  };
}

export function deleteUser(userId) {
  return async function(dispatch, getState) {
    function onSucces(repsonse) {
      dispatch(successMessage(i18n.t("admin:users.delete.success")));
    }
    function onError(error) {
      dispatch(errorMessage(i18n.t("admin:users.message.error")));
    }
    try {
      const reponse = await Axios.delete(`${baseUrl.users}/${userId}`, {
        headers: { ...buildTokenHeaders() }
      });
      onSucces(reponse);
    } catch (err) {
      onError(err);
    }
  };
}

export function fetchCurrentUser() {
  return function(dispatch, getState) {
    dispatch(fetchUserById(getState().user.id));
  };
}

export function updateUser(userData, userId, isCurrent = false) {
  return async function(dispatch, getState) {
    function onSucces(repsonse) {
      if (isCurrent) {
        dispatch(fetchUser(repsonse.data.user));
      }
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: i18n.store.data[i18n.language].account.profile.successMessage
      });
    }
    function onError(error) {
      dispatch(fetchUserError(error));
      dispatch({
        type: ERROR_MESSAGE,
        payload: getErrorMessagesFromBackend(error)
      });
    }
    try {
      let datas;
      if (userData.avatar) {
        datas = buildFormData(userData);
      } else {
        datas = userData;
      }
      const reponse = await Axios.put(`${baseUrl.users}/${userId}`, datas, {
        headers: { ...buildTokenHeaders() }
      });
      onSucces(reponse);
    } catch (err) {
      onError(err);
    }
  };
}

export function fetchUsers() {
  return async function(dispatch, getState) {
    function onSucces(response) {
      dispatch({ type: FETCH_USERS, payload: response.data.users });
    }
    function onError(error) {
      dispatch({ type: FETCH_USERS_ERROR, payload: error });
    }
    try {
      const reponse = await Axios.get(`${baseUrl.users}`, {
        headers: { ...buildTokenHeaders() }
      });
      onSucces(reponse);
    } catch (err) {
      onError(err);
    }
  };
}

export function fetchRoles() {
  return async function(dispatch, getState) {
    function onSucces(response) {
      return response;
    }
    function onError(error) {
      console.log("Error while fetching roles list", error);
    }
    try {
      const reponse = await Axios.get(`${baseUrl.roles}`, {
        headers: { ...buildTokenHeaders() }
      });
      return onSucces(reponse);
    } catch (err) {
      return onError(err);
    }
  };
}
