import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
// @ts-ignore
import Switch from 'react-switch';
import './AndOrToggle.scss';
// @ts-ignore
import { SublimeTheme } from 'exalt3d-sublime-ui';

function createElement(value) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        fontSize: 15,
        color: 'white',
        paddingRight: 2
      }}
    >
      {value}
    </div>
  );
}

export default function AndOrToggle({
  value = '',
  onPress = () => {},
  level = 0,
  arrow = true,
  id = undefined
}) {
  // const color = SublimeTheme.sublime.color.forbidden[`level${level}`];
  const offColor = SublimeTheme.sublime.color.mainGreen;
  const onColor = SublimeTheme.sublime.color.mainRed;
  const isChecked = value === 'AND';

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <div id={id} className="sublime-and-or-container">
      <span data-tip="Be careful! This action will remove forbidden and mandatory">
        <Switch
          checked={isChecked}
          onChange={onPress}
          uncheckedIcon={createElement('OR')}
          offColor={onColor}
          checkedIcon={createElement('AND')}
          onColor={offColor}
          className="sublime-and-or"
          boxShadow={`5px 2.5px 2.5px ${isChecked ? offColor : onColor}`}
          width={80}
        />
      </span>
      {arrow && (
        <div
          style={{ borderTopColor: `${isChecked ? offColor : onColor}` }}
          className="bottom-arrow"
        />
      )}
    </div>
  );
}