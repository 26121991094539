import React from 'react';
import { SublimeTheme } from 'exalt3d-sublime-ui';
import { Badge } from 'reactstrap';
import {
  findMenuById,
  getTitleTree
} from '../../../../utils/configurator/menu';
import { toggleForbiddenMenu } from '../../../../utils/configurator/restriction';

export default function Forbbidens({
  context = {},
  item = {},
  treeData = [],
  hierarchy = false
}) {
  return item.node.data.forbids.map(forbiddenMenuId => {
    const forbiddenMenu = findMenuById(treeData, forbiddenMenuId);
    if (forbiddenMenu) {
      return (
        <Badge
          style={{
            width: 'auto',
            backgroundColor:
              SublimeTheme.sublime.color.forbidden[
                `level${forbiddenMenu.path.length - 1}`
              ]
          }}
          className='container-elipsed-text pointed marged-bottom marged-right'
          onClick={() => toggleForbiddenMenu(item, forbiddenMenu, context)}
          key={`forb-${forbiddenMenu.node.id}`}
        >
          {hierarchy
            ? getTitleTree(treeData, forbiddenMenu)
            : forbiddenMenu.node.data.title}
        </Badge>
      );
    }
    return null;
  });
}
