import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { SublimeTheme } from 'exalt3d-sublime-ui';
import {
  allPossibleMenuCombinaison,
  clearAllForbidden
} from '../../../utils/configurator/restriction';
import {
  generateItem,
  updateItem,
  findMenuById,
  getTitleTree
} from '../../../utils/configurator/menu';
import { updateConfigField } from '../../../store/actions/project';
import './step3/style.scss';
import AndOrToggle from './step3/AndOrToggle';
import AvailableConfigurations from './step3/AvailableConfigurations';
import ForbiddensModal from './step3/ForbiddensModal';
import MenuItem from './step3/MenuItem';
import { autoWidthElements } from '../../../utils/auto-sizer';
import Tuto from './step3/tuto';
import { isOnMobile } from '../../../utils/screen';

const greenColor = SublimeTheme.sublime.color.mainGreen;
const redColor = SublimeTheme.sublime.color.mainRed;

const CustomContainer = ({ children, id }) => {
  if (isOnMobile()) {
    return <>{children}</>;
  }
  return <Row id={id}>{children}</Row>;
};

export default class SublimeConfStep3 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      treeData: [],
      activeForbbiden: false,
      availableForbidden: [],
      currentItem: { node: { title: 'NOT SELECTED' } },
      menus: []
    };
  }

  async componentDidMount() {
    const { store } = this.props;
    const { menu } = store.getState().projects.project;
    await this.setState({ treeData: menu });
    await this.fetchMenus();
    autoWidthElements('sublime-list', 350);
  }

  toggleAndOr = async item => {
    // function to toggle AND/OR rules and update menu item
    const { treeData } = this.state;
    const { store } = this.props;
    const { top_level_rule } = store.getState().projects.project;
    const childrenRule = item.node.data.childrenRule === 'OR' ? 'AND' : 'OR';
    await clearAllForbidden(store, treeData);
    let parentRule = '';
    if (item.path.length > 1) {
      const parentItem = findMenuById(
        treeData,
        item.path[item.path.length - 2]
      );
      parentRule = parentItem.node.data.childrenRule;
    } else {
      parentRule = top_level_rule;
    }
    const newValue = generateItem({
      ...item.node.data,
      childrenRule,
      parentRule
    });
    await updateItem(item, newValue, this);
    await this.updateChildrenRule(item, { parentRule: childrenRule });
    this.fetchMenus();
  };

  // It is necessary to update the children because otherwise the last level is never reached
  updateChildrenRule = (item, opt) => {
    const { treeData } = this.state;
    item.node.children.forEach(async child => {
      const newChildValue = generateItem({ ...child.data, ...opt });
      const element = findMenuById(treeData, child.id);
      await updateItem(element, newChildValue, this);
    });
  };

  save = () => {
    const { treeData } = this.state;
    const { store } = this.props;
    store.dispatch(updateConfigField(store, { menu: treeData }));
  };

  topLevelAndOrButton = () => {
    const { treeData } = this.state;
    const { store } = this.props;
    const { project } = store.getState().projects;

    const buttonValue = project.top_level_rule;
    const top_level_rule = buttonValue === 'AND' ? 'OR' : 'AND';
    if (project.menu.length <= 1) {
      return null;
    }
    return (
      <AndOrToggle
        level={0}
        onPress={async () => {
          await store.dispatch(updateConfigField(store, { top_level_rule }));
          await clearAllForbidden(store, treeData);
          this.fetchMenus();
        }}
        value={buttonValue}
      />
    );
  };

  submitForm = async () => {
    const { treeData, menus } = this.state;
    const { submit, store } = this.props;
    const { projects } = store.getState();
    const { project } = projects;
    const { named_nomenclatures } = project;
    const formatedCombs = {};
    menus.forEach(comb => {
      const joinedNomenclature = comb.map(i => i.nomenclature).join('&');
      formatedCombs[joinedNomenclature] = {
        ...named_nomenclatures[joinedNomenclature],
        name: comb
          .map(i => getTitleTree(treeData, findMenuById(treeData, i.node.id)))
          .join('->'),
        defaultPosition: named_nomenclatures[joinedNomenclature]
          ? named_nomenclatures[joinedNomenclature].defaultPosition
          : { x: 1, y: 1 },
        defaultImageUrl: named_nomenclatures[joinedNomenclature]
          ? named_nomenclatures[joinedNomenclature].defaultImageUrl
          : null
      };
    });
    /*
      Example of generated object
      {
        1: {
          name: 'Menu title',
          defaultPosition: {x: 1, y: 8},
          defaultImageUrl: https://www.images.com/blue.jpg
        },
        1_1: {
          name: 'Menu title->Menu title / Child menu',
          defaultPosition: {x: 1, y: 1},
          defaultImageUrl: null
        },
      }
    */
    await submit({
      menu: treeData,
      configurations: [...menus],
      named_nomenclatures: JSON.stringify(formatedCombs)
    });
  };

  fetchMenus() {
    const { store } = this.props;
    const { treeData } = this.state;
    allPossibleMenuCombinaison(store, treeData)
      .then(menus => this.setState({ menus: [...menus] }))
      .catch(err => console.log('Error while fetching menu combinaisons', err));
  }

  render() {
    const {
      treeData,
      activeForbbiden,
      currentItem,
      availableForbidden,
      menus
    } = this.state;
    const { store, prevStep } = this.props;
    const { project } = store.getState().projects;
    const isTopLevelInAnd = project.top_level_rule === 'AND';
    const menusCount = menus.length;
    return (
      <CustomContainer id='sublime-combinaisons-creator'>
        <Col xs={12} md={12} className='marged-top'>
          <Row>
            <Col xs={12}>
              <Tuto />
            </Col>
          </Row>
          <Row>
            <Col className='marged-bottom' xs={12} md={7}>
              <div className='sublime-tree-locked-container boxed'>
                <div
                  className='sublime-tree'
                  style={{
                    borderColor: isTopLevelInAnd ? greenColor : redColor
                  }}
                >
                  <div className='sublime-tree-root' />
                  {this.topLevelAndOrButton()}
                  {treeData.map(item => (
                    <MenuItem
                      key={item.id}
                      item={item}
                      level={0}
                      context={this}
                      topLevelRule={project.top_level_rule}
                      menusCount={menusCount}
                    />
                  ))}
                </div>
              </div>
            </Col>
            <Col className='marged-bottom' xs={12} md={5}>
              <AvailableConfigurations
                named
                projectId={project.id}
                treeData={treeData}
                menus={menus}
              />
            </Col>
          </Row>
          <Row className='marged-bottom'>
            <Col xs={12}>
              <div
                className={`marged-top flex-row ${
                  isOnMobile() ? 'flex-between' : 'flex-end'
                }`}
              >
                <div className='marged-right'>
                  <Button onClick={prevStep}>Prev step</Button>
                </div>
                <div className='marged-right'>
                  <Button color='primary' onClick={this.submitForm}>
                    Next step
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <ForbiddensModal
          currentItem={currentItem}
          treeData={treeData}
          availableForbidden={availableForbidden}
          isActive={activeForbbiden}
          toggleModal={() => this.setState({ activeForbbiden: false })}
          context={this}
        />
      </CustomContainer>
    );
  }
}
