import {
  FETCH_STATS,
  FETCH_STATS_ERROR
} from '../types/stats';

const initialState = {
  current_offer: {},
  projects: [], // [{16: {used_disk_space: 6032146}}]
  projects_total: 0,
  projects_with_uploads: 0,
  total_disk_space: 0,
  uploads_count: 0,
  used_files_type: {
    'image/jpeg': 0,
    'image/png': 0,
    'image/webp': 0
  }
};

export default (state = initialState, { type, payload }) => {
  switch (type) {

    case FETCH_STATS:
      return { ...state, ...payload };

    case FETCH_STATS_ERROR:
      return state;

    default:
      return state;
  }
};