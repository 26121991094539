import React, { Suspense } from 'react';
import Router from './routes/Router';
import './App.scss';
import './i18n';
import AlertBar from './components/shared/messages/AlertBar';
import Animations from './components/shared/anims/index';

export default function App() {
  return (
    <Suspense fallback='loading'>
      <div id='sublime-app'>
        <AlertBar />
        <Animations />
        <Router />
      </div>
    </Suspense>
  );
}
