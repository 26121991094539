// @ts-nocheck
import Axios from 'axios';
import baseUrl from '../../api/url';
import {
  FETCH_STATS,
  FETCH_STATS_ERROR
} from '../types/stats';
import { buildTokenHeaders } from './auth';

export function fetchStats() {
  return async function (dispatch, getState) {
    function onSuccess(response) {
      return response.data;
    }
    function onError(error) {
      dispatch({ type: FETCH_STATS_ERROR, payload: error });
    }

    try {
      const response = await Axios.get(`${baseUrl.stats}`, {
        headers: {
          ...buildTokenHeaders()
        }
      });
      return onSuccess(response);
    } catch (error) {
      return onError(error);
    }
  };
}

export function fetchStat(userId = null) {
  return async function (dispatch, getState) {
    function onSuccess(response) {
      dispatch({ type: FETCH_STATS, payload: response.data.all });
      return response;
    }
    function onError(error) {
      dispatch({ type: FETCH_STATS_ERROR, payload: error });
      return error.response;
    }

    try {
      const { id } = getState().user;
      const response = await Axios.get(`${baseUrl.stats}/${userId ? userId : id}`, {
        headers: {
          ...buildTokenHeaders()
        }
      });
      return onSuccess(response);
    } catch (error) {
      return onError(error);
    }
  };
}

export function secureFetchAppStats() {
  return async function(dispatch, getState) {
    function onSuccess(response) {
      return response;
    }
    function onError(error) {
      console.log("error while fetching stats", error);
      return error;
    }

    try {
      const response = await Axios.get(baseUrl.appStats, {
        headers: { ...buildTokenHeaders() }
      });
      return onSuccess(response);
    } catch (error) {
      throw onError(error);
    }
  };
};

export function secureFetchNodeAppStats() {
  return async function(dispatch, getState) {
    function onSuccess(response) {
      return response;
    }
    function onError(error) {
      console.log("error while fetching node.js stats", error);
      throw error;
    }

    try {
      const response = await Axios.get(baseUrl.uploaderSystemStats, {
        headers: { Authorization: getState().user.uploader_token }
      });
      return onSuccess(response);
    } catch (error) {
      return onError(error);
    }
  };
};