import React, { useState, useEffect } from 'react';
import { SublimeTheme } from 'exalt3d-sublime-ui';
import { FaBan } from 'react-icons/fa';
import { IoIosUnlock } from 'react-icons/io';
import ReactTooltip from 'react-tooltip';
import {
  findMenuById,
  generateItem,
  updateItem
} from '../../../../utils/configurator/menu';
import {
  allPossibleMenuCombinaison,
  menusCanBeProhibited
} from '../../../../utils/configurator/restriction';
import AndOrToggle from './AndOrToggle';

const greenColor = SublimeTheme.sublime.color.mainGreen;
const redColor = SublimeTheme.sublime.color.mainRed;
const disableColor = SublimeTheme.sublime.color.mainSilver;
let forbiddenRef;

function buildAndOrButton(item, context) {
  const { state } = context;
  const { treeData } = state;
  const element = findMenuById(treeData, item.id);
  const buttonValue = element.node.data.childrenRule;
  return (
    <AndOrToggle
      level={element.path.length}
      onPress={() => context.toggleAndOr(element)}
      value={buttonValue}
    />
  );
}

function buildTree(trees, parent, level, menusCount, context) {
  const { state } = context;
  const { treeData } = state;
  const isParentInAnd = parent.data.childrenRule === 'AND';
  return (
    <div
      className='sublime-tree'
      style={{ borderColor: isParentInAnd ? greenColor : redColor }}
    >
      <div className='sublime-tree-root' />
      {buildAndOrButton(parent, context)}
      {trees.map(item => {
        const formatedItem = findMenuById(treeData, item.id);
        return (
          <MenuItem
            key={item.id}
            item={item}
            menusCount={menusCount}
            level={level || formatedItem.path.length - 1}
            context={context}
          />
        );
      })}
    </div>
  );
}

export default function MenuItem({
  item,
  context,
  level,
  menusCount,
  topLevelRule = undefined
}) {
  const { state } = context;
  const { treeData } = state;
  const hasChilds = item.children && item.children.length >= 1;
  let isInAnd = item.data.parentRule === 'AND';
  if (topLevelRule) {
    isInAnd = topLevelRule === 'AND';
  }
  const color = isInAnd ? greenColor : redColor;
  const formatedItem = findMenuById(treeData, item.id);
  const [possibleForbiddenMenus, setPossibleForbiddenMenus] = useState([]);

  const ForbiddenButton = () => {
    if (possibleForbiddenMenus.length < 1) return null;
    return (
      <FaBan
        // ref={ref => (forbiddenRef = ref)}
        data-tip='Forbidden menus'
        color={
          formatedItem.node.data.forbids.length >= 1 ? redColor : greenColor
        }
        onClick={() => {
          ReactTooltip.hide(forbiddenRef);
          context.setState({
            availableForbidden: [...possibleForbiddenMenus],
            currentItem: formatedItem,
            activeForbbiden: true
          });
        }}
      />
    );
  };

  const MandatoryButton = () => {
    const { isMandatory } = formatedItem.node.data;
    const onClick = async () => {
      const newValue = generateItem({
        ...formatedItem.node.data,
        isMandatory: !isMandatory
      });
      await updateItem(formatedItem, newValue, context);
      allPossibleMenuCombinaison(context.props.store, context.state.treeData)
        .then(menus => {
          context.setState({ menus: [...menus] });
        })
        .catch(err =>
          console.log('Error while fetching menu combinaisons', err)
        );
    };
    return (
      <IoIosUnlock
        size='18px'
        onClick={onClick}
        color={isMandatory ? greenColor : disableColor}
        data-tip={isMandatory ? 'Disable mandatory' : 'Enable mandatory'}
      />
    );
  };

  useEffect(() => {
    if (formatedItem && !hasChilds) {
      menusCanBeProhibited(context.props.store, treeData, formatedItem).then(
        result => {
          setPossibleForbiddenMenus([...result]);
          ReactTooltip.rebuild();
        }
      );
    }
  }, [menusCount]);

  return (
    <>
      <ReactTooltip />
      <div
        className={`flex-row flex-center-items sublime-list ${
          hasChilds ? '' : 'leaf'
        }`}
      >
        <span style={{ backgroundColor: color }} className='left-list-line' />
        <span
          style={{
            borderColor: color,
            boxShadow: `box-shadow: 0 2px 2px -2px ${color}`
          }}
          className='flex-row flex-between sublime-list-item'
        >
          <span>
            <b>{item.data.title}</b>
            {item.data.subtitle && item.data.subtitle.length >= 1 && (
              <p>{item.data.subtitle}</p>
            )}
          </span>
          <span className='sublime-list-actions flex-row flex-center-items marged-left pointed'>
            {hasChilds ? <MandatoryButton /> : <ForbiddenButton />}
          </span>
        </span>
      </div>
      {hasChilds &&
        buildTree(item.children, item, level + 1, menusCount, context)}
    </>
  );
}
