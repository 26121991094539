import React from "react";
import Loader from "react-loader-spinner";
import { connect } from "react-redux";
import * as appActions from "../../../store/actions/app";
import { Container } from "reactstrap";

const animationDuration = 7000;

function Animations({ animation, clearAnimation }) {
  if (!animation) {
    return null;
  }
  const textColor = animation.message.color;
  setTimeout(() => {
    clearAnimation();
  }, animationDuration);
  if (window) {
    //@ts-ignore
    window.scrollTo(0, 0);
  }
  return (
    <div
      className="flex-column flex-center flex-center-items"
      style={{
        position: "absolute",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        width: "100vw",
        height: "100vh",
        zIndex: 1,
        color: textColor
      }}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: "<style>body {position: fixed;}</style>"
        }}
      />
      <Loader
        type={animation.loader}
        color={animation.color}
        height={"15vw"}
        width={"15vw"}
      />
      <Container className="flex-row flex-center-items marged-top">
        <h2 style={{ margin: "0 15px 0 0", textAlign: "center" }}>
          {animation.message.body}
          <span style={{ marginTop: "20px" }}>
            <Loader type="ThreeDots" color={textColor} height={30} width={30} />
          </span>
        </h2>
      </Container>
    </div>
  );
}

const mapStateToProps = state => ({
  animation: state.app.animation
});

const mapDispatchToProps = {
  clearAnimation: appActions.clearAnimation
};

export default connect(mapStateToProps, mapDispatchToProps)(Animations);
