import React, { useState } from "react";
import { Label, CustomInput } from "reactstrap";
import "./index.scss";

interface ICheckBoxProps {
  onChange: any;
  value: boolean;
  label: string;
  id?: string | number;
}

const CheckBox: React.FunctionComponent<ICheckBoxProps> = ({
  onChange,
  value = false,
  label = "",
  id,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState(value);

  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    setInputValue(event.target.checked);
    onChange(event);
  }
  const cbId = `sublime-checkbox ${id ? id : ""}`;
  return (
    <div className="sublime-checkbox-group pointed">
      <CustomInput
        {...rest}
        label={""}
        id={cbId}
        onChange={handleOnChange}
        checked={inputValue}
        type="checkbox"
        color="primary"
      />
      <Label htmlFor={cbId}>{label}</Label>
    </div>
  );
};

export default CheckBox;
