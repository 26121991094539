import * as React from 'react';
//@ts-ignore
import { FormGroup, Label } from 'reactstrap';
import { IDomain } from '../../../../interfaces/project';
import { IStoreState } from '../../../../interfaces/store';
import CreatableSelect from 'react-select/creatable';
import domainSelectStyle from './domainSelectStyle';

interface IAppProps {
  domains: Array<IDomain>;
  onChange: any;
  store: IStoreState;
}

const App: React.FunctionComponent<IAppProps> = ({ domains, onChange }) => {
  const handleChange = (newValues: any, actionType: any) => {
    console.log('Action Handle change', { actionType, newValues });

    if (actionType.action === 'remove-value') {
      let newDomains = domains;
      const selectedIndex = domains
        .map(domain => domain.id)
        .indexOf(actionType.removedValue.value);
      if (actionType.removedValue.__isNew__) {
        newDomains = newDomains.filter(
          domain => domain.name !== actionType.removedValue.name
        );
        return onChange(newDomains);
      }
      if (selectedIndex !== -1) {
        newDomains = newDomains.map((domain, idx) =>
          idx === selectedIndex ? { ...domain, _destroy: true } : domain
        );
        return onChange(newDomains);
      }
    }
    if (actionType.action === 'create-option') {
      return onChange(
        newValues.map((domain: any) => ({
          ...domain,
          name: domain.label,
          id: Number.isInteger(domain.value) ? domain.value : null
        }))
      );
    }
    if (actionType.action === 'select-option') {
      return onChange(newValues);
    }
  };

  const handleInputChange = (inputValue: any, actionType: any) => {
    console.log('Action Input change', { actionType, inputValue });
  };
  const formatedDomains = domains.map(domain => ({
    ...domain,
    label: domain.name,
    value: domain.id
  }));
  const filteredDomains = formatedDomains.filter(
    domain => domain._destroy !== true
  );
  return (
    <>
      <FormGroup>
        <Label for='domain'>Domain name </Label>
        <CreatableSelect
          styles={domainSelectStyle}
          isMulti
          onChange={handleChange}
          onInputChange={handleInputChange}
          isClearable
          value={filteredDomains}
          options={formatedDomains}
        />
      </FormGroup>
    </>
  );
};

export default App;
