import Axios from "axios";
import { getUploaderToken } from "../sessions";
import url from "../api.ts";

const destroyAllUploadsConfigsForProject = projectId => {
  const uploadToken = getUploaderToken();
  return Axios.delete(url.uploaderUrl.dropConfigs, {
    headers: { Authorization: uploadToken },
    data: { sublimeProjectId: projectId }
  });
};

export default destroyAllUploadsConfigsForProject;
