import {
  FETCH_POSTS,
  FETCH_POSTS_ERROR
} from '../types/post';

const initialState = [];

export default (state = initialState, { type, payload }) => {
  switch (type) {

    case FETCH_POSTS:
      return [...payload];

    case FETCH_POSTS_ERROR:
      return state;

    default:
      return state;
  }
};
