import * as React from "react";
import { Toast, ToastHeader, ToastBody, Modal, ModalBody } from "reactstrap";
import { Line } from "react-chartjs-2";
import SublimeHeader from "../../../../shared/SublimeHeader";
import { IStatistic, IStatProject } from "../../../../../interfaces/statistics";
import { IOffer } from "../../../../../interfaces/offer";
import {
  buildPlayerRemainingTime,
  buildPlayerSessionsRemainingTime,
  buildPlayerSessionsByDomain
  //@ts-ignore
} from "../../../../../utils/stats";

interface IPlayersRemainingTimeProps {
  stats: IStatistic;
  currentOffer: IOffer;
}

const PlayersRemainingTime: React.FunctionComponent<IPlayersRemainingTimeProps> = ({
  stats
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [currentProject, setCurrentProject] = React.useState<
    IStatProject[string]
  >();
  const loadModal = (index: number) => {
    const projectId = Object.keys(stats.projects)[index];
    const project: IStatProject[string] = stats.projects[projectId];
    setCurrentProject({ ...project });
    setIsOpen(true);
  };
  const closeModal = () => {
    setCurrentProject(undefined);
    setIsOpen(false);
  };
  return (
    <Toast>
      <ToastHeader>
        <SublimeHeader title="Players times remaining" />
      </ToastHeader>
      <ToastBody>
        <div className="flex-column flex-center flex-center-items">
          <Line
            onElementsClick={chartElements => {
              if (chartElements && chartElements[0]) {
                loadModal(chartElements[0]._index);
              }
            }}
            data={buildPlayerRemainingTime(stats.projects)}
          />
        </div>
      </ToastBody>
      <Modal centered toggle={closeModal} isOpen={isOpen}>
        <ModalBody>
          {currentProject && (
            <div>
              <Line
                data={buildPlayerSessionsRemainingTime(
                  currentProject.player_usage.sessions
                )}
              />
              <Line
                data={buildPlayerSessionsByDomain(
                  currentProject.player_usage.sessions_by_domain
                )}
              />
            </div>
          )}
        </ModalBody>
      </Modal>
    </Toast>
  );
};

export default PlayersRemainingTime;
