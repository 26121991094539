import fileExtension from "file-extension";

export const acceptTypes = type => {
  switch (type) {
    case "image":
      return "image/png, image/jpeg, image/jpg, image/webp";
    case "file":
      return "image/png, image/jpeg, image/jpg, image/webp, application/pdf";
    default:
      return "image/png, image/jpeg, image/jpg, image/webp";
  }
};

export const isAnImage = fileName =>
  ["png", "jpg", "jpeg", "gif", "webp"].includes(fileExtension(fileName));
