export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export function getPositionFromGrid(xLength, yLength, index, image) {
  if (/\d+[x]\d+\b/.test(image.fileName)) {
    const positions = image.fileName.match(/\d+[x]\d+\b/)[0].split('x');
    return {
      x: parseInt(positions[0]),
      y: parseInt(positions[1])
    };
  }
  return getPositionFromBadNamed(xLength, yLength, index);
}

export function getPositionFromBadNamed(xLength, yLength, index) {
  const postions = []
  for(let y = 1; y <= yLength; y++){
    for(let x = 1; x <= xLength; x++){
      postions.push({x, y})
    }
  }
  return postions[index];
}

// export function getPositionFromBadNamed(xLength, index) {
//   const y = Math.trunc((index + 1) / xLength) + 1;
//   const x = index + 1 - (y - 1) * xLength;
//   return { x, y };
// }
