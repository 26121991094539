// @ts-nocheck
import Axios from "axios";
import {
  UPDATE_CONFIGURATOR_FIELD,
  LOGIN_USER,
  LOGOUT_USER,
  CLEAR_PROJECT_DATA
} from "../types/project";
import {
  PROJECT_ERROR_INITIALIAZE,
  PROJECT_ERROR_ON_UPDATE
} from "../types/projectErrors";
import { errorCatcher } from "./errors";
import url from "../../utils/api.ts";
import { setUploaderToken, setProjectId } from "../../utils/sessions";

export function initializeProject(store) {
  // Initialize project with an id
  return async function() {
    function onSuccess(response) {
      const { id, disk_name, user_id } = response.data.project;
      setProjectId(id, disk_name, user_id);
      store.dispatch({
        type: UPDATE_CONFIGURATOR_FIELD,
        payload: { ...response.data.project }
      });
      return true;
    }
    function onError(error) {
      store.dispatch(errorCatcher(error, PROJECT_ERROR_INITIALIAZE));
      return false;
    }

    try {
      const response = await Axios.post(
        url.apiUrl.projects,
        {},
        { headers: url.buildAuthHeaders(store) }
      );
      onSuccess(response);
    } catch (err) {
      onError(err);
    }
  };
}

export function editProject(store, projectId, context) {
  // Get project data by id and set to redux store
  return async function() {
    function onSuccess(response) {
      const { disk_name, user_id } = response.data.project;
      setProjectId(projectId, disk_name, user_id);
      store.dispatch({
        type: UPDATE_CONFIGURATOR_FIELD,
        payload: { ...response.data.project }
      });
    }
    function onError(error) {
      if (error.response && error.response.status === 401) {
        // user try to edit a project that does not belong to him
        store.dispatch({
          type: "ERROR_MESSAGE",
          payload:
            "You are trying to modify a project that does not belong to you"
        });
        setTimeout(function() {
          window.location = "/";
        }, 1000);
      }
      store.dispatch(errorCatcher(error, PROJECT_ERROR_INITIALIAZE));
      return false;
    }

    try {
      const response = await Axios.get(`${url.apiUrl.projects}/${projectId}`, {
        headers: url.buildAuthHeaders(store)
      });
      onSuccess(response);
    } catch (err) {
      onError(err);
    }
  };
}

export function updateConfigField(store, fieldValue) {
  //  example : { description: 'value' }
  return async function() {
    function onSuccess(response) {
      store.dispatch({
        type: UPDATE_CONFIGURATOR_FIELD,
        payload: { ...response.data.project }
      });
      if (fieldValue.final_put) {
        store.dispatch({
          type: "SUCCESS_MESSAGE",
          payload:
            "Thank you for creating this project ! Your code is cooking, please check your mailbox ! "
        });
      }
      return { response, success: true };
    }
    function onError(error) {
      store.dispatch(errorCatcher(error, PROJECT_ERROR_ON_UPDATE));
      return { response: error.response, success: false };
    }

    try {
      const authState = store.getState().auth;
      const id = fieldValue.id || store.getState().projects.project.id;
      const { client, uid } = authState;
      const headers = {
        "access-token": authState["access-token"],
        client,
        uid
      };
      fieldValue.domains_attributes = fieldValue.domains;
      if (fieldValue.menu) {
        fieldValue.menu = JSON.stringify(fieldValue.menu);
      }
      if (fieldValue.configurations) {
        fieldValue.configurations = JSON.stringify(fieldValue.configurations);
      }
      if (fieldValue.status) {
        // used to auto change status on edition (backend)
        delete fieldValue.status;
      }
      const response = await Axios.put(
        `${url.apiUrl.projects}/${id}`,
        fieldValue,
        { headers }
      );
      return onSuccess(response);
    } catch (err) {
      return onError(err);
    }
  };
}

export function login(tokens, uploaderToken) {
  setUploaderToken(uploaderToken); // use localStorage because cant connect component inside library
  return {
    type: LOGIN_USER,
    payload: {
      tokens,
      uploaderToken
    }
  };
}

export function logout() {
  return {
    type: LOGOUT_USER,
    payload: null
  };
}

export function clearProject() {
  return {
    type: CLEAR_PROJECT_DATA,
    payload: null
  };
}
