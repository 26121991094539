import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { formatFileSize } from '../../../utils/bytes';
//@ts-ignore
import { FaTrash } from 'react-icons/fa';
//@ts-ignore
import { IoIosAddCircleOutline } from 'react-icons/io';
import { SublimeTheme } from 'exalt3d-sublime-ui';
//@ts-ignore
import { Spinner } from 'reactstrap';

import './uploader.scss';
import { uploadAttachmentFile } from '../../../utils/uploader/attachments';
import { IUpload } from '../../../interfaces/project';
import { acceptTypes } from '../../../utils/uploader/file';
import { deleteUpload } from '../../../utils/uploader/configurationsUploader';

export interface FileWithPath extends File {
  readonly path?: string;
}

interface IAppProps {
  onUpload: any;
  onDelete: any;
  allreadyUploaded?: Array<any>;
  showPreview?: boolean;
  type: 'image' | 'file';
  limit: number;
}

const App: React.FunctionComponent<IAppProps> = ({
  onUpload,
  onDelete,
  allreadyUploaded = [],
  showPreview,
  type,
  limit = 1
}) => {
  const [loading, setloading] = React.useState(false);
  const onDrop = (acceptedFiles: Array<FileWithPath>) => {
    acceptedFiles.forEach((file: File) => {
      if (allreadyUploaded.length <= limit) {
        setloading(true);
        uploadAttachmentFile(file)
          .then(response => {
            onUpload(response);
            setloading(false);
          })
          .catch(error => {
            onUpload(error);
            setloading(false);
          });
      }
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptTypes(type),
    multiple: limit > 1
  });

  const allreadyUploadedFiles = allreadyUploaded.map((file: IUpload) => (
    <li
      className='sublime-uploader-item full-width flex-row flex-between flex-center-items'
      key={file.fileName}
    >
      {showPreview && (
        <span>
          <img width='100px' src={file.url} alt={file.fileName} />
        </span>
      )}
      <span>
        {file.fileName} - {formatFileSize(file.fileSize, 2)}
      </span>
      <span
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          deleteUpload(file.id)
            .then(onDelete)
            .catch(onDelete);
        }}
        role='button'
        className='sublime-uploader-delete pointed'
      >
        <FaTrash size='14px' color={SublimeTheme.sublime.color.mainRed} />
      </span>
    </li>
  ));
  return (
    <div
      style={{
        maxHeight: '150px',
        overflow: 'scroll'
      }}
      className={`sublime-uploader pointed flex-column ${
        allreadyUploaded.length < 1 ? 'flex-end' : 'flex-center'
      }`}
      {...getRootProps()}
    >
      {loading && (
        <div className='uploads-loader'>
          <Spinner type='grow' color='success' size='xs' />
        </div>
      )}
      <input {...getInputProps()} />
      {allreadyUploaded.length < 1 && !loading && (
        <div className='flex-column flex-center-items flex-center'>
          <IoIosAddCircleOutline
            size={60}
            color={SublimeTheme.sublime.color.mainGreen}
          />
          <p className='upload-info-text'>
            Drag 'n' drop some files here, or click to select files
          </p>
        </div>
      )}
      <div className='sublime-uploader-content'>
        <aside>
          <ul>{allreadyUploadedFiles}</ul>
        </aside>
      </div>
    </div>
  );
};

export default App;
