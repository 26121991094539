import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import SublimeTheme from '../../../ui-kit/theme';
import Separator from '../../../ui-kit/Separator/index.tsx';
import { fetchLogsFilesNames, fetchLogs } from '../../../store/actions/logs';
import ImagesProcessLogModal from './ImagesProcessLogModal';
import ProcessStatus from './ProcessStatus';

import './ImagesProcessLogs.scss';

function ImagesProcessLogs({ fetchSharedLogs, fetchLogs, t }) {
  const [files, setFiles] = useState({
    pending: [],
    success: [],
    errors: [],
    isRunning: false
  });
  const [selectedFile, setSelectedFile] = useState({ file: null, type: null });
  useEffect(() => {
    fetchLogs()
      .then(response => {
        setFiles({ ...response.data.files });
      })
      .catch(error => {
        console.log(error);
      });
  }, [fetchLogs]);

  const LineItem = ({ file, type }) => {
    return (
      <div className='sublime-log-line'>
        <span
          onClick={() => setSelectedFile({ file, type })}
          className='pointed'
        >
          {file}
        </span>
      </div>
    );
  };

  return (
    <div className='layout-margin'>
      <Row>
        <Col>
          <span
            className='pointed'
            onClick={() => {
              fetchSharedLogs()
                .then(response => {
                  const newTabs = window.open();
                  newTabs.document.body.append(response.data);
                })
                .catch(error => {
                  console.log(error);
                });
            }}
          >
            <ProcessStatus isRunning={files.isRunning} />
            <span style={{ fontSize: '10px' }}>{t('logs.seeAll')}</span>
          </span>
        </Col>
      </Row>
      <Row className='marged-top'>
        <Col>
          <span>
            {t('logs.imagesProcess.pending')}{' '}
            <b style={{ color: SublimeTheme.sublime.color.mainGold }}>
              {files.pending.length}
            </b>
          </span>
        </Col>
        <Col>
          <span>
            {t('logs.imagesProcess.success')}{' '}
            <b style={{ color: SublimeTheme.sublime.color.mainGreen }}>
              {files.success.length}
            </b>
          </span>
        </Col>
        <Col>
          <span>
            {t('logs.imagesProcess.errors')}{' '}
            <b style={{ color: SublimeTheme.sublime.color.mainRed }}>
              {files.errors.length}
            </b>
          </span>
        </Col>
      </Row>
      <Row>
        <Col>
          {files.pending.length >= 1 && (
            <Separator title={t('logs.imagesProcess.pending')}>
              {files.pending.map(file => (
                <LineItem key={file} file={file} type='pending' />
              ))}
            </Separator>
          )}
          {files.success.length >= 1 && (
            <Separator title={t('logs.imagesProcess.success')}>
              {files.success.map(file => (
                <LineItem key={file} file={file} type='success' />
              ))}
            </Separator>
          )}
          {files.errors.length >= 1 && (
            <Separator title={t('logs.imagesProcess.errors')}></Separator>
          )}
        </Col>
        <Col>
          {selectedFile.file && (
            <ImagesProcessLogModal
              selectedLog={selectedFile.file}
              logType={selectedFile.type}
            />
          )}
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  fetchLogs: fetchLogsFilesNames,
  fetchSharedLogs: fetchLogs
};

export default withTranslation(['admin'])(
  connect(mapStateToProps, mapDispatchToProps)(ImagesProcessLogs)
);
