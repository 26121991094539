/* eslint-disable import/prefer-default-export */
// @ts-ignore
import currencies from './currencies';

export function allCurrencies() {
  try {
    const datas = [];
    Object.keys(currencies).forEach(key => {
      datas.push({ label: currencies[key], value: key });
    });
    return datas;
  } catch (err) {
    console.log('Error while fetching currency datas', err);
    return '';
  }
}
