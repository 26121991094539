import { IStore } from '../interfaces/store';

const isProduction = process.env.REACT_APP_ENV === 'production';
const isStaging = process.env.REACT_APP_ENV === 'staging';

let baseUrlApp = isProduction
  ? 'https://sublime.exalt3d.com/api/v1'
  : 'http://127.0.0.1:3000/api/v1';
if (isStaging) baseUrlApp = 'https://staging.sublime.exalt3d.com/api/v1';

let baseUrlUploader = isProduction
  ? 'https://upload.exalt3d.com/api/v1'
  : 'http://127.0.0.1:5000/api/v1';
if (isStaging) baseUrlUploader = 'https://upload.exalt3d.com/api/v1';

let baseUrlImageHoster = isProduction
  ? 'https://upload.exalt3d.com'
  : 'http://127.0.0.1:5000';
if (isStaging) baseUrlImageHoster = 'https://upload.exalt3d.com/api/v1';

const apiUrl: any = {
  apiUrl: {
    projects: `${baseUrlApp}/projects`
  },
  uploaderUrl: {
    upload: `${baseUrlUploader}/uploads`, // GET || POST || DELETE
    byFolders: `${baseUrlUploader}/uploads/folders`, // GET
    byFolderFields: `${baseUrlUploader}/uploads/folder-fields`, // POST
    dropFolder: `${baseUrlUploader}/uploads/all`, // DELETE
    dropConfigs: `${baseUrlUploader}/uploads/configs` // DELETE
  },
  imagesHoster: `${baseUrlImageHoster}/images`,
  buildAuthHeaders: (store: IStore) => {
    const authState = store.getState().auth;
    const { client, uid } = authState;
    return {
      'access-token': authState['access-token'],
      client,
      uid
    };
  }
};

export default apiUrl;
