//@ts-ignore
import Axios, { AxiosResponse } from 'axios';
import { FileWithPath } from 'file-selector';
import { getProjectId, getUploaderToken } from '../sessions';
import { parametrize } from '../string';
import apiUrl from '../api';

export const uploadFiles = (
  files: Array<FileWithPath>,
  folderName: string,
  originalSize: string,
  progressCallback: any,
  cancelToken: any
): Promise<AxiosResponse> => {
  const formData = new FormData();
  const { projectId, userId, projectDisk } = getProjectId();
  const uploadToken = getUploaderToken();
  formData.append('sublimeProjectId', projectId);
  formData.append('projectDisk', projectDisk);
  formData.append('sublimeUserId', userId);
  formData.append('folderName', folderName);
  formData.append('originalSize', originalSize);
  files.forEach(file => {
    formData.append(
      'pictures',
      file,
      `${parametrize(file.name)}.${file.name.split('.').pop()}`
    );
  });
  return Axios.post(apiUrl.uploaderUrl.upload, formData, {
    headers: {
      Authorization: uploadToken
    },
    cancelToken,
    onUploadProgress: (progressEvent: any) => {
      const progress = (progressEvent.loaded * 100) / progressEvent.total;
      progressCallback(progress);
    }
  });
};

export const fetchAlreadyUploaded = (
  folderName: string
): Promise<AxiosResponse> => {
  const { projectId, userId, projectDisk } = getProjectId();
  const uploadToken = getUploaderToken();
  return Axios.get(apiUrl.uploaderUrl.byFolderFields, {
    params: {
      sublimeUserId: userId,
      sublimeProjectId: projectId,
      sublimeDiskName: projectDisk,
      sublimeFolderName: folderName.replace('&', '-')
    },
    headers: {
      Authorization: uploadToken
    }
  });
};

export const deleteUpload = (
  uploadId: string | number
): Promise<AxiosResponse> => {
  const uploadToken = getUploaderToken();
  return Axios.delete(`${apiUrl.uploaderUrl.upload}/${uploadId}`, {
    headers: {
      Authorization: uploadToken
    }
  });
};

export const deleteFolder = (folderName: string): Promise<AxiosResponse> => {
  const { projectId, userId } = getProjectId();
  const uploadToken = getUploaderToken();
  return Axios.delete(apiUrl.uploaderUrl.dropFolder, {
    headers: {
      Authorization: uploadToken
    },
    data: {
      sublimeUserId: userId,
      sublimeProjectId: projectId,
      sublimeFolderName: folderName
    }
  });
};
