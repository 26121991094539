import { IMenuItem, ITreeDataItem, INamedNomenclatures } from '../../interfaces/menu';

export const toggledFromPlayerOnly = (): any => {
  return (
    {
      menu: [],
      configurations: [],
      named_nomenclatures: {},
      default_configuration: '1',
      original_size: null
    }
  );
};

export const generatePlayerOnlyDatas = (projectName: string): any => {
  const id = new Date().getTime();

  return (
    {
      menu: generatePlayerOnlyMenu(id, projectName),
      configurations: generatePlayerOnlyConfiguration(id, projectName),
      named_nomenclatures: generatePlayerOnlyNamedNom(projectName),
      default_configuration: '1',
      original_size: null
    }
  );
};

export const generatePlayerOnlyMenu = (id: number, projectName: string): Array<IMenuItem> => (
  [
    {
      id,
      title: projectName,
      subtitle: '',
      children: [],
      data: {
        id,
        title: projectName,
        subtitle: '',
        displayTitle: false,
        thumbnail: {
          isColor: false,
          color: '',
          url: '',
          fileDatas: {}
        },
        productReference: {
          isReferenced: false,
          display: false,
          reference: ''
        },
        price: {
          amount: null,
          optionAmount: ''
        },
        file: {
          url: '',
          fileDatas: {}
        },
        childrenRule: 'OR',
        parentRule: 'OR',
        isMandatory: false,
        forbids: []
      }
    }
  ]
);
export const generatePlayerOnlyConfiguration = (id: number, projectName: string): Array<Array<ITreeDataItem>> => (
  [
    [
      {
        //@ts-ignore
        node: {
          id,
          title: projectName,
          subtitle: '',
          children: [],
          data: {
            id,
            title: projectName,
            subtitle: '',
            displayTitle: false,
            thumbnail: {
              isColor: false,
              color: '',
              url: '',
              fileDatas: {}
            },
            productReference: {
              isReferenced: false,
              display: false,
              reference: ''
            },
            price: {
              amount: null,
              optionAmount: ''
            },
            file: {
              url: '',
              fileDatas: {}
            },
            childrenRule: 'OR',
            parentRule: 'OR',
            isMandatory: false,
            forbids: []
          }
        },
        parentNode: null,
        path: [1575293169316],
        level: 0,
        index: 1,
        parentRule: 'OR',
        childrenRule: 'OR',
        isLeaf: true,
        nomenclature: '1'
      }
    ]
  ]
);
export const generatePlayerOnlyNamedNom = (projectName: string): INamedNomenclatures => (
  {
    '1': {
      name: projectName,
      defaultImageUrl: '',
      defaultPosition: {
        x: 1,
        y: 1
      }
    }
  }
);
