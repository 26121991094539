import React from 'react';
import { CSVLink } from 'react-csv/lib';
import { FaFileCsv } from 'react-icons/fa';
import { SublimeTheme } from 'exalt3d-sublime-ui';
import { getTitleTree } from '../../../../utils/configurator/menu';
import './AvailableConfigurations.scss';

export default function AvailableConfigurations({
  menus = [],
  treeData = [],
  named = false,
  projectId = null
}) {
  const csvHeaders = [
    { label: 'Nomenclature', key: 'nomenclature' },
    { label: 'Names', key: 'names' }
  ];

  const csvDatas = menus.map(menuItem => ({
    nomenclature: menuItem.map(i => i.nomenclature).join('&'),
    names: menuItem.map(i => getTitleTree(treeData, i)).join('&')
  }));

  return (
    <div className='available-configurations-container boxed'>
      <div>
        <h4 className='text-center'>
          {`Number of possible configurations : ${menus.length}`}
          {menus.length >= 1 && (
            <CSVLink
              filename={`available-configurations-${Date.now()}-${projectId}`}
              data={csvDatas}
              headers={csvHeaders}
            >
              <span
                data-toggle='tooltip'
                data-placement='top'
                title='Click to download a csv of available configurations'
                role='button'
                className='marged-right pointed'
              >
                <FaFileCsv color={SublimeTheme.sublime.color.mainGreen} />
              </span>
            </CSVLink>
          )}
        </h4>
        <br />
        <ul>
          {menus.map(menuItem => (
            <li
              key={`${menuItem.map(i => i.nomenclature).join('&')}`}
              className='marged-bottom'
              dangerouslySetInnerHTML={{
                __html: named
                  ? `- <span class='sublime-silver'>${menuItem
                      .map(i => getTitleTree(treeData, i))
                      .join('<span class="sublime-red"> & </span>')}</span>`
                  : `- ${menuItem.map(i => i.nomenclature).join('&')}`
              }}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}
