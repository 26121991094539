import React, { useState } from "react";
import "./index.scss";

interface ITextFieldProps {
  type: string;
  onChange: any;
  value: string;
  error?: boolean;
  className?: string;
}

const TextField: React.FunctionComponent<ITextFieldProps> = ({
  type = "text",
  onChange,
  value = "",
  error,
  className,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState(value);
  let formatedClassName = "sublime-input";
  if (error) {
    formatedClassName += " sublime-input-error";
  }
  if (className) {
    formatedClassName += ` ${className}`;
  }
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //@ts-ignore
    setInputValue(event.target.value);
    onChange(event);
  };
  return (
    <input
      {...rest}
      className={formatedClassName}
      type={type}
      onChange={handleOnChange}
      value={inputValue}
    />
  );
};

export default TextField;
