// @ts-nocheck
import Axios from 'axios';
import baseUrl from '../../api/url';
import { successMessage, errorMessage } from './app';
import i18n from '../../i18n';

export function fetchLogsFilesNames() {
  return async function (dispatch, getState) {
    function onSuccess(response) {
      dispatch(successMessage(i18n.store.data[i18n.language].admin.logs.messages.fetchSuccess));
      return response;
    }
    function onError(error) {
      dispatch(errorMessage(i18n.store.data[i18n.language].admin.logs.messages.fetchError));
      return error.response;
    }

    try {
      const response = await Axios.get(baseUrl.logs.imagesProcess.files, {
        headers: {
          Authorization: getState().user.uploader_token
        },
      });
      return onSuccess(response);
    } catch (error) {
      return onError(error);
    }
  };
}

export function fetchLog(fileName, logType) {
  return async function (dispatch, getState) {
    function onSuccess(response) {
      dispatch(successMessage(i18n.store.data[i18n.language].admin.logs.messages.fetchSuccess));
      return response;
    }
    function onError(error) {
      dispatch(errorMessage(i18n.store.data[i18n.language].admin.logs.messages.fetchError));
      return error.response;
    }

    try {
      const response = await Axios.get(`${baseUrl.logs.imagesProcess[logType]}/${fileName}`, {
        headers: {
          Authorization: getState().user.uploader_token
        },
      });
      return onSuccess(response);
    } catch (error) {
      return onError(error);
    }
  };
}

export function fetchLogs() {
  return async function (dispatch, getState) {
    function onSuccess(response) {
      dispatch(successMessage(i18n.store.data[i18n.language].admin.logs.messages.fetchSuccess));
      return response;
    }
    function onError(error) {
      dispatch(errorMessage(i18n.store.data[i18n.language].admin.logs.messages.fetchError));
      return error.response;
    }

    try {
      const response = await Axios.get(baseUrl.logs.imagesProcess.logs, {
        headers: {
          Authorization: getState().user.uploader_token
        },
      });
      return onSuccess(response);
    } catch (error) {
      return onError(error);
    }
  };
}