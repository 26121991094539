// @ts-nocheck
import Axios from "axios";
import baseUrl from "../../api/url";
import {
  SIGN_IN,
  SIGN_IN_ERROR,
  SIGN_UP,
  SET_USER_ROLE,
  SIGN_UP_ERROR,
  SIGN_OUT,
  SIGN_OUT_ERROR
} from "../types/auth";
import { SUCCESS_MESSAGE, ERROR_MESSAGE } from "../types/app";
import { fetchUser } from "../actions/user";
import i18n from "../../i18n";
import store from "../index";
import { getErrorMessagesFromBackend } from "../../utils/errors";

export function signIn(email, password, rememberMe) {
  return async function(dispatch, getState) {
    function onSuccess(response) {
      dispatch({ type: SIGN_IN, payload: { ...response.headers, rememberMe } });
      dispatch(fetchUser(response.data.data));
      dispatch(setUserRole(response.data.data));
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: i18n.store.data[i18n.language].login.successMessage
      });
    }
    function onError(error) {
      dispatch({ type: SIGN_IN_ERROR, payload: error });
      dispatch({ type: ERROR_MESSAGE, payload: error.response.data.errors });
    }

    try {
      const response = await Axios.post(baseUrl.auth.signIn, {
        email,
        password
      });
      const { uid, client } = response.headers;
      setTimeout(async () => {
        try {
          const result = await Axios.get(
            `api/v1/auth/validate_token?uid=${uid}&client=${client}&access-token=${response.headers["access-token"]}`
          );
          return onSuccess(result);
        } catch (validationError) {
          return onError(validationError);
        }
      }, 1500); // wait 1.5s for backend set a new token (need to create a middleware to watch token)
    } catch (signinError) {
      return onError(signinError);
    }
  };
}

export function signUp(userDatas) {
  return async function(dispatch, getState) {
    function onSuccess(response) {
      dispatch({ type: SIGN_UP, payload: response.data });
      dispatch({
        type: SIGN_IN,
        payload: { ...response.headers, rememberMe: false }
      });
      dispatch(fetchUser(response.data.data));
      dispatch(setUserRole(response.data.data));
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: i18n.store.data[i18n.language].signUp.successMessage
      });
    }
    function onError(error) {
      dispatch({ type: SIGN_UP_ERROR, payload: error });
      dispatch({
        type: ERROR_MESSAGE,
        payload: getErrorMessagesFromBackend(error)
      });
    }

    try {
      const response = await Axios.post(baseUrl.auth.signUp, { ...userDatas });
      onSuccess(response);
    } catch (error) {
      onError(error);
    }
  };
}

export function signOut() {
  try {
    return {
      type: SIGN_OUT,
      payload: null
    };
  } catch (error) {
    return {
      type: SIGN_OUT_ERROR,
      payload: error
    };
  }
}

export function buildTokenHeaders() {
  const authState = store.getState().auth;
  const { client, uid } = authState;
  return {
    "access-token": authState["access-token"],
    client,
    uid
  };
}

export function setUserRole(userDatas) {
  return {
    type: SET_USER_ROLE,
    payload: userDatas
  };
}
