// import React from "react";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';

const confirmBox = (
  title: string,
  message: string,
  onConfirm: any,
  onCancel: any,
  customUI: any | undefined
) => {
  confirmAlert({
    title,
    message,
    buttons: [
      {
        label: 'Yes',
        onClick: onConfirm
      },
      {
        label: 'No',
        onClick: onCancel
      }
    ],
    customUI
  });
};

export default confirmBox;
