import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Toast, ToastHeader, ToastBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {
  secureFetchAppStats,
  secureFetchNodeAppStats
} from '../../../store/actions/stats';
import SublimeHeader from '../../shared/SublimeHeader';
import { formatFileSize } from '../../../utils/bytes';

const defaultRubyStats = {
  cpu_used: 0,
  disk_used: 0,
  disk_used_perc: 0,
  mem_used: 0
};

const defaultNodeStats = {
  storage: {
    a: {
      diskAPath: '',
      space: { free: 0, size: 0, used: 0, usedPercent: 0 }
    },
    b: {
      diskBPath: '',
      space: { free: 0, size: 0, used: 0, usedPercent: 0 }
    },
    c: {
      diskCPath: '',
      space: { free: 0, size: 0, used: 0, usedPercent: 0 }
    },
    d: { diskDPath: '', space: { free: 0, size: 0, used: 0, usedPercent: 0 } }
  },
  system: {
    cpu_used: 0,
    disk_used: 0,
    disk_used_perc: 0,
    mem_used: 0
  }
};

const LineItem = ({ label, value }) => {
  return (
    <div className='marged-top marged-bottom'>
      <h5 style={{ color: '#888', fontSize: 12, fontWeight: 700, margin: 0 }}>
        {label}
      </h5>
      <h1 style={{ fontWeight: 700 }}>{value}</h1>
    </div>
  );
};

const StorageDiskItem = ({ data }) => {
  return (
    <div style={{ width: '300px' }}>
      <CircularProgressbar
        strokeWidth={1}
        value={data.space.used}
        maxValue={data.space.size}
        text={`${data.space.usedPercent.toFixed(2)}%`}
      />
      <p className='text-center marged-top'>
        {formatFileSize(data.space.used, 2, true)}
        {' / '}
        {formatFileSize(data.space.size, 2, true)}
      </p>
    </div>
  );
};

function Index({ fetchRubyStats, fetchNodeStats, t }) {
  const [rubyStats, setRubyStats] = useState(defaultRubyStats);
  const [nodeStats, setNodeStats] = useState(defaultNodeStats);
  // let rubyStatFetch = null;

  useEffect(() => {
    let rubyStatFetch = setInterval(() => {
      fetchRubyStats()
        .then(response => {
          setRubyStats({ ...response.data });
        })
        .catch(error => {
          setRubyStats({ ...defaultRubyStats });
          console.log(error);
        });
      fetchNodeStats()
        .then(response => {
          setNodeStats({ ...response.data });
        })
        .catch(error => {
          setNodeStats({ ...defaultNodeStats });
          console.log(error);
        });
    }, 3000);
    return () => {
      clearInterval(rubyStatFetch);
    };
  }, []);

  console.log('lalala', nodeStats);
  return (
    <div className='layout-margin marged-bottom'>
      <SublimeHeader title={t('statistics.title')} />
      <Container>
        <Row>
          <Col>
            <Toast>
              <ToastHeader>Applications systems</ToastHeader>
              <ToastBody>
                <Row>
                  <Col>
                    <Toast>
                      <ToastHeader>Ruby system</ToastHeader>
                      <ToastBody style={{ height: '50vh' }}>
                        <div style={{ marginTop: 15 }}>
                          <b
                            style={{
                              fontSize: 16,
                              fontWeight: 900
                            }}
                          >
                            Ruby on Rails Status
                          </b>
                        </div>
                        <LineItem
                          label='CPU Usage'
                          value={`${rubyStats.cpu_used}%`}
                        />
                        <LineItem
                          label='Memory Usage'
                          value={`${rubyStats.mem_used}%`}
                        />
                        <LineItem
                          label='Disk Usage'
                          value={`${rubyStats.disk_used_perc}%`}
                        />
                        <LineItem
                          label='Disk Usage'
                          value={`${rubyStats.disk_used} GB`}
                        />
                      </ToastBody>
                    </Toast>
                  </Col>
                  <Col>
                    <Toast>
                      <ToastHeader>Node.js system</ToastHeader>
                      <ToastBody style={{ height: '50vh' }}>
                        <div style={{ marginTop: 15 }}>
                          <b
                            style={{
                              fontSize: 16,
                              fontWeight: 900
                            }}
                          >
                            Express Status
                          </b>
                        </div>
                        <LineItem
                          label='CPU Usage'
                          value={`${nodeStats.system.cpu_used}%`}
                        />
                        <LineItem
                          label='Memory Usage'
                          value={`${nodeStats.system.mem_used.toFixed(2)}%`}
                        />
                        <LineItem
                          label='Disk Usage'
                          value={`${nodeStats.system.disk_used_perc.toFixed(
                            2
                          )}%`}
                        />
                        <LineItem
                          label='Disk Usage'
                          value={formatFileSize(
                            nodeStats.system.disk_used,
                            2,
                            true
                          )}
                        />
                      </ToastBody>
                    </Toast>
                  </Col>
                </Row>
              </ToastBody>
            </Toast>
          </Col>
        </Row>
        <Row>
          <Col>
            <Toast className='marged-top'>
              <ToastHeader>Storages systems</ToastHeader>
              <ToastBody>
                <Row>
                  <Col>
                    <Toast>
                      <ToastHeader>Storage disk A</ToastHeader>
                      <ToastBody className='flex-center'>
                        <StorageDiskItem data={nodeStats.storage.a} />
                      </ToastBody>
                    </Toast>
                  </Col>
                  <Col>
                    <Toast>
                      <ToastHeader>Storage disk B</ToastHeader>
                      <ToastBody className='flex-center'>
                        <StorageDiskItem data={nodeStats.storage.b} />
                      </ToastBody>
                    </Toast>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Toast className='marged-top'>
                      <ToastHeader>Storage disk C</ToastHeader>
                      <ToastBody className='flex-center'>
                        <StorageDiskItem data={nodeStats.storage.c} />
                      </ToastBody>
                    </Toast>
                  </Col>
                  <Col>
                    <Toast className='marged-top'>
                      <ToastHeader>Storage Disk D</ToastHeader>
                      <ToastBody className='flex-center'>
                        <StorageDiskItem data={nodeStats.storage.d} />
                      </ToastBody>
                    </Toast>
                  </Col>
                </Row>
              </ToastBody>
            </Toast>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  fetchRubyStats: secureFetchAppStats,
  fetchNodeStats: secureFetchNodeAppStats
};

export default withTranslation('admin')(
  connect(mapStateToProps, mapDispatchToProps)(Index)
);
