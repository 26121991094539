import { SublimeTheme } from 'exalt3d-sublime-ui';

export default {
  type: 'START_ANIMATION',
  payload: {
    loader: 'Watch',
    color: SublimeTheme.sublime.color.mainGold,
    message: {
      body: 'Your project is being cooked, you will receive an email with links to it when it is ready',
      color: SublimeTheme.sublime.color.mainGreen
    }
  }
};
