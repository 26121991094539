import { Styles } from 'react-select';
import chroma from 'chroma-js';
import SublimeTheme from '../../../ui-kit/theme';
const color = chroma(SublimeTheme.sublime.color.mainGreen);

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10
  }
});

export const colourStyles = {
  control: (styles: Styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (
    styles: Styles,
    { data, isDisabled, isFocused, isSelected }: any
  ) => {
    console.log('LALALA', data);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? color.hex('auto')
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : SublimeTheme.sublime.color.mainBlack,
      cursor: isDisabled ? 'not-allowed' : 'default'
    };
  },
  input: (styles: Styles) => ({ ...styles, ...dot() }),
  singleValue: (styles: Styles, { data }: any) => ({
    ...styles,
    ...dot(getColorByStatus(data.value))
  })
};

const getColorByStatus = (status: string) => {
  console.log('STATTUS', status);
  switch (status) {
    case 'canceled':
      return SublimeTheme.sublime.color.mainDisabled;
    case 'disabled':
      return SublimeTheme.sublime.color.mainDisabled;
    case 'registered':
      return SublimeTheme.sublime.color.mainGreen;
    case 'confirmed':
      return SublimeTheme.sublime.color.mainGreen;
    case 'trying_end':
      return SublimeTheme.sublime.color.mainOrange;

    default:
      return SublimeTheme.sublime.color.mainDisabled;
  }
};
