import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { SublimeSpnr } from 'exalt3d-sublime-ui';
import SublimeStepper from './SublimeStepper';
import SublimeConfStep1 from './steps/SublimeConfStep1';
import SublimeConfStep2 from './steps/SublimeConfStep2';
import SublimeConfStep3 from './steps/SublimeConfStep3';
import SublimeConfStep4 from './steps/SublimeConfStep4';
import { updateConfigField } from '../../store/actions/project';
import {
  generatePlayerOnlyDatas,
  toggledFromPlayerOnly
} from '../../utils/configurator/playerOnly';
import destroyAllUploadsConfigsForProject from '../../utils/uploader/delete';

export default class Configurator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 0,
      loading: false
    };
  }

  submitForm = async fields => {
    this.setState({ loading: true });
    const { currentStep } = this.state;
    let nextStep = currentStep + 1;
    const { store } = this.props;
    const { project } = store.getState().projects;
    const result = await store.dispatch(updateConfigField(store, fields));
    const isToggledFromSingleToConfig =
      project.display_player_only && !fields.display_player_only;
    if (result.success) {
      if (currentStep === 0 && isToggledFromSingleToConfig) {
        await store.dispatch(
          updateConfigField(store, {
            ...toggledFromPlayerOnly()
          })
        );
        await destroyAllUploadsConfigsForProject(
          result.response.data.project.id
        );
      }
      if (currentStep === 0 && fields.display_player_only) {
        nextStep = 3;
        if (result.response.data.project.flat_menu.length !== 1) {
          await store.dispatch(
            updateConfigField(store, {
              ...generatePlayerOnlyDatas(fields.name)
            })
          );
          await destroyAllUploadsConfigsForProject(
            result.response.data.project.id
          );
        }
      }
      if (currentStep === 2) {
        const { named_nomenclatures } = fields;
        const keys = Object.keys(JSON.parse(named_nomenclatures));
        if (
          project.default_configuration === null ||
          !keys.includes(project.default_configuration)
        ) {
          const defaultPos = keys.length >= 1 ? keys[0] : '1';
          await store.dispatch(
            updateConfigField(store, { default_configuration: defaultPos })
          );
        }
      }
      await this.setState({ currentStep: nextStep, loading: false });
      //@ts-ignore
      window.scrollTo(0, 0);
    } else {
      this.setState({ loading: false });
    }

    return result;
  };

  prevStep = async () => {
    const { currentStep } = this.state;
    const { store } = this.props;
    const { projects } = store.getState();
    const { project } = projects;
    const { display_player_only } = project;
    let prevStep = currentStep - 1;

    if (display_player_only && currentStep === 3) {
      prevStep = 0;
    }
    await this.setState({ currentStep: prevStep });
    //@ts-ignore
    window.scrollTo(0, 0);
  };

  setStep = step => {
    this.setState({ currentStep: step });
  };

  render() {
    const { currentStep, loading } = this.state;
    const { store, className, loading: projectLoading } = this.props;
    const { projects } = store.getState();
    const { project } = projects;
    const { display_player_only } = project;

    let disabledSteps = [];
    if (display_player_only) {
      disabledSteps = [1, 2];
    }
    return (
      <Container className={`${className} layout-margin`}>
        <SublimeStepper
          disabledSteps={disabledSteps}
          activeStep={currentStep}
          setStep={this.setStep}
        />
        {currentStep === 0 && (
          <SublimeConfStep1 store={store} submit={this.submitForm} />
        )}
        {currentStep === 1 && (
          <SublimeConfStep2
            store={store}
            submit={this.submitForm}
            prevStep={this.prevStep}
          />
        )}
        {currentStep === 2 && (
          <SublimeConfStep3
            store={store}
            submit={this.submitForm}
            prevStep={this.prevStep}
          />
        )}
        {currentStep === 3 && (
          <SublimeConfStep4
            store={store}
            submit={this.submitForm}
            prevStep={this.prevStep}
          />
        )}
        {loading || (projectLoading && <SublimeSpnr />)}
      </Container>
    );
  }
}
